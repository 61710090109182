import React, { useState, useEffect } from 'react';
import images from '../../../assets/images';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Icon } from '@iconify/react'; 
import { connect } from 'react-redux'; 
import { ViewTickets } from '../../../store/ticket/action';
import { PaymentFailure, PaymentStatus, PaymentSuccess } from '../../../store/officeadmin/action';
import { arrayBufferToBase64 } from '../../../util/helper';
import { Link, useNavigate, useLocation } from 'react-router-dom'; 
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';  
import Loader from '../../../components/Loader';

const OrderSuccess = ({profile, ViewTickets, PaymentSuccess, PaymentFailure, PaymentStatus}) => {
  const navigate = useNavigate();
  const location = useLocation()

  const [show, setShow] = useState(false);
  const [orders, setOrders] = useState([])
  const [activeIndex, setActiveIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState();
  const { pathname } = useLocation();
  const personId = pathname.split('/').pop();

  useEffect(() => {
    async function fetchData(param, personId) {
      const urlParam = param;
      const ref = urlParam.split("=")[1];
      if (ref) { 
        let orders = await ViewTickets(ref); 
        if (orders.total == 0 || orders.length == 0) {
          const checkPaymentStatus = await PaymentStatus(ref)
          if (checkPaymentStatus.result == "CAPTURED") {
            const res = await PaymentSuccess(ref, personId);
            orders = await ViewTickets(ref);
            setOrders(orders && orders || [])
            setIsSuccess(true)
          } else {
            await PaymentFailure(ref, personId);
            setIsSuccess(false)
          }
        } else { 
          const checkPaymentStatus = await PaymentStatus(ref) 
          if(checkPaymentStatus?.result == 'CAPTURED'){
            setOrders(orders && orders || [])
            setIsSuccess(orders[0].isSuccess)
          } else {
            await PaymentFailure(ref, personId);
            setIsSuccess(false)
          }
        }
        setLoading(false)
      }
    }
    fetchData(location?.search, personId)
  }, [location])

  useEffect(() => {
    setShow(true);
  }, []); // Run once on component mount 

  const navigateToTickets = () => {
    navigate(`/tickets/${personId}`)
  }

  const handleDownload = (e) => {
    e.preventDefault()
    const active = orders[activeIndex];
    const url = window.URL.createObjectURL(new Blob([active.ticketImage]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', active.orderNo+".png");
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link)
  }

  return (
    <section className='order-success'>
      {loading && <Loader />}
      <div className="container">
      <div className="row justify-content-center">
        <div className="col-12">
            {isSuccess == true && <div className="vh-80 text-center">
              <img src={images.successimage} className={`${show ? 'fade-in' : ''}`} alt="Grand Raffle Success" />
              <h1 className='mt-3'>Success! </h1>
              <p className='mt-3'>Your order has been successfully processed, and a copy of your e-Ticket has been sent to the email address provided: {profile && profile?.email}</p>
              <div className="d-flex text-center cn-btn">
                <div className="ch-btn mt-3">
                  <button className="crt-btn" data-bs-toggle="modal" data-bs-target="#viewticket" aria-label="viewticket">VIEW TICKETS</button>
                </div>
                <div className="ch-brbtn mt-3">
                  <button className="crt-brbtn" onClick={navigateToTickets} aria-label="buytickets">BUY TICKETS</button>
                </div>
              </div>
            </div>}
            {isSuccess == false && <div className="vh-80 text-center">
              <img src={images.failureimage} className={`${show ? 'fade-in failure-img' : ''}`} alt="Grand Raffle Success" />
              <h1 className='mt-3'>Failure! </h1>
              <p className='mt-3'>Oops, it seems there was an issue processing your order. Please try again or contact support for assistance.</p>
              <div className="d-flex text-center cn-btn"> 
                <div className="ch-brbtn mt-3">
                  <button className="crt-brbtn" onClick={navigateToTickets} aria-label="buytickets">BUY TICKETS</button>
                </div>
              </div>
            </div>}
          </div>
        </div>
      </div>

      <div className="modal fade" id="viewticket" tabIndex="-1" aria-labelledby="viewticketLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="tic-icon">

              <Link className="md-close" data-bs-dismiss="modal" aria-label="Close">
                <Icon icon="jam:close" />
              </Link>
              <Link className="download-icon" onClick={handleDownload}>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="download-tooltip">Download</Tooltip>}
                >
                  <Icon icon="flowbite:download-outline" />
                </OverlayTrigger>
              </Link>
            </div>
            <div className="modal-body">
              <Carousel onChange={index => setActiveIndex(index)} selectedItem={activeIndex}
                showThumbs={false} showStatus={false} showIndicators={false}
                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                  hasPrev && (
                    <button className="custom-prev-button" onClick={onClickHandler} title={label}>
                      <Icon icon="solar:alt-arrow-left-bold" />
                    </button>
                  )
                }
                renderArrowNext={(onClickHandler, hasNext, label) =>
                  hasNext && (
                    <button className="custom-next-button" onClick={onClickHandler} title={label}>
                      <Icon icon="solar:alt-arrow-right-bold" />
                    </button>
                  )
                }>
                {
                  orders && orders.length && orders.map(o => {
                    return <div className="ticket-slider" >
                      <img src={arrayBufferToBase64(o.ticketImage, "image/png")} alt="" />
                    </div>
                  })
                }
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapDispatchToProps = { ViewTickets, PaymentSuccess, PaymentFailure, PaymentStatus }
const mapStateToProps = (state) => ({ 
    profile: state?.auth && state?.auth?.profile,

});

export default connect(mapStateToProps, mapDispatchToProps)(OrderSuccess);
