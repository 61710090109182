import React, { useState, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Icon } from '@iconify/react'
import { useNavigate } from 'react-router-dom'
import images from '../../assets/images'
import { GetSalutations } from '../../store/master/action';
import { connect } from 'react-redux';
import { RegisterUser } from '../../store/auth/action';
import validate from "validate.js";
import { isEmpty } from 'lodash';
import schema from './schema';
import TextInput from '../../components/TextInput';
import RegisterSuccessModal from './SuccessModal'
import ErrorModal from '../../components/ErrorModal';
import PhoneInput from '../../components/PhoneInput';
import Loader from '../../components/Loader';

const RegisterScreen = ({ GetSalutations, RegisterUser }) => {
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState();
  const [salutation, setSalutation] = useState([]); 
  const [loading, setLoading] = useState(false);

  const initialState = {
    values: {
      salutationId: "",
      firstName: "",
      lastName: "",
      mobile: "",
      email: "",
      password: "",
      recaptcha: "",
    },
    errors: {
      salutationId: null,
      firstName: null,
      lastName: null,
      mobile: null,
      email: null,
      password: null,
      recaptcha: null,
    },
  };
  const [values, setValues] = useState(initialState.values);
  const [errors, setErrors] = useState(initialState.errors);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetch() {
      const res = await GetSalutations(); 
      setSalutation(res)
    }
    fetch();
  }, []);

  const handleChange = (field, value) => {
    setValues((prev) => ({ ...prev, [field]: value }));
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const validateForm = () => {
    const errors = validate(values, schema);
    setErrors(errors);
    if (errors) {
      const message = !isEmpty(values?.salutationId) || !isEmpty(values?.firstName) || !isEmpty(values?.lastName) || !isEmpty(values?.email) || !isEmpty(values?.mobile) || !isEmpty(values?.password)
        ? errors[Object.keys(errors)[0]][0] : "Please ensure all the fields are filled out to register your account.";
      setMessage(message);
      window.$("#errorModal").modal('show');
      return false;
    }
    return true;
  };

  const handleRegister = async (e) => { 
    const valid = validateForm();
    if (valid) {
      setLoading(true) 
      const {recaptcha, ...originalValues} = values;
      const res = await RegisterUser(originalValues); 
      if (res) {
         setLoading(false)
         window.$("#registerSuccessModal").modal('show');
      } else {
        setLoading(false)
      }
    }
  };

  const handleOnClose = () => {
    window.$("#errorModal").modal('hide');
  }

  const handleOk = () => {
    window.$("#registerSuccessModal").modal('hide');
    navigate('/login')
  }

  return (<section className='login-section register-data'>
    <ErrorModal message={message} btn1Text="OK" btn1Click={handleOnClose} />
    <RegisterSuccessModal btn1Text="OK" btn1Click={handleOk} />
    {loading && <Loader/>}
    <div className='container'>
    <div className="row justify-content-center">
        <div className="col-md-8 col-lg-6 col-xl-6 col-sm-12">
          <div className='login-sectioncontent'>
            <div className='log-header text-center'>
              <img src={images.footerlogo} className='img-fluid wit-2' onClick={() => { navigate('/') }} alt="Footer Logo"/>
              <h3 className='mt-3'>Register with Grand Raffle</h3> 
                <div className='row mt-3'>
                  <div className='col-md-12 rd-btn'>
                    <p>All fields are required</p>
                    <div className="button-wrap">
                      Title :
                      {salutation && salutation.length ? salutation?.map((i, index) => {
                        return (<span key={index}>
                          <input value={i.salutationId} className="hidden radio-label gender-options" type="radio"
                            name="accept-offers" id={index} onChange={(e) => handleChange("salutationId", Number(e.target.value))}
                            checked={values.salutationId == i.salutationId && "checked"} />
                          <label className={`button-label ${errors && errors?.salutationId && 'error-box'}`} htmlFor={index}>
                            <h1>{i.Title}</h1>
                          </label>
                        </span>)
                      }):''}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className='form-group'>
                      <TextInput
                        type="text"
                        value={values.firstName}
                        onChange={(e) => handleChange("firstName", e.target.value)}
                        label='First Name'
                        className='form-control'
                        name='firstname'
                        id='firstname'
                        error={errors?.firstName}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className='form-group'>
                      <TextInput
                        type="text"
                        value={values.lastName}
                        onChange={(e) => handleChange("lastName", e.target.value)}
                        className='form-control'
                        name='lastname'
                        id='lastname'
                        label='Last Name'
                        error={errors?.lastName}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                  <div className="custom-phonenumber">
                    <PhoneInput
                      id={"mobile"}
                      inputProps={{
                        name: 'mobile',
                        required: true,
                      }}
                      placeholder={"xxxxx-xxxxx"}
                      name={"mobile"}
                      value={values?.mobile}
                      onChange={(e) => handleChange("mobile", e)}
                      country="ae"
                      error={errors && errors?.mobile}
                    />
                    </div>
                  </div>
                  <div className='col-md-12'>
                    <div className='form-group'>
                      <TextInput
                        type='email'
                        value={values.email}
                        onChange={(e) => handleChange("email", e.target.value)}
                        className='form-control'
                        name='email'
                        id='email'
                        label='Email'
                        error={errors?.email}
                      />
                    </div>
                    <label className='em-text'>Please provide valid email for verification link to be sent</label>

                  </div>
                  <div className='col-md-12'>
                    <div className='form-group psd-tp'>
                      <TextInput
                        type={showPassword ? 'text' : 'password'}
                        value={values.password}
                        onChange={(e) => handleChange("password", e.target.value)}
                        className='form-control pad-right'
                        name='password'
                        id='password'
                        label='Password'
                        error={errors?.password}
                      />
                      <span className='eye-icon' onClick={handleTogglePassword}>
                        {showPassword ? <Icon icon="eva:eye-outline" /> : <Icon icon="tabler:eye-off" />}
                      </span>
                    </div>
                  </div>
                  <div className='col-md-12'>
                    <div className="captcha text-center" style={{ width: '100%', marginTop: '10px' }}>
                    <ReCAPTCHA 
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY} 
                    type='image'
                    size="normal"
                    onChange={(value)=>handleChange("recaptcha", value)}
                    />                    
                    </div>
                  </div>
                  <div className='sub-btn col-12 text-center'>
                    <button className='gradient-btn' onClick={handleRegister} >
                      REGISTER
                    </button>
                  </div>
                  <p className='joinnw'>
                    Already registered? <a onClick={() => navigate("/login")} style={{ cursor: 'pointer' }}>Login Now</a>
                  </p>
                </div> 
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> 
  );
};
const mapDispatchToProps = { GetSalutations, RegisterUser };
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterScreen);
