import client from "../../services/api-client";
import { errorHandler } from "../error/action";
  
export const CreatePerson = (data) => async (dispatch) => {
    try {
        const res = await client.service("person").create(data);
        if (res) {
            return res;
        }
        return false;
    } catch (error) {
        errorHandler(error);
        return false;
    }
};

export const GetAllTickets = (personId) => async (dispatch) => {
    try{
        const res = await client.service('ticket-list').find({ query: { personId } });
        if(res){
            return res;
        }
    } catch (err) {
        errorHandler(err);
        return false
    }
}

export const AddPersonTicket = (tickets) => async (dispatch) => {
    try{
        const res = await client.service('personticket').create(tickets);
        if(res){
            dispatch({
                type: "SELECTED_TICKETS",
                data: tickets
            }); 
            return res
        }
    } catch (err){
        errorHandler(err);
        return false
    }
}

export const CreatePayment = (personId) => async (dispatch) => {
    try {
        const res = await client.service('payment').create({},{query:{personId: personId}});
        if (res) {
            return res;
        }
    } catch (err) {
        errorHandler(err)
        return false
    }
} 
 
export const PaymentSuccess = (ref, personId) => async (dispatch) => {
    try {
        const res = await client.service(`/payment-accepted`).create({},{ query: { ref: ref, personId:personId } });
        if (res) {
            return res;
        }
    } catch (err) {
        errorHandler(err)
        return false
    }
}

export const PaymentFailure = (ref, personId) => async (dispatch) => {
    try {
        const res = await client.service(`/payment-failure`).create({},{ query: { ref: ref , personId: personId} });
        if (res) {
            return res;
        }
    } catch (err) {
        errorHandler(err)
        return false
    }
}

export const PaymentStatus = (ref) => async (dispatch) => {
    try {
        const res = await client.service(`/payment-status`).find({ query: { ref: ref } });
        if (res) {
            return res;
        }
    } catch (err) {
        errorHandler(err)
        return false
    }
}

export const PaymentHistory = ($limit=10, $skip=0, transactionBy) => async (dispatch) => {
    try {
        const res = await client.service(`/ordersummary`).find({ query: { $limit: $limit, $skip: $skip * $limit, transactionBy: transactionBy } });
        if (res) {
            return res;
        }
    } catch (err) {
        errorHandler(err)
        return false
    }
}

export const GetAllOrders = ($limit=10, $skip=0, transactionBy) => async (dispatch) => {
    try {
        const res = await client.service(`/printorder`).find({ query: { $limit: $limit, $skip: $skip * $limit } });
        if (res) {
            return res;
        }
    } catch (err) {
        errorHandler(err)
        return false
    }
}