import React from "react";
import { connect } from "react-redux";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const Phone = (props) => {
    const { id, name, value, onChange, error, className, inputProps, country, placeholder=''} = props;
    const countryProp = country !== null ? country : "ae";
    return (
        <PhoneInput
           autoComplete="nope"
            id={id}
            name={name}
            inputProps={inputProps}
            country={countryProp ? countryProp : 'ae'}
            value={value}
            onChange={(value, country,) => { onChange(value, country) }}
            error={error}
            className={`${error ? "redborder" : ""} ${className ? className : ''}`}
            placeholder={placeholder}
            dropdownClass="text-start"
            
        />
    )
};

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Phone);




