import React from "react";
import { Navigate } from "react-router-dom"; 
import store from "../../store";

const ProtectedRoute = ({children, userPrevillege}) =>{
const token = localStorage.getItem("feathers-jwt");
const isAuthenticated = token != null;
const { profile } = store.getState().auth;
const role = profile && profile.role && profile.role.roleId;

if (!isAuthenticated) {
    return (
      <Navigate to="/login" />
    );
  }
  else {
    const userHasRequiredRole = userPrevillege && profile && (userPrevillege === "all" || userPrevillege.includes(role)) ? true : false;
    if (isAuthenticated && !userHasRequiredRole) {
      return <Navigate to="*" />;
    }
    return children;
  }

}

export default ProtectedRoute;