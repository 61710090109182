import React from 'react'
import { Icon } from '@iconify/react'

const SuccessModal = ({ message, btn1Text, btn1Click, btn2Text, btn2Click }) => {
  return (<div class="modal fade successModal" data-bs-backdrop="static" id="successModal" tabindex="-1" aria-labelledby="successModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div className="success-content">
            <Icon icon="mdi:success-circle-outline" />
            <h5>Success</h5>
            <p>{message}</p>
          </div>
          <div className="btn-modal text-center">
            {btn1Text && <button type="button" class="btn yell-gradient-modal" onClick={btn1Click} aria-label="btn-name">{btn1Text}</button>}
            {btn2Text && <button type="button" class="btn grn-gradient-modal" onClick={btn2Click} aria-label="btn-name">{btn2Text}</button>}
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default SuccessModal