import React, { useState, useEffect } from 'react';  
import { GetNationality, GetSalutations } from '../../../store/master/action';
import { CreatePerson } from '../../../store/officeadmin/action'
import { connect } from 'react-redux';
import { isEmpty  } from 'lodash'
 import { formatNationality } from '../../../util/helper';
import { validate } from 'validate.js';
import schema from './schema';
import ErrorModal from '../../../components/ErrorModal';
import SuccessModal from '../../../components/SuccessModal';
import { ProfileInfo } from './ProfileInfo'; 
import { useNavigate, useLocation } from 'react-router-dom';
import Loader from '../../../components/Loader';


const Profile = (props) => {
  const { GetNationality, GetSalutations, profile, CreatePerson } = props;
  const [message, setMessage] = useState();
  const navigate = useNavigate(); 
  const location = useLocation(); 

  const initialState = {
    values: {
      profileStatus: 1,
      firstName: "",
      lastName: "",
      email: "",
      dateOfbirth: "",
      nationalityId: null,
      mobile:null, 
      isChecked:false 
    },
    errors: {
      firstName: null,
      lastName: null,
      email: null,
      dateOfbirth: null,
      nationalityId: null,
      mobile:null, 
      isChecked:null

    },
  };
  const [values, setValues] = useState(initialState.values);
  const [errors, setErrors] = useState(initialState.errors);
  const [nationality, setNationality] = useState([]);
  const [salutations, setSalutations] = useState([])
  const [loading, setLoading] = useState(false);


  const handleChange = async (field, value, country) => {
    if (field == 'mobile') {
      const numberWithoutCode = value.replace(country.dialCode, '');
      setValues((prev) => ({ ...prev, [field]: `${country.dialCode} ${numberWithoutCode}` }))
      return
    }
    setValues((prev) => ({ ...prev, [field]: value }))
  }; 

  useEffect(() => {
    async function fetchData() { 
      const nationality = await GetNationality();
      if (nationality) {
        const res = await formatNationality(nationality);
        setNationality(res)
      } 

      const salutations = await GetSalutations();
      if (salutations) {
        setSalutations(salutations);
      }
    }
    fetchData()
  }, []);
 

  const validateForm = () => { 
    let data = values; 
    const error = validate(data, schema);
    setErrors(error);
    let valid = !isEmpty(error) ? false : true;
    if (!valid) {
      let errorMessage =
        (error &&
          !isEmpty(error) &&
          error[Object.keys(error)[0]] &&
          error[Object.keys(error)[0]][0]) || '';
      setMessage(errorMessage);
      window.$("#profileError").modal('show');
    }
    else {
      setErrors({});
    }
    return valid;
  }

  const handleSubmit = async () => {
    let valid = await validateForm();
    if (valid) {
      setLoading(true)
      values.profileStatus = true;
      values.isConsent = true;
      const { isChecked, ...originalValues } = values;
      const res = await CreatePerson(originalValues); 
      if (res) {
        setLoading(false)
        navigate(`/tickets/${res.personId}`);
      } else {
        setLoading(false)
      }
    }
  }

  const handleOk = () => {
    window.$("#profileError").modal('hide');
    window.$("#successModal").modal('hide');
  } 
   
  const handleSuccess = () => { 
    window.$("#successModal").modal('hide');
  } 
 
  return (<section className='Profiling-section'>
    {loading && <Loader />}
    <ErrorModal modalId="profileError" message={message} btn1Text={"OK"}  btn1Click={handleOk} />
    <SuccessModal message={message} btn1Text={"OK"} btn1Click={handleSuccess} />
    <div className='container'> 
      <div className='row'>
        <div className="profile">
          <h3>Complete Your Profile, Unlock Your Fortune!</h3>
          <p>Enhance your Grand Raffle experience: Confirm your nationality, provide valid documents, and address info to secure your chances and rewards!</p>
        </div>
        <ProfileInfo errors={errors} values={values} handleChange={handleChange}  nationality={nationality} salutations={salutations}/>
       <div className='sub-btn col-12 text-center'>
            <button className='gradient-btn' onClick={handleSubmit} aria-label="submit"> Submit</button>
           </div>
      </div>
    </div>
  </section>
  );
};

const mapDispatchToProps = { GetNationality, GetSalutations, CreatePerson }
const mapStateToProps = (state) => ({
  profile: state?.auth && state?.auth?.profile,
});
export default connect(mapStateToProps, mapDispatchToProps)(Profile)