import React, { useState } from 'react'
import ReCAPTCHA from "react-google-recaptcha";
import { Link, useNavigate } from 'react-router-dom'
import { Icon } from '@iconify/react'
import images from '../../assets/images'
import { Login } from '../../store/auth/action';
import { connect } from 'react-redux';
import validate from "validate.js";
import { schema } from './schema';
import { isEmpty } from 'lodash';
import TextInput from '../../components/TextInput';
import ErrorModal from '../../components/ErrorModal';
import Loader from '../../components/Loader';
import { RoleType } from '../../util/enum';

const LoginScreen = ({ Login }) => {
  const navigate = useNavigate();
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState();
  const initialState = {
    values: {
      email: "",
      password: "",
      recaptcha: "",
    },
    errors: {
      email: null,
      password: null,
      recaptcha: null,
    },
  };
  const [values, setValues] = useState(initialState.values);
  const [errors, setErrors] = useState(initialState.errors);
  const [loading, setLoading] = useState(false);


  const handleChange = (field, value) => {
    if (field === "email") {
    } else if (field === "password") {
    }
    setValues((prev) => ({ ...prev, [field]: value }));
  };


  const handleClick = (e) => {
    e.preventDefault();
    navigate('/register') 
  };

  const redirectDashboard = (res) => { 
    if (res?.role?.roleId === RoleType.Admin) {
      navigate('/ticket');
      return false
    }
    if (res?.role?.roleId === RoleType.OfficeAdmin) {
      navigate('/registration');
      return false
    }
    if (res?.role?.roleId === RoleType.Customer) {
      if (res && res?.person && res?.person?.profileStatus == 1) {
        navigate('/tickets');
        return false;
      }
      navigate('/profile');
      return false
    }
  }

  const validateForm = () => {
    const errors = validate(values, schema);
    setErrors(errors);
    if (errors) {
      const message =
        !isEmpty(values.email) && !isEmpty(values.password)
          ? errors[Object.keys(errors)[0]][0]
          : "Please enter your email and password to log in.";
      setMessage(message);
      window.$("#errorModal").modal('show');
      return false;
    }
    return true;
  };

  const handleLogin = async (e) => {
    let valid = await validateForm();
    if (valid) {
      setLoading(true);
      const {recaptcha, ...originalValues} = values;
      const res = await Login(originalValues);
      if (res) {
        redirectDashboard(res) 
      } else {
        setLoading(false)
      }
    }
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickPublic = (e) => {
    navigate('/')
  }
  const handleOnClose = () => {
    if (errors) {
      window.$("#errorModal").modal('hide');
    }
  }

  return (<section className='login-section'>
    {loading && <Loader />}
    <ErrorModal message={message} btn1Text="OK" btn1Click={handleOnClose} />
    <div className="container">
    <div className="row justify-content-center">
        <div className="col-md-8 col-lg-6 col-xl-6 col-sm-12">
          <div className="login-sectioncontent">
            <div className="log-header ">
              <div className="text-center">
                <img src={images.footerlogo} className='img-fluid wit-2 ' onClick={handleClickPublic} alt="Footer Logo"/>
              </div>
              <h3 className='mt-3'>Login to Grand Raffle</h3>
              <div className="row">
                <div className="col-md-12">
                  <TextInput
                    type='email'
                    value={values.email}
                    onChange={(e) => handleChange("email", e.target.value)}
                    label='Email'
                    name="email"
                    id='email'
                    error={errors && errors?.email}
                  />
                </div>
                <div className="col-md-12">
                  <TextInput
                    wrapperClass={"psd-tp"}
                    type={showPassword ? 'text' : 'password'}
                    value={values.password}
                    endAdornment={() => <span className='eye-icon' onClick={handleTogglePassword}>
                      {showPassword ? <Icon icon="eva:eye-outline" /> : <Icon icon="tabler:eye-off" />}
                    </span>}
                    onChange={(e) => handleChange("password", e.target.value)}
                    label='Password'
                    name='password'
                    id='password'
                    className="pad-right"
                    error={errors && errors?.password}
                  />
                </div>
                <div className="col-md-12">
                  <div className="forgot-options col-12">
                    <p><Link to="/forgotpassword">Forgot Password ?</Link></p>

                  </div>
                </div>
                <div className="col-md-12">
                  <div className='captcha text-center' style={{ width: '100%', marginTop: '10px' }}>
                    <ReCAPTCHA 
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY} 
                    type='image'
                    onChange={(value)=>handleChange("recaptcha", value)}
                    />
                  </div>
                </div>
                <div className="sub-btn col-12 text-center">
                  <button className="gradient-btn" onClick={handleLogin} aria-label="btn-login">LOGIN</button>
                </div>
                <p className="joinnw">New to Grand Raffle? <Link to="/" onClick={handleClick}>Register Now</Link></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

const mapDispatchToProps = { Login };

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);