import { Icon } from "@iconify/react";
import _ from "lodash";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

const Pagination = (props) => {
    const { items, onChangePage, currentPage, className, prevLink, nextLink } = props 
    const [pageRange, setPageRange] = useState(`1-${props.pageSize}`);
    const [pager, setPager] = useState({})
    const [initialPage, setInitialPage] = useState(1)
    const [pageSize, setPageSize] = useState(props.pageSize)
    const limit = 10;
    useEffect(() => {
        if (items > 0) {
            setPage(initialPage)
            setPageSize(pageSize)
        }
    }, [items])


    const setPage = page => {
        // get new pager object for specified page
        let pager = getPager(items, page, pageSize);

        // get new page of items from items array
        let pageOfItems = limit;

        // update state
        setPager(pager)
        setPageRange(`${pager.startIndex+1}-${pager.endIndex+1}`)
        // call change page function in parent component
        onChangePage(pageOfItems, pager.currentPage);
    };

    const getPager = (totalItems, currentPage, pageSize) => {
        // default to first page
        currentPage = currentPage || 1;
        // default page size is 10
        pageSize = pageSize || limit;

        // calculate total pages
        let totalPages = Math.ceil(totalItems / pageSize);

        let startPage, endPage;
        if (totalPages <= 5) {
            // less than 10 total pages so show all
            startPage = 1;
            endPage = totalPages;
        } else {
            // more than 10 total pages so calculate start and end pages
            if (currentPage <= 3) {
                startPage = 1;
                endPage = 5;
            } else if (currentPage + 2 >= totalPages) {
                startPage = totalPages - 4;
                endPage = totalPages;
            } else {
                startPage = currentPage - 2;
                endPage = currentPage + 2;
            }
        }

        // calculate start and end item indexes
        let startIndex = (currentPage - 1) * pageSize;
        let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

        // create an array of pages to ng-repeat in the pager control
        let pages = [...Array(endPage + 1 - startPage).keys()].map(
            i => startPage + i
        );

        // return object with all pager properties required by the view
        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages
        };
    };
 
    if (!pager.pages || pager.pages.length <= 1) {
        // don't display pager if there is only 1 page
        return null;
    }

    return (<div className={`pagination_rounded justify-content-center ${className} mt-3`}>
        <ul>
            <li>
                <Link to="#" className="prev" disabled={pager.currentPage === 1} onClick={(e) => {e.preventDefault(); setPage(pager.currentPage - 1)}}><Icon icon="grommet-icons:form-previous" /></Link>
            </li>
            {pager.pages.map((page, index) => (
                <li key={index} className={pager.currentPage === page ? "active" : "hidden-xs"}  >
                    <Link to="#" className={pager.currentPage === page ? "active" : ""} onClick={(e) => {e.preventDefault(); setPage(page)}}> {page}</Link>
                </li>
            ))}
            <li>
                <Link to="#" className="next" disabled={pager.currentPage === pager.totalPages} onClick={() => pager.currentPage !== pager.totalPages && setPage(pager.currentPage + 1)}><Icon icon="grommet-icons:form-next" /></Link>
            </li>
        </ul>
        <p className="pgresults">Showing {pageRange} of {items} entries</p>
    </div>)
}

export default Pagination;
