import React from 'react';
import images from '../../assets/images';

const WinnersClub = () => {
  const winnersData = [
    { name: '???' },
    { name: '???' },
    { name: '???' },
    { name: '???' },
    { name: '???' },
    { name: '???' },
    { name: '???' },
    { name: '???' },
    { name: '???' },
    { name: '???' },
    { name: '???' },
    { name: '???' },
    { name: '???' },
    { name: '???' },
    { name: '???' },
  ];

  return (
    <>
      <div className='home-section winner-section'>
        <section className='hero-bg'>
          <section className='hero-section '>
            <div className="container">
              <div className="row">
                <div className="col-md-7 text-center mt-5">
                  <div className="content winner-club">
                    <img src={images.winnerclub} className='img-fluid win-img' alt='Grand Raffle Winner Club' loading="lazy"/>
                    <h4 className='hr-content fadeIn'>Winners of June 10 Raffle Draw</h4>
                    <p>GRAND WINNER</p>
                    <img src={images.winnerclubname} className='img-fluid winname'/>
                    <img src={images.aed} className='img-fluid'/>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className='winner-club'>
                    <img src={images.winside}  className='winner-img img-fluid' />
                  </div>
                </div>
              </div>
              <hr className='hr-a'/>
              <div className="row win-bor justify-content-end">
                <div className="col-md-12">
                  <div className="win-head">
                    <h4>BONUS PRIZE: 20 WINNERS</h4>
                    <img src={images.aed10} className='img-fluid'/>

                  </div>
                </div>
                {winnersData.map((winner, index) => (
                  <div className="col-md-4" key={index}>
                    <div className="win-list">
                    <h4>{winner.name}</h4>

                    </div>
                    
                  </div>
                ))}
              </div>
            </div>
          </section>
        </section>
      </div>
    </>
  );
}

export default WinnersClub;
