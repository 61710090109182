import React from 'react'
import { Icon } from '@iconify/react'

const ErrorModal = ({message, btn1Text, btn1Click, btn2Text, btn2Click, modalId="errorModal"}) => {
  return (
    <div class="modal fade errorModal" data-bs-backdrop="static" id={modalId} tabindex="-1" aria-labelledby="errorModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div className="error-content">
              <Icon icon="mingcute:alert-line" />
              <h5>Error</h5>
              <p>{message}</p>
            </div>
            <div className="btn-modal text-center">
              {btn1Text && <button type="button" class="btn yell-gradient-modal" onClick={btn1Click} aria-label="ok button">{btn1Text}</button>}
              {btn2Text && <button type="button" class="btn grn-gradient-modal" onClick={btn2Click} aria-label="cancel button">{btn2Text}</button>}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ErrorModal