import { Countries, DocTypes } from "../../../util/enum";
const msg = "^Please complete all fields marked with an asterisk (*) before submitting.";

export default {
  email: {
    presence: { allowEmpty: false, message: msg },
    email: { required: true, message: "^Enter a valid email address." },
  },
  salutationId: {
    presence: { allowEmpty: false, message: msg },
  },
  firstName: {
    presence: { allowEmpty: false, message: msg },
  },
  lastName: {
    presence: { allowEmpty: false, message: msg },
  },
  dateOfbirth: {
    presence: { allowEmpty: false, message: msg },
  },
  nationalityId: {
    presence: { allowEmpty: false, message: msg },
  }, 
  mobile: {
    presence: { allowEmpty: false, message: msg },
    length: {
      minimum: 11,
      message: "^Please enter a valid phone number",
    },
  },
  isChecked: {
    presence: { allowEmpty: false, message: "^Please accept the terms & conditions." },
  }
};
