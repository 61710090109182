import { feathers } from '@feathersjs/feathers'
import socketio from '@feathersjs/socketio-client'
import io from 'socket.io-client'
import authentication from '@feathersjs/authentication-client'

const socket = io(process.env.REACT_APP_API_BASE_URL, {
    transports: ['websocket'],
    'reconnection': true,
    'reconnectionDelay': 1000,
    'reconnectionDelayMax': 5000,
    'reconnectionAttempts': 5, autoConnect: true
  });
const client = feathers()

// Setup the transport (Rest, Socket, etc.) here
client.configure(socketio(socket))

// Available options are listed in the "Options" section
client.configure(authentication({
    storageKey: "feathers-jwt",
    jwtStrategy: "jwt",
    storage: window.localStorage,

}))

export default client;

