import iso3166 from 'iso-3166-1-alpha-2';
import { map } from 'lodash';
import moment from 'moment';

export const GetPersonData = (data) => {
    return {
        personId: data?.personId,
        salutationId: data?.salutationId,
        firstName: data?.firstName,
        lastName: data?.lastName,
        email: data?.email,
        nationalityId: data?.nationalityId,
        countryId: data?.countryId,
        dateOfbirth: data?.dateOfbirth ? moment(data?.dateOfbirth).format('YYYY-MM-DD'): '',
        documentTypeId: data?.documentTypeId,
        passportNumber: data?.passportNumber,
        emiratesId:data?.emiratesId,
        documentExpirydate: data?.documentExpirydate ? moment(data?.documentExpirydate).format('YYYY-MM-DD'):'',
        firstTimebuyer: data?.firstTimebuyer && data?.firstTimebuyer == 1 ? true: false,
        source: data?.source,
        addressLine1: data?.addressLine1,
        addressLine2: data?.addressLine2,
        city: data?.city,
        stateId: data?.stateId,
        emarateId: data?.emarateId,
        mobile: data?.mobile,
        homePhone: data?.homePhone,
    }
};

export const CountryCode = (country, countryID) => {
    let temp = country?.find((i) => i.value === countryID);
    let code = iso3166.getCode(temp?.label);
    if (code) {
      return code?.toLocaleLowerCase();
    }
}

export const formatNationality = (nationality) => {
  if (nationality) {
    const res = map(nationality, (n) => {
      return { value: n.nationalityId, label: n.nationality };
    });
    return res
  }
}

export const formatDocumentTypes = (documentType) => {
  if (documentType) {
    const res = map(documentType, (s) => {
      return { value: s?.documentTypeId, label: s?.documentName };
    });
    return res;
  }
}

export const formatStates = (states) => {
  if (states) {
    const res = map(states, (s) => {
      return { value: s?.stateId, label: s?.stateName };
    });
    return res;
  }
}

export const formatEmiratesId = (emiratesId) => {
  if (emiratesId.length>0) {
    const emiratesIdDigits = emiratesId && emiratesId?.replace(/\D/g, '');
    if (emiratesIdDigits.length <= 3){
      return `${emiratesIdDigits?.slice(0, 3)}`
    } else if(emiratesIdDigits.length <= 10){
      return `${emiratesIdDigits?.slice(0, 3)}-${emiratesIdDigits?.slice(3, 10)}`
    } else if(emiratesIdDigits.length > 10){
      return `${emiratesIdDigits?.slice(0, 3)}-${emiratesIdDigits?.slice(3, 10)}-${emiratesIdDigits?.slice(10, 14)}-${emiratesIdDigits?.slice(14)}`;
    }
  }
};

export const getNextDrawScheduleDate = (scheduleList) => {
  if(scheduleList){
    const nextDate = scheduleList && scheduleList?.find((i)=> new Date(i?.schedule) > new Date());
    if(nextDate){
      return moment(nextDate?.schedule)?.format('DD-MM-YYYY');
    }
  }
}

export const getCountryAndStatesName = (countrylist, country, stateList, state) => {
  let countryName = countrylist && countrylist?.find((i) => i?.nationalityId == country)?.nationality;
  let stateName = stateList && stateList?.find((i) => i?.stateId == state)?.stateName;

  if (countryName && stateName) {
    return countryName + ", " + stateName
  }
}

export const getSubTotal = (tickets) => {
  if(tickets){
   let data = tickets?.length;
   return data * 250;
  }
}

export const grandTotal = (tickets) => {
  if (tickets) {
    let total = tickets?.length * 250;
    if (tickets?.length > 2 && tickets?.length < 4 || tickets?.length === 4) {
      return total - 250;
    } else if (tickets?.length > 4 && tickets?.length == 6) {
      return total - 500;
    } else if (tickets?.length === 5) {
      return total - 250;
    } else {
      return total;
    }
  }
};

export const getDiscount = (tickets) => {
  if (tickets) {
    if (tickets?.length > 2 && tickets?.length < 4 || tickets.length === 4) {
      return 250;
    } else if (tickets.length > 5) {
      return 500;
    } else if (tickets.length === 5) {
      return 250;
    } else {
      return 0;
    }
  }
};

export function arrayBufferToBase64(blob, type) {
  var arrayBufferView = new Uint8Array(blob);
  var blob = new Blob([arrayBufferView], { type: type });
  return URL.createObjectURL(blob);
};