import React, { useState, useEffect } from 'react'
import { Icon } from '@iconify/react'
import images from '../../../assets/images'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { Logout } from '../../../store/auth/action'
import { connect } from 'react-redux';
import ChangePasswordModal from '../../../components/ChangePasswordModal'
import RefreshModal from '../../../components/RefreshConfirmationModal'
import { SelectedTicket } from '../../../store/ticket/action'
import { RoleType } from '../../../util/enum'

const Header = ({ Logout, profile, selected, SelectedTicket }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isLogout, setIsLogout] = useState(false)

    const handleLogout = (e) => {
        e.preventDefault();
        if ((location.pathname == "/tickets" || location.pathname == "/ordersummary" || location.pathname == "/payment") && selected?.length > 0) {
            window.$("#refreshModal").modal("show");
            setIsLogout(true)
        } else {
            Logout();
            navigate("/login");
            SelectedTicket([])
        } 
    };

    const handleNaviation = (e) => {
        e.preventDefault();
        if ((location.pathname == "/tickets" || location.pathname == "/ordersummary" || location.pathname == "/payment") && selected?.length > 0) {
            window.$("#refreshModal").modal("show");
        } else {
            navigate("/"); 
        }
    }

    const handleOk = () => {
        window.$("#refreshModal").modal("hide");
        SelectedTicket([])
        if (isLogout) {
            Logout();
            navigate("/login");
        } else {
            navigate("/");
        }
    }


    return (<>
        <ChangePasswordModal />
        <RefreshModal btn1Click={handleOk} />
        <header className="header-area non-public is-sticky">
            <div className="main-header d-none d-lg-block">
                <div className="main-menu-wrapper sticky ">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-lg-4">
                                <div className="brand-logo">
                                    <Link to={"/"} onClick={handleNaviation} >
                                        <img src={images.footerlogo}  className='img-fluid' alt="Logo"/>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="app-menu">
                                    <div className="user-name">
                                        Hello, {profile?.person?.firstName} {profile?.person?.lastName}
                                        <div class="dropdown-center">
                                            <span class="setting-cls dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" >
                                                Account Settings
                                            </span>
                                            <ul class="dropdown-menu ">
                                                {profile && profile?.role?.roleId == RoleType.Customer &&
                                                <li onClick={() => profile.role.roleId == RoleType.Customer ? navigate('/orderhistory') : navigate('/history')}><Link class="dropdown-item" ><Icon icon="material-symbols-light:history" />Order history</Link></li>}
                                                {profile && profile?.role?.roleId !== RoleType.OfficeAdmin &&
                                                <li onClick={() => window.$("#changepasswordmodal").modal('show')}><Link class="dropdown-item" ><Icon icon="tdesign:user-password" />Change password</Link></li>}
                                                {/* <li><hr class="dropdown-divider"/></li> */}
                                                <li><Link class="dropdown-item" to="/login" onClick={handleLogout}><Icon icon="ri:logout-circle-r-line" />Logout</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                    {profile?.person && profile?.person?.profileStatus == true &&
                                        <div className="crt-top text-center">
                                            <Link to="/profile" class="disabled">

                                                <svg width="45" height="45" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M11.2568 31.2222C12.1198 31.2222 12.8193 30.5226 12.8193 29.6597C12.8193 28.7967 12.1198 28.0972 11.2568 28.0972C10.3939 28.0972 9.69434 28.7967 9.69434 29.6597C9.69434 30.5226 10.3939 31.2222 11.2568 31.2222Z" fill="#E4A951" />
                                                    <path d="M20.75 31.2222C21.6129 31.2222 22.3125 30.5226 22.3125 29.6597C22.3125 28.7967 21.6129 28.0972 20.75 28.0972C19.8871 28.0972 19.1875 28.7967 19.1875 29.6597C19.1875 30.5226 19.8871 31.2222 20.75 31.2222Z" fill="#E4A951" />
                                                    <path d="M22.2431 19.6943L21.6736 22.1943H11.2569L8.08334 12.1457C8.04901 12.0391 7.9894 11.9424 7.90959 11.8638C7.82978 11.7852 7.73214 11.727 7.62501 11.6943L4.77778 10.8193C4.69024 10.7924 4.59825 10.783 4.50707 10.7917C4.41589 10.8003 4.32731 10.8269 4.24638 10.8697C4.08293 10.9563 3.96059 11.1043 3.90626 11.2811C3.85192 11.458 3.87005 11.6491 3.95666 11.8126C4.04326 11.976 4.19125 12.0983 4.36806 12.1527L6.86112 12.9166L10.0486 22.986L8.90973 23.9166L8.81945 24.0068C8.53774 24.3315 8.37806 24.7442 8.36792 25.1739C8.35778 25.6036 8.49781 26.0234 8.76389 26.361C8.95317 26.5912 9.19369 26.7739 9.46621 26.8945C9.73873 27.015 10.0357 27.0702 10.3333 27.0555H21.9236C22.1078 27.0555 22.2844 26.9823 22.4147 26.8521C22.5449 26.7218 22.6181 26.5452 22.6181 26.361C22.6181 26.1768 22.5449 26.0002 22.4147 25.87C22.2844 25.7397 22.1078 25.6666 21.9236 25.6666H10.2222C10.1423 25.6638 10.0643 25.6405 9.99602 25.5989C9.92769 25.5573 9.87125 25.4987 9.83215 25.4289C9.79306 25.3591 9.77263 25.2804 9.77284 25.2003C9.77304 25.1203 9.79388 25.0417 9.83334 24.9721L11.5069 23.5832H22.2292C22.3897 23.5872 22.5466 23.5353 22.6732 23.4366C22.7999 23.3378 22.8883 23.1982 22.9236 23.0416L23.6806 19.6943H22.2431Z" fill="#E4A951" />
                                                </svg><span>Cart</span></Link>
                                            <div className="crt-no">
                                                <span>{selected && selected.length > 0  ? selected?.length : 0}</span>
                                            </div>

                                            {/* <Link to="/profile" class="disabled"><Icon icon="mdi-light:cart" /><span>Cart</span></Link> */}
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mobile-header d-lg-none d-md-block sticky">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12">
                            <div className="mobile-main-header">
                                <div className="mobile-logo">
                                    <img src={images.footerlogo}  className='img-fluid' alt="Logo"/>
                                </div>
                                <div className="mobile-menu-toggler">
                                    <button className="mobile-menu-btn" data-bs-toggle="offcanvas" data-bs-target="#mobile-menu" aria-label="mobile-menu">
                                        <Icon icon="mynaui:menu" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="offcanvas offcanvas-start" id="mobile-menu">
                <div className="offcanvas-header">
                    <h1 className="offcanvas-title"></h1>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="btn-close"><Icon icon="flowbite:close-solid" /></button>
                </div>
                <div className="offcanvas-body">
                    <div className="mobile-menu">
                        <nav class="nav flex-column">
                            <Link class="nav-link active" aria-current="" to="/profile"><Icon icon="ei:user" /> Hello Steve,</Link>
                            <Link class="nav-link" onClick={() => window.$("#changepasswordmodal").show()}>Change Password</Link>
                            <Link class="nav-link" to="/profile" onClick={handleLogout}>Logout</Link>
                        </nav>
                    </div>
                </div>
            </div>
        </header>
    </>
    )
}
const mapStateToProps = (state) => ({
    profile: state?.auth && state?.auth?.profile,
    selected: state?.ticket && state?.ticket?.selected,
});
const mapDispatchToProps = { Logout, SelectedTicket };
export default connect(mapStateToProps, mapDispatchToProps)(Header);