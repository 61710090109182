import React, { useState, useEffect, useRef } from 'react';
import { Icon } from '@iconify/react'
import images from '../../assets/images';
import { GetDrawschedule } from '../../store/master/action';
import { connect } from 'react-redux';
import moment from "moment";
import _ from 'lodash';
import { useNavigate } from 'react-router-dom'
import { RoleType } from '../../util/enum';




const CountdownTimer = ({ GetDrawschedule, profile }) => {

  const [drawSchedule, setDrawSchedule] = useState();
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());
  const navigate = useNavigate();

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining(drawSchedule));
    }, 1000);

    // Clear the interval when the component unmounts
    return () => clearInterval(timerInterval);
  }, [drawSchedule]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await GetDrawschedule();
      if (result) {
        const formattedDate = moment(result?.schedule)?.format('YYYY-MM-DD')
        setDrawSchedule(formattedDate);
        calculateTimeRemaining(formattedDate)
      }
    };
    fetchData();
  }, [])

  function calculateTimeRemaining(drawSchedule) {
    if (drawSchedule) {
      const targetDate = new Date(`${drawSchedule}T00:00:00`);
      const currentDate = new Date();

      // Calculate the difference in milliseconds
      const difference = targetDate - currentDate;

      // Calculate days, hours, minutes, and seconds
      const days = _.floor(difference / (1000 * 60 * 60 * 24));
      const hours = _.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = _.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = _.floor((difference % (1000 * 60)) / 1000);

      // Return the result
      return {
        days,
        hours,
        minutes,
        seconds
      };
    }
  }

  const formatTime = (value) => { 
    if(value){
      const res =  String(value).padStart(2, '0').split('')
      return res
    }
    return ['0', '0'] 
  };

  const handleRedirection = () => {
    if (profile && profile?.role.roleId == RoleType.OfficeAdmin) {
      navigate('/registration');
    }
    if (profile && profile?.role.roleId == RoleType.Customer) {
      if (profile && profile?.person?.profileStatus == 1) {
        navigate('/tickets');
      } else if (profile && profile?.person && profile?.person?.profileStatus == null) {
        navigate('/profile');
      }
    }
    if (!profile) {
      navigate('/register');
    }
  };

return (
    <div>
      <h1 className='nxt-drwhead'><img src={images.nexttxt} className='img-fluid' alt='next draw'/></h1>
      <div className="wrap justify-content-center">

        <div className="countdown">
          <div className="bloc-time hours" data-init-value="24">
            {formatTime(timeRemaining?.days).map((digit, index) => (
              <div key={index} className={`dy${index + 1} figure sec sec-${index + 1} gradient${index % 2 === 0 ? 2 : 1}`}>
                <span className="top">{digit}</span>
                <span className="top-back">
                  <span>{digit}</span>
                </span>
                <span className="bottom">{digit}</span>
                <span className="bottom-back">
                  <span>{digit}</span>
                </span>
              </div>
            ))}

            <span className="count-title">Days</span>

          </div>
          <div className="bloc-time hours" data-init-value="24">
            {formatTime(timeRemaining?.hours).map((digit, index) => (
              <div key={index} className={`hr${index + 1} figure sec sec-${index + 1} gradient${index % 2 === 0 ? 2 : 1}`}>
                <span className="top">{digit}</span>
                <span className="top-back">
                  <span>{digit}</span>
                </span>
                <span className="bottom">{digit}</span>
                <span className="bottom-back">
                  <span>{digit}</span>
                </span>
              </div>
            ))}


            <span className="count-title">Hours</span>

          </div>

          <div className="bloc-time min" data-init-value="0">
            {formatTime(timeRemaining?.minutes).map((digit, index) => (
              <div key={index} className={`mm${index + 1} figure sec sec-${index + 1} gradient${index % 2 === 0 ? 2 : 1}`}>
                <span className="top">{digit}</span>
                <span className="top-back">
                  <span>{digit}</span>
                </span>
                <span className="bottom">{digit}</span>
                <span className="bottom-back">
                  <span>{digit}</span>
                </span>
              </div>
            ))}

            <span className="count-title">MINUTES</span>

          </div>

          <div className="bloc-time sec" data-init-value="0">
            {formatTime(timeRemaining?.seconds).map((digit, index) => (
              <div key={index} className={`ss${index + 1} figure sec sec-${index + 1} gradient${index % 2 === 0 ? 2 : 1}`}>
                <span className="top">{digit}</span>
                <span className="top-back">
                  <span>{digit}</span>
                </span>
                <span className="bottom">{digit}</span>
                <span className="bottom-back">
                  <span>{digit}</span>
                </span>
              </div>
            ))}
            <span className="count-title">SECONDS</span>
          </div>
          <button className='com-btn cursor-pointer' onClick={handleRedirection} aria-label="live draw">AED 2.5 MILLION <Icon icon="iconamoon:arrow-right-2-bold" /></button>
        </div>
      </div>
      <div>

      </div>


    </div>



  );
};

const mapDispatchToProps = { GetDrawschedule }
const mapStateToProps = (state) => ({
  profile: state?.auth && state?.auth?.profile,

});

export default connect(mapStateToProps, mapDispatchToProps)(CountdownTimer);
