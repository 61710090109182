import React from 'react'
import { Icon } from '@iconify/react'

const ConfirmationModal = ({ btn1Click, btn1, btn2Click, btn2, title, message }) => {
  
  return (<div class="modal fade successModal" data-bs-backdrop="static" id="confirm-modal" tabindex="-1" aria-labelledby="successModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div className="modalclose" onClick={() => window.$("#confirm-modal").modal("hide")}>
          <Icon icon="ep:close-bold" aria-label="Close" />
        </div>
        <div class="modal-body">
          <div className="success-content">
            <Icon icon="emojione-v1:warning" />
            <h5>{title}</h5>
            <p>{message}</p>
          </div>
          <div className="btn-modal text-center">
            <button type="button" class="btn grey-gradient-modal" onClick={btn1Click} aria-label="confirmation">{btn1}</button>
            <button type="button" class="btn yell-gradient-modal" onClick={btn2Click} aria-label="confirmation">{btn2}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default ConfirmationModal