import React from 'react'

const Disclaimer = () => {
    return (
        <section className='contentsection'>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="cm-content dis-con">
                            <h1>Disclaimer</h1>
                            <h5>PRIVACY AND COOKIES POLICY</h5>
                            <h4>01 APRIL 2024</h4>
                            <ul>
                                <li>This privacy policy (the “Policy”) provides you with information about how We collect your personal data, and about the cookies which are used on Our website <a href="http://www.grandraffle.ae">www.grandraffle.ae</a> (the “Website”).</li>
                                <li>The Website may contain links to third-party sites. We do not control these third-party sites or the companies or individuals who maintain them. We are not responsible for the actions of these third parties, or for any of your personal data that you provide to them after you leave the Website.</li>
                                <li>This Policy was written in English. To the extent any translated version of this Privacy Policy conflicts with the English version, the English version prevails.</li>
                            </ul>

                            <h4>1. Identity of the controller</h4>
                            <p>This Policy outlines the way in which the Operator and Organiser company (“We, Us, Our”), a company registered in UAE, collects and processes the personal data which concerns you (hereinafter, your “Data”). “Personal data” means any information from which it is practicable for the identity of an individual to be ascertained.</p>
                            <p>This Policy is subject to all applicable data protection laws and regulations.</p>

                            <h4>2. Identity and contact details of the Data Protection Officer (DPO)</h4>
                            <p>We have appointed an internal Data Protection Officer.</p>
                            <p>If you have any questions relating to the collection and/or processing of your Data by Us, please contact the data protection officer at the following address: <a href="mailto:grandraffle@truebell.org">grandraffle@truebell.org</a> / <a href="mailto:grandraffle@blumint.in">grandraffle@blumint.in</a>.</p>

                            <h4>3. Data protection collection</h4>
                            <ul>
                                <li>We collect Personal Data about you from various sources. For example, we collect and obtain information:
                                    <ul className='sub-li'>
                                        <li><span>Directly from you:</span>
                                            <ul className='ssub-li'>
                                                <li>We collect personal information you provide, such as when you register for an account or create a profile, contact us, respond to a survey, or sign up to receive emails, phone calls, text messages, and/or postal mailings.</li>
                                            </ul>
                                        </li>
                                        <li><span>Using cookies and other automatic data collection technologies through the Website:</span>
                                            <ul className='ssub-li'>
                                                <li>When you visit Our Website, open or click on emails We send you, or interact with Our advertisements, We or third parties We work with, automatically collect certain information using technologies such as cookies, web beacons, clear GIF, pixels, internet tags, web server logs, and other data collection tools. For more information, please see Section 6 “Cookies” below.</li>
                                            </ul>
                                        </li>
                                        <li><span>From Our third-party partners:</span>
                                            <ul className='ssub-li'>
                                                <li>We obtain information from third parties that We have partnered with, such third-party websites and other third parties We choose to collaborate or work with.</li>
                                            </ul>
                                        </li>
                                        <li><span>From social media platforms and networks:</span>
                                            <ul className='ssub-li'>
                                                <li>If you interact with Us on social media or use features, such as plugins, widgets, or other tools made available by social media platforms or networks (including Instagram, Facebook, Twitter, Google, YouTube) in connection with Our Website, We collect information that you share with Us, or that the social media platforms share with Us. For more information about the privacy practices of those social media platforms, please review the privacy policies and settings of the social media platforms and networks that you use.</li>
                                            </ul>
                                        </li>
                                        <li><span>From other sources:</span>
                                            <ul className='ssub-li'>
                                                <li>For example, We may obtain information about you from other sources, such as data analytics providers, marketing or advertising service providers, fraud prevention service providers, vendors that provide services on Our behalf, or publicly available sources. We also create information based on Our analysis of the information We have collected from you.</li>
                                            </ul>
                                        </li>
                                        <li><span>Children Data:</span>
                                            <ul className='ssub-li'>
                                                <li>Protecting the safety and privacy of children is very important to Us. We do not knowingly collect or use the Data of minors under the age of 18 in accordance with local legislations in force. Please do not submit any Data of a minor without the express consent and participation of the parent or legal guardian.</li>
                                                <li>If you believe that We have collected Data about a minor without such consent, please contact us and We will delete this information.</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>

                            </ul>
                            <h4>4. Data that We collect</h4>
                            <p>The Data which relates to you and which is collected by Us is mainly:</p>
                            <ul>
                                <li>Name prefix (e.g., Mr., Ms.)</li>
                                <li>Contact details</li>
                                <li>Date of birth</li>
                                <li>Passport / Emirates ID number</li>
                                <li>Nationality</li>
                                <li>Country of residence</li>
                                <li>IP address</li>
                                <li>Cookies and trackers</li>
                                <li>Login data</li>
                                <li>Chat conversations</li>
                            </ul>
                            <p>Where you provide Us with any Data relating to any other person (such as any person you nominate to complete your purchase) you warrant and undertake that you have obtained and will maintain that person's consent to the collection and use of that person's Data by Us in accordance with this Policy and that you have explained to that person that their Data must be processed by Us in order to complete the purchase.</p>
                            <h4>5. For which purpose do We collect your Data, on which legal basis?</h4>
                            <p>Your Data is either:</p>
                            <ul>
                                <li>Collected based on your consent;</li>
                                <li>Required for the completion of your order according to a contract; or</li>
                                <li>Collected to fulfil legal requirements.</li>
                            </ul>
                            <p>You have no obligation to provide any information requested by Us. However, if you choose to withhold requested information, We may not be able to provide you with any services that depend on the collection of this information (particularly where Our collection of this information is required by law).</p>
                            <p>Your Data is therefore collected and/or processed notably for the following purposes:</p>


                            <table className='table-bordered'>
                                <thead>
                                    <tr>
                                        <th className='wit-33'>Purpose</th>
                                        <th className='wit-33'>Personal data collected</th>
                                        <th>Legal basis</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>To register to Our Website</td>
                                        <td>Full name, Primary phone numbers, E-mail address, Nationality, Country of residence, Passport/Emirates ID number, Password</td>
                                        <td>Performance of a contract</td>
                                    </tr>
                                    <tr>
                                        <td>Invoicing and payment of your transactions</td>
                                        <td>Title, First name, Last name, Email address, Residence Address</td>
                                        <td>Performance of a contract</td>
                                    </tr>
                                    <tr>
                                        <td>To send you information on Our offers, latest news and events</td>
                                        <td>Title, First name, Last name, Email address, Mailing address, Mobile number your consent </td>
                                        <td>(If required)</td>
                                    </tr>
                                    <tr>
                                        <td>To manage, optimise and personalize customer relations</td>
                                        <td>Title, First name, Last name, Mailing address, Request, Chat, conversations</td>
                                        <td>Performance of a contract</td>
                                    </tr>
                                    <tr>
                                        <td>To publish your victory on Our Website and in different media</td>
                                        <td>Title, First name, Last name, Nationality, Profession, Other relevant information</td>
                                        <td>Performance of a contract</td>
                                    </tr>
                                    <tr>
                                        <td>To save your details and usage preferences</td>
                                        <td>Cookies and trackers, Login data your consent</td>
                                        <td>(If required)</td>
                                    </tr>
                                    <tr>
                                        <td>To ensure the security of online transactions</td>
                                        <td>Identification details, Billing information (transaction details and credit card number)</td>
                                        <td>Legal obligations</td>
                                    </tr>
                                    <tr>
                                        <td>To comply with local requirements or judicial authorities</td>
                                        <td>Data requested by authorities</td>
                                        <td>Legal obligations</td>
                                    </tr>
                                    <tr>
                                        <td>To protect Our business and the Website, including maintenance, support, reporting and hosting of data, detection of intellectual property infringement or misuse of the Data</td>
                                        <td>Necessary for the investigation</td>
                                        <td>Protection of the business</td>
                                    </tr>
                                    <tr>
                                        <td>To comply with the law on safety, security, health safety</td>
                                        <td>Title, First name, Last name, etc.</td>
                                        <td>Legal obligations</td>
                                    </tr>
                                </tbody>

                            </table>

                            <h4>6. How long do We keep your Data?</h4>
                            <p>Your Data, except for tax invoices, is kept five years from the date of completion of the transaction or termination of our business relationship. Your tax invoice is kept 9 years from the tax invoice issuance date.</p>
                            <p>Your personal information will not be kept in a form that allows you to be identified for any longer than is reasonably considered necessary by Us for achieving the purposes for which it was collected or processed or as it is established in the applicable laws related to data retention periods. The data retention term of your Data collected by Us is determined per purpose of data processing according to the criteria below.</p>
                            <p>Data collected for the specific purposes defined in Article 5 will be stored only:</p>
                            <ul>
                                <li>For the duration of your commercial relationship with Us;</li>
                                <li>Except for some specific processing which have shorter retention period: the images collected by CCTV systems, information collected by cookies are retained between 1 hour and 12 months;</li>
                                <li>If a judicial action is initiated, the personal information may be stored until the end of such action, including any potential periods for appeal, and will then be deleted or archived as permitted by applicable law;</li>
                            </ul>
                            <p>Your personal data are then anonymized or deleted.</p>
                            <h4>7. Cookies</h4>
                            <p>Browsing on the Website results in the deposit of cookies, with your consent where required.</p>
                            <p>Cookies are stored on your device when you have browsed the Website. Cookies are small text files which are likely to be stored in a dedicated space on the hard drive of your device (computer, tablet, smart phone, etc.) when you consult an on-line service using your browser.</p>
                            <p>The deposit of certain cookies on your device requires that you give your prior consent. Thus, as soon as you arrive on Our Website, an information banner indicates that We are using this technology and that by clicking on "Accept cookies", you agree to the deposit of these cookies on your device.</p>
                            <p>The cookies used on Our Website are categorized, as follows:</p>
                            <ul>
                                <li>“Strictly necessary” cookies: We use cookies that are “strictly necessary” for the functionality of the Website’s platforms. These cookies are essential to enable users to visit the Website and fulfill express requests from the user (e.g., cookies that temporarily store the contents of an online form). A deactivation of this type of cookies would reduce all or part of the functions of the Website.</li>
                                <li>“Advertising” cookies: targeting and advertising cookies are used to better customize advertisements to your interests. They limit the number of times the same advertisement is shown to you, evaluate the efficacy of an advertising campaign, and analyze how the viewing of a certain advertisement influences the behavior of individual users. Such Cookies are usually placed by advertising networks with the consent of the respective website operator. They register a user’s visit of the website and either pass such information on to other entities, e.g. advertising companies, or directly adapt advertisements accordingly so that, based on your browsing behavior, you will be shown exactly such advertisements as may potentially interest you.</li>
                            </ul>
                            <p>If you have a Facebook account and are logged in, your visit to this Website will be associated with your Facebook user account.</p>
                            <p>We also use Meta’s remarketing feature “Custom Audiences”. This feature enables the display of interest-based ads (“Meta Ads”) to users of Our Website that are visiting Facebook or other websites that use this function. We pursue the interest of displaying ads to you that are relevant to your interests to provide you with a more interesting experience on Our Website.</p>
                            <p>Your browser automatically establishes a direct connection with the Meta server to exchange the relevant data. We have no control over the extent and further processing of the data collected by Meta using this tool and therefore We provide the information available to Us: the integration of Meta Custom Audiences provides Meta with the information that you visited the relevant web page or clicked on one of Our ads. In case you are logged into a “Meta” service, Meta can associate your visit with your account. Even if you do not have a Meta account or are not logged in, there is a possibility that the provider ascertains your IP address and other identifiers and stores them.</p>
                            <p>If you have given your consent, We might pass your telephone number or e-mail address to Facebook to be able to display ads that are relevant to your interests.</p>
                            <p>Find out more about how the Meta is used for advertisement campaigns at <a href="https://www.facebook.com/business/tools/" target='_blank'>https://www.facebook.com/business/tools/</a>.</p>
                            <p>Find more information on Meta’s data policy at <a href="https://www.facebook.com/privacy/policy" target='_blank'>https://www.facebook.com/privacy/policy</a>.</p>
                            <p>The legal basis for this processing is your consent given when you click on the cookie banner displayed when accessing Our Website.</p>
                            <h4>8. Data recipients</h4>
                            <p>We will take all practicable steps to keep your Data confidential but We may disclose, transfer or assign such data to the following parties:</p>
                            <ul>
                                <li>Within the Operator / Organiser Group, especially with the following departments:
                                    <ul>
                                        <li>Communication departments for the management of your requests and questions, and for sending you newsletters;</li>
                                        <li>Security and IT department for managing the cybersecurity of the Website;</li>
                                        <li>Legal and compliance departments for the management of any possible fraudulent acts or legal actions;</li>
                                    </ul>
                                </li>
                                <li>With agents, contractors, third-party service providers (such as those providing compliance, administrative, communications, marketing, computer services, and other services in connection with the managing, processing, and storage of your Data) who will process your Data on Our behalf and following Our instructions based on specific arrangements;</li>
                                <li>If We or any member of the Operator / Organiser Group is acquired or decides to sell or reorganize any relevant part of Our business, to any actual or proposed acquirer, assignee, transferee, or successor who agrees to assume Our obligations to you under this Policy, including at the negotiation stage;</li>
                                <li>With financial institutions, card or other payment instrument issuers, credit providers, or debt collection agencies; or</li>
                                <li>In order to comply with applicable laws or regulations (whether of your jurisdiction or elsewhere) or to protect Our rights or property; in order to comply with a court order or other legal process;</li>
                                <li>In response to a request by a government authority, law enforcement agency, or similar body (whether situated in your jurisdiction or elsewhere); or where We believe it is necessary to comply with applicable laws or regulations.</li>
                            </ul>
                            <h4>9. Data transfers to third countries</h4>
                            <p>You consent to the processing and storage of your Data in locations other than the location from which you access the Website. Since Operator and Organiser are international groups and work with data processors located abroad, the recipients of your Data may also be located abroad, in countries/regions where runs its activities.</p>
                            <p>Any transfer of Data will be governed by appropriate guarantees, notably contractual safeguards, in accordance with the regulations applying to the protection of your Data. We have taken all reasonable steps to ensure the security and confidentiality of your Data when it is accessed or stored in locations other than the one from which you access the Website.</p>
                            <p>Payment and associated information are handled by Network International-hosted payment gateway and We will not store any credit card information.</p>
                            <h4>10. Your rights</h4>
                            <p>In accordance with the regulations in force, you have the following rights:</p>
                            <ul>
                                <li>Withdrawal of consent: you can withdraw at any time your consent for any processing of personal data based on your consent, without affecting the lawfulness of processing based on your consent before its withdrawal;</li>
                                <li>Access: you can ask Us to confirm whether We process your Data and, as the case may be, inform you of the characteristics of such processing;</li>
                                <li>Erasure: you can ask Us to erase your personal data in the following cases: when it is no longer necessary for the purposes for which it was collected; you have withdrawn your consent and there is no other legal basis on which your data are processed; you objected to the processing of your personal data and DFS has no compelling legitimate grounds to refuse it; your personal data has been processed unlawfully; or to comply with a legal obligation. We are not required to comply with your request notably if the processing of your personal data is necessary for compliance with a legal obligation or for the establishment, exercise, or defense of legal claims;</li>
                                <li>Correction: you can ask Us to rectify or complete inaccurate or incomplete personal data;</li>
                                <li>Restriction: it means that, in some cases, you have the right to ask Us to temporarily freeze the use of some of your Data;</li>
                                <li>Objection: (including to marketing) you can request not to receive direct marketing communications from Us and for Us not to disclose your Data to others for direct marketing purposes;</li>
                                <li>Transfer: you have the right to obtain and/or use your Data in an appropriate form and/or in a commonly used structure or a format that can be read by electronic systems or interoperable between electronic systems.</li>
                            </ul>
                            <p>These rights may be exercised at any time by contacting the Data Protection Officer at the address given in Article 2 of this Privacy and cookie Policy, or by following the relevant instructions on Our marketing communications or following the instructions as set out in certain service-specific guidance.</p>
                            <h4>11. Changes to this policy</h4>
                            <p>We may revise this Policy from time to time by posting the amended terms on the Website. All amended terms automatically take effect immediately upon posting. Your continued use of the Website after an updated Privacy Policy is posted signifies that you accept all its revisions.</p>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Disclaimer