import React from 'react'

const index = () => {
    return (
        <section className='contentsection'>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="cm-content">
                            <h1>POLICY AND TERMS</h1>

                            <p>The Fujairah Airport Duty Free Grand Raffle Program (“Grand Raffle”), comprising such raffles specified in these Policy and terms (“POLICY AND TERMS”), are organized by Fujairah International Airport and managed by Truebell Management Consultancy FZ LLC (the “Operator”) in association with Blumint Enterprises LLC-FZ (the “Organiser”). Any references to “We”, “Us”, and “Our” in the Policy and terms are references to the Operator/Organiser. By participating in any of the Grand Raffle Programs, you agree to be bound by these Policy and terms.</p>
                            <p>These Policy and terms are valid as of 1 April 2024 until further notice and are applicable to all Grand Raffle tickets (“ticket”) purchased from Our stores and online at <a href="https://www.grandraffle.ae">https://www.grandraffle.ae</a> Website.</p>
                            <p>For more information, please contact us on (+971) 600 3334726 (Monday to Thursday: 9:00 AM-3:30 PM GST, Friday and Saturday: 9:00 AM - 12:30 PM GST) or by sending an email to <a href="mailto:contact@grandraffle.ae">contact@grandraffle.ae</a>.</p>
                            <ol>
                                <li>
                                    <h4>1. Account Registration and Purchase of Ticket</h4>
                                    <ol>
                                        <li><span>1.1</span> You must be at least 18 years of age to register an account online and purchase a ticket.</li>
                                        <li><span>1.2</span> You must not use the name of anyone else on the ticket. If you do, the Operator reserves the right to take whatever action it considers appropriate, including irrevocable forfeiture of any prize.</li>
                                        <li><span>1.3</span> You are only allowed to register and operate one online account.</li>
                                        <li><span>1.4</span> You can only register an account and purchase a ticket if you are not prohibited or restricted to do so under the laws of your jurisdiction of residence or jurisdiction in which you are located. The ability to register an account and purchase a ticket does not constitute an offer or invitation by the Operator for persons to create an account or purchase a ticket in any jurisdiction in which such activities are prohibited or restricted.</li>
                                        <li><span>1.5</span> In addition to the other requirements and restrictions described in these Policy and terms, you are not permitted to purchase a ticket if you are:
                                            <ol type="a">
                                                <li>(a) A business partner, director, or employee of the Operator/Organiser involved in the Grand Raffle operations.</li>
                                                <li>(b) A specified employee of any of the Operator/Organisers’s key consultants, contractors, or sub-contractors.</li>
                                                <li>(c) An immediate family member of those mentioned in Section 1.5(a) and 1.5(b).</li>
                                                <li>(d) A resident of a sanctioned country as determined by the Operator/Organiser.</li>
                                                <li>(e) On a global list of high risk (including, without limitation, money launderer, terrorist, and criminal offender) or sanctioned individuals.</li>
                                            </ol>
                                        </li>
                                        <li><span>1.6</span> The Operator reserves the right at its sole discretion to refuse you from opening an account and purchasing a ticket without giving a reason.</li>
                                        <li><span>1.7</span> If the Operator subsequently discovers that you were not or are no longer eligible to open, hold, and use an account, the Operator reserves the right to take all appropriate steps in relation to you and your account, including, without limitation, closing your account in accordance with Section 4.</li>
                                        <li><span>1.8</span> It is your responsibility to check your order carefully before completing any ticket order or purchase. All ticket sales are final and no refunds will be given under any circumstances, unless otherwise specifically stated.</li>
                                        <li><span>1.9</span> It is solely your responsibility to ensure that any ticket purchased at Our store are dropped in the raffle drum of the counter at the store immediately after the purchase. Any ticket not dropped in the raffle drum immediately upon purchase will not be eligible to participate in the draw. Also, any ticket purchased online will be dropped in the raffle drum by the Operator/Organiser representative at the airport office on the very next working day and it will be intimated to you through email.</li>
                                        <li><span>1.10</span> A ticket is only valid for the draw series stated on the ticket and is not transferable to another draw series.</li>
                                    </ol>
                                </li>
                                <li>
                                    <h4>2. Online Account</h4>
                                    <ol>
                                        <li><span>2.1</span> You must use your account only when you are physically located in a jurisdiction where it is lawful to do so. The Operator cannot provide you with any legal advice or assurances in respect of your use of account. You should check the relevant laws in your jurisdiction before using your account.</li>
                                        <li><span>2.2</span> You are responsible for making sure all your account information is accurate and up to date.</li>
                                        <li><span>2.3</span> You must always keep your password secure and secret and take steps to prevent it being used by anyone else. You should, for example:
                                            <ol type="a">
                                                <li>(a) Memorize your password and never tell it to anyone.</li>
                                                <li>(b) Never write your password down or record it in a way that can be understood by someone else.</li>
                                                <li>(c) Destroy any communication from the Operator in relation to your password as soon as you have read them.</li>
                                                <li>(d) Avoid using a password that is easy to guess.</li>
                                                <li>(e) Contact the Operator immediately if anyone has used your account or given any instruction in relation to it without your permission, or if you think someone else knows your password.</li>
                                            </ol>
                                        </li>
                                        <li><span>2.4</span> If you forget your password, you can reset it by following the instructions on the Website account login page.</li>
                                    </ol>
                                </li>
                                <li>
                                    <h4>3. Play Limits </h4>
                                    <ol>
                                        <li><span>3.1</span> A maximum limit of purchase value for AED 1000 per transaction is automatically set on your account.</li>
                                    </ol>
                                </li>
                                <li>
                                    <h4>4. Closing an Account</h4>
                                    <ol>
                                        <li><span>4.1</span> If you wish to close your account, you may do so at any time by contacting the Operator.</li>
                                        <li><span>4.2</span> Accounts are limited to one per person. If the Operator discovers that you have more than one account or believes a third-party account is being used by you or on your behalf, the Operator reserves the right to close some or all of those accounts and take whatever action the Operator reasonably considers appropriate.</li>
                                        <li><span>4.3</span> The Operator may close your account and refuse future purchase of any ticket immediately, without giving you prior notice or reasons, if you breach these Policy and terms or for any other reason at the Operator’s sole discretion.</li>
                                        <li><span>4.4</span> If your account has been closed by the Operator, or you have requested to close your account, any ticket purchased for a particular draw series prior to account closure or exclusion is non-refundable and will still enter the draw. Prizes due must be claimed in accordance with Section 8.</li>
                                    </ol>
                                </li>
                                <li>
                                    <h4>5. Use of Payment Card</h4>
                                    <ol>
                                        <li><span>5.1</span> You must not use any payment method belonging to a third party to purchase a ticket without their consent. Any fraudulent or unauthorized use of payment cards is prohibited and will result in automatic draw disqualification and irrevocable forfeiture of the prize.</li>
                                    </ol>
                                </li>
                                <li>
                                    <h4> 6. Promotional Offers</h4>
                                    <ol>
                                        <li><span>6.1</span> From time to time, the Operator may conduct marketing competitions/games or make promotional offers.</li>
                                        <li><span>6.2</span> Each competition/game or promotional offer will have its own Policy and terms (“Specific Terms”), which will be made clear at the time the competition/game or promotional offer is launched on the Website, the Operator’s social media platforms or in any media which features the competition/game or promotional offer. These Specific Terms shall be read in conjunction with these Policy and terms where applicable.</li>
                                        <li><span>6.3</span> The competitions/games and promotional offers are open only to those who meet any eligibility requirements detailed in the Specific Terms and in any event, you cannot participate if you do not meet the requirements as set out in these Policy and terms.</li>
                                        <li><span>6.4</span> Tickets sold are subject to the Operator’s right to alter the advertised promotion as reasonably necessary. No refunds or replacements on any purchase transactions will be owed because of changes to the advertised promotion.</li>
                                        <li><span>6.5 </span>The Operator reserves the right, when reasonably required to do, to replace the stated competition/game or promotional offer prizes with prizes that the Operator considers to be broadly equivalent in value. The Operator does not offer cash alternative for non-cash competition/game prizes.</li>
                                        <li><span>6.6</span> The Operator may disqualify you, at the sole discretion of the Operator, if the Operator believes that you are in breach of the Specific Terms and these Policy and terms.</li>
                                        <li><span>6.7</span> Entries received after the relevant advertised closing date for a competition/game or promotional offer will not be accepted.</li>
                                        <li><span>6.8 </span>The Operator reserves the right to request proof of a competition prize winner’s identity or any document that may reasonably be required from promotional offer prize winner. If a competition or game winner cannot provide the Operator with proof of identity or any document that is reasonably acceptable to the Operator, the Operator may withdraw the competition or game prize and select another winner.</li>
                                    </ol>
                                </li>
                                <li>
                                    <h4>7. Draw and Results</h4>
                                    <ol>
                                        <li><span>7.1</span> The Operator will decide the time, frequency, date, and method of the draws.</li>
                                        <li><span>7.2</span> In the event of any alleged fault, mistake, misunderstanding, or dispute concerning the operation of any part of the prize draw, networks or systems, the decision of the Operator shall be final.</li>
                                        <li><span>7.3</span> Each draw will be conducted in line with the Operator’s draw procedure and in the presence of witnesses who are responsible for verifying the draw process.</li>
                                        <li><span>7.4</span> Unless otherwise specifically stated in promotional offer, each ticket drawn is limited to winning one prize per draw and you shall be deemed to accept the prize first awarded to you.</li>
                                        <li><span>7.5</span> If a draw cannot take place on the date or at the time fixed, it will take place as soon as reasonably possible as determined by the Operator.</li>
                                        <li><span>7.6</span> The Operator will refund you the amount you paid for the ticket entry into the draw only if it is cancelled.</li>
                                        <li><span>7.7</span> If any draw is declared invalid or interrupted due to equipment failure or for any other reason, the draw will be completed in line with the Operator’s draw procedure.</li>
                                        <li><span>7.8</span> Only the ticket drawn confirmed by witnesses mentioned in Section 7.3 will be the winning ticket for that draw.</li>
                                        <li><span>7.9</span> Official results will be published on the Website and the Operator’s social media platforms including Facebook and Instagram after the relevant draw. Also, the Operator/Organiser will directly contact the winner from the contact number provided at the time of registration for Prize intimation.</li>
                                        <li><span>7.10</span> You will not be entitled to claim for any compensation and the Operator will not be bound by any errors or incorrectly announced or published winning ticket number.</li>
                                    </ol>
                                </li>
                                <li>
                                    <h4>8. Claiming Prizes</h4>
                                    <ol>
                                        <li>
                                            <span>8.1</span> You can only claim a prize if you are the named winner. The Operator will contact you, using your registered contact details, after the relevant draw to inform you how to claim your prize.
                                        </li>
                                        <li>
                                            <span>8.2</span> To receive the prize, a winner is required to provide the Operator with such documents as it requires to verify the winner’s age, identity and/or eligibility. This could include (without limitation):
                                            <ol>
                                                <li>(a) a copy of customer portion of the original winning ticket (for store purchase) or the PDF version of the winning ticket (for online purchase);</li>
                                                <li>(b) proof of winner’s identity in the form of a passport or national identification document used for registration at the time of ticket purchase; and</li>
                                                <li>(c) proof of residential address dated within three months.</li>
                                            </ol>
                                            Failure to provide such document(s) and information within thirty days from the Operator’s request will result in the irrevocable forfeiture of the prize. No prizes will be handed over to any winner unless all the requirements of the Operator are fulfilled.
                                        </li>
                                        <li>
                                            <span>8.3</span> Cash prize winners are required to provide the Operator with such documents as it requires for prize payment processing via bank transfer. This could include (without limitation):
                                            <ol>
                                                <li>(a) bank transfer authorization letter; and</li>
                                                <li>(b) stamped bank issued letter confirming winner’s identification in line with Section 8.2(b) and bank account details.</li>
                                            </ol>
                                        </li>
                                        <li><span>8.4</span> You must be at least 18 years old to claim a prize.</li>
                                        <li><span>8.5</span> Winners must collect their prize within six months from the draw date. If the winner fails to do so, the prize will be irrevocably forfeited. Any unclaimed prizes will not be added to future draws.</li>
                                    </ol>
                                </li>
                                <li>
                                    <h4>9. Payment of Prize</h4>
                                    <ol>
                                        <li><span>9.1</span> All cash prizes will be made in UAE Dirham (AED).</li>
                                        <li><span>9.2</span> The Operator will only pay a prize based on the official results of the relevant draw published on the Website. The Operator will not pay a prize based on results recorded anywhere else.</li>
                                        <li><span>9.3</span> Any prize due will be paid to the person whose name is printed on the winning ticket only, except for situation described in Sections 8.5 and 9.4. The Operator is not responsible or liable for any disputes about ownership of the ticket.</li>
                                        <li><span>9.4</span> The Operator may, at the Operator’s sole discretion, pay the prize to your authorized representative if you are unable to claim a prize due to legal, physical, or other disability. In the event of the death of a winner, a person can claim the prize on behalf of such deceased’s estate if he or she can prove, to the Operator’s satisfaction, that he or she is entitled to make the prize claim, for example, by providing a grant of probate. The person claiming will need to submit any relevant proof and document as required by the Operator.</li>
                                        <li><span>9.5</span> The Operator is not liable to pay a prize if you do not meet the requirements set out in these Policy and terms. If a prize is paid to anyone who does not meet such requirements or is paid to someone erroneously, that person will be required to repay the prize immediately upon the Operator’s request.</li>
                                        <li><span>9.6</span> Prize payment will be made by cheque or directly to winner’s bank account in the winner’s country of residence. Exceptions can only be made in extraordinary circumstances and at the sole discretion of the Operator.</li>
                                        <li><span>9.7</span> The Operator will not be liable for any tax or administration charges incurred directly or indirectly as a result of prize payment. All charges will be borne by the prize winner.</li>
                                        <li><span>9.8</span> The Operator may withhold delivery of a prize until it has received supporting evidence from the winner and failure to provide supporting evidence in a timely manner may result in forfeiture of the prize.</li>
                                        <li><span>9.9</span> The Operator will not be obliged to pay any prize if the Operator reasonably believes that you are not the winner or the holder of the relevant account, or that the information provided by you is incomplete or has been altered or tempered with.</li>
                                    </ol>
                                </li>
                                <li>
                                    <h4>10. Personal Information</h4>
                                    <ol>
                                        <li><span>10.1</span> By participating in Grand Raffle Programs, you are giving consent to the collection, use, and storage of your personal data by the Operator. The processing of your information will be carried out by the Operator in line with the Privacy Policy.</li>
                                        <li><span>10.2</span> Your information on the ticket may not be removed, altered, defaced, modified, copied, or reproduced in any way. It is your responsibility to ensure that all information provided on the account registration or ticket is true, accurate, and up to date (e.g., ID number, full name, nationality, etc.). You must be able to verify, in whatever way the Operator decides, that any information supplied to the Operator is genuine. No details will be altered once the transaction has been completed, and any ticket found to be altered will be invalidated at the sole discretion of the Operator.</li>
                                        <li><span>10.3</span> By participating in the Grand Raffle Programs, if you win a prize, you are giving consent to the Operator disclosing and publishing your personal data publicly as is deemed appropriate in the sole discretion of the Operator, including information such as name, nationality, photos, etc. You will take part in reasonable publicity and public relations activity at the Operator’s request. This information may be disclosed to media and public relations companies.</li>
                                        <li><span>10.4</span> If you win a prize, you understand and accept that the Operator may, if requested or required by a government agency or body, provide your personal information and the details of any prize claimed by you or paid to you.</li>
                                    </ol>
                                </li>
                                <li>
                                    <h4>11. Limitation of Liability</h4>
                                    <ol>
                                        <li><span>11.1</span> The Operator will not be liable to you for any loss or damage which may result directly or indirectly from:
                                            <ol>
                                                <li>(a) Events beyond its reasonable control and expectations (including, without limitation, epidemic or pandemic, war, strike, fire, flood, drought, power cut and/or failure or obstruction of any network, broadcasting or telecommunications service);</li>
                                                <li>(b) Delay, postponement or cancellation of any draw;</li>
                                                <li>(c) The failure or destruction of, or damage to, all or part of the computer systems or records of the Operator or any third party;</li>
                                                <li>(d) Any mistake caused by all or part of the computer systems or records of the Operator or any third party;</li>
                                                <li>(e) Delays, losses, mistakes or omission in or made by the postal or other delivery service or by the banking and/or payment system;</li>
                                                <li>(f) Any other action or event which prevents or hinders the issue of a valid entry (including, without limitation, the failure of the Website to display correctly on any device used to view it);</li>
                                                <li>(g) The refusal to sell a ticket to you or to allow you to enter a draw;</li>
                                                <li>(h) Any losses caused by you, including the misuse or unauthorized use of passwords and the failure to keep the Operator informed of changes to your personal contact details;</li>
                                                <li>(i) Failure or malfunction of your equipment or technology or your internet service provider or mobile phone network operator or mobile phone operating system, or the equipment, technology, internet service provider or mobile phone network operator of any other person or third party.</li>
                                            </ol>
                                        </li>
                                        <li><span>11.2</span> The Operator will not be responsible or liable to you if you contravene the laws of your jurisdiction of residence or jurisdiction in which you are located.</li>
                                        <li><span>11.3</span> The Operator will not be liable to you if in its sole opinion it determines it is prohibited by any applicable law and regulation from providing you with the prize (including, without limitation, due to a sanctions designation).</li>
                                        <li><span>11.4</span> Nothing in these Policy and Terms will exclude the Operator's liability for fraud or death or personal injury resulting from its own gross negligence or willful default.</li>
                                    </ol>
                                </li>
                                <li>
                                    <h4>12. General</h4>
                                    <ol>
                                        <li><span>12.1</span> The Website, in-store service, and the draw are operated by the Operator/Organiser.</li>
                                        <li><span>12.2</span> The Operator/Organiser is the authorized user of intellectual property rights, including copyright and trademarks, in all the offering of services to you and content on the Website. The Operator does not grant you any rights to intellectual property.</li>
                                        <li><span>12.3</span> The Operator may refuse to provide a prize, or seek its recovery, in the event of non-entitlement under the Policy and Terms, a breach of these Policy and Terms, or any fraud or dishonesty.</li>
                                        <li><span>12.4</span> The Operator may change these Policy and Terms including the Privacy Policy, any competitions/ games and promotional offers Specific Terms, and any other rules and procedures issued by the Operator from time to time. Such changes will be effective from the date of publication. If the Operator is making material changes, the Operator will give you as much prior notice of the changes as is reasonably practicable. Notification will be by email, or any form of communication reasonably decided by the Operator. You agree that you will be bound by the changes when you next access your account, purchase a ticket, enter a draw, or claim a prize after the changes have become effective or (where relevant) when you expressly accept the changes, whichever takes place first.</li>
                                        <li><span>12.5</span> In the event of any conflict or inconsistency between the Policy and Terms or rules on the Website, in-store service, and any applicable competition/ game or promotional offer, these Policy and Terms will take precedence.</li>
                                        <li><span>12.6</span> Except if the Operator assigns and transfers its rights and/ or obligations under these Policy and Terms, a person who is not a party to these Policy and Terms has no rights or otherwise to enforce any provision of these Policy and Terms.</li>
                                    </ol>
                                </li>
                                <li>
                                    <h4>13. Law</h4>
                                    <ol>
                                        <li><span>13.1</span>These Policy and Terms shall be exclusively governed by and construed in accordance with the Federal laws of courts of Fujairah and the courts in the UAE.</li>
                                    </ol>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default index