import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const SelectDate = ({
  className,
  name,
  placeholderText = "DD/MM/YYYY",
  selected,
  value,
  onChange,
  peekNextMonth,
  showMonthDropdown = true,
  showYearDropdown = true,
  dropdownMode = "select",
  dateFormat = "dd/mm/yyyy",
  minDate,
  maxDate,
  error,
  futureDateOnly=false,
}) => {
  return (
    <DatePicker
      autoComplete="nope"
      className={`${className ? className : ''}`}
      wrapperClassName={`${error ? "redborder " : ""}`}  
      name={name}
      placeholderText={placeholderText}
      selected={selected}
      value={value}
      onChange={onChange}
      peekNextMonth={peekNextMonth}
      showMonthDropdown={showMonthDropdown}
      showYearDropdown={showYearDropdown}
      dropdownMode={dropdownMode}
      dateFormat={dateFormat}
      minDate={futureDateOnly && new Date()}
      maxDate={maxDate ? maxDate : !futureDateOnly && new Date()} 
      error={error}
    />
  )
}

export default SelectDate