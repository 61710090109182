import React from "react";
import { connect } from 'react-redux'; 
import { Link } from "react-router-dom";

const PageNotFound = ({}) => { 
    return <div>
        <section>
            <div className="error-content text-center">
                <h1 className="bgm-txt">404</h1>
                <div className="overlay-txt">
                    <h2>We Are Sorry , Page Not Found!</h2>
                    <p>Page you are looking for might have been removed had its name changed or is tempororily unavailable.</p>
                    <div className="agencybtn1 mt-4">
                        <Link to={"/"}>Back to home page</Link>
                    </div>
                </div>
            </div>
        </section>
    </div>
};




const mapDispatchToProps = {};




const mapStateToProps = (state) => ({});




export default connect(mapStateToProps, mapDispatchToProps)(PageNotFound);