let pass = (
  <div> 
    <p>Please ensure your password meets these criteria for security:</p>
    <ul style={{ listStyleType: 'disc', textAlign: 'left', }} className="mb-3"> 
      <li>8 characters minimum</li>
      <li>At least one uppercase letter (A-Z)</li>
      <li>At least one lowercase letter (a-z)</li>
      <li>At least one number (0-9)</li>
      <li>At least one special character (!@#$)</li>  
    </ul>
    </div>
);
  
  let msg = `^The passwords you entered do not match. Please make sure the 'New Password' and 'Confirm Password' fields are identical to ensure a smooth account creation.`
  
export const schema={
    password: {

        presence: { allowEmpty: false },
    
        length: {
    
          minimum: 8,
    
          maximum: 16,
    
          message:  pass,
    
        },
        format: {
    
          pattern: /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W]).{6,20})/,
    
          message: pass,
    
        },
    
      },
    
      confirm: {
    
        presence: { allowEmpty: false, message: "^Please complete all required fields with valid entries before proceeding." },
    
        length: {
    
          maximum: 16,
    
        },
    
    
        equality: {
    
          attribute: "password",
    
          message: msg,
    
          comparator: (v1, v2) => {
    
            return v1 === v2;
    
          },
    
        },
    
      },
};