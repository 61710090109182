import { Icon } from "@iconify/react";
import Select from "../../../components/Select";
import TextInput from "../../../components/TextInput";
import DatePicker from '../../../components/DatePicker'
import moment from "moment";
import PhoneInput from "../../../components/PhoneInput";
import { formatEmiratesId } from "../../../util/helper";
import { DocTypes } from "../../../util/enum";

export const ProfileInfo = ({ errors, salutations, values, handleChange, nationality, docTypes }) => {
  const firsttimebuyer = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
  ];
  const sources = [
    { value: 1, label: 'Advertising (newspaper/magazine, radio, website ads)' },
    { value: 2, label: 'Social Media (Facebook/Twitter)' },
    { value: 3, label: 'Fujairah Airport Website' },
    { value: 4, label: 'Friend' },
    { value: 6, label: 'Others' },
  ];

  const calculateMaxDate = () => {
    const currentDate = new Date();
    const maxDate = new Date(currentDate.getFullYear() - 18, currentDate.getMonth(), currentDate.getDate());
    return maxDate;
  };


  return <div className='col-md-6'>
    <div className='login-sectioncontent'>
      <div className='log-header'>
        <div className='row mt-3 profile-form mb-5'>
          <div className='col-md-12 rd-btn'>
            <div className="button-wrap">
              Title :
              {salutations && salutations.length ? salutations?.map((i, index) => {
                return (<span key={index}>
                  <input value={i.salutationId} className="hidden radio-label gender-options" type="radio"
                    name="accept-offers" id={index} onChange={(e) => handleChange("salutationId", Number(e.target.value))}
                    checked={values?.salutationId == i.salutationId && "checked"} />
                  <label className={`button-label ${errors && errors?.salutationId && 'error-box'}`} htmlFor={index}>
                    <h1>{i.Title}</h1>
                  </label>
                </span>)
              }):''}
            </div>
          </div>
          <div className="col-md-12">
            <TextInput
              type='text'
              value={values?.firstName}
              onChange={(e) => handleChange("firstName", e.target.value)}
              label='First Name'
              error={errors && errors?.firstName}
            />
          </div>
          <div className="col-md-12">
            <TextInput
              type='text'
              value={values?.lastName}
              onChange={(e) => handleChange("lastName", e.target.value)}
              label='Last Name'
              error={errors && errors?.lastName}
            />
          </div>
          <div className='col-md-12'>
            <TextInput
              type='text'
              value={values?.email}
              onChange={(e) => handleChange("email", e.target.value)}
              label='Email'
              disabled={values && values?.personId > 0}
              error={errors && errors?.email}
            />
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <div className="date" data-date-format="mm/dd/yyyy">
                <DatePicker
                  className="form-control calender-wrap"
                  placeholderText={"Date of Birth *"}
                  dropdownMode="select"
                  name="dateOfbirth"
                  dateFormat="dd/mm/yyyy"
                  selected={values?.dateOfbirth ? values?.dateOfbirth : ""}
                  value={values?.dateOfbirth ? moment(values?.dateOfbirth).format("DD-MM-YYYY") : ""}
                  onChange={(date) => handleChange("dateOfbirth", date)}
                  error={errors && errors?.dateOfbirth}
                  futureDateOnly={false}
                  maxDate={new Date(calculateMaxDate())}
                />
                <div className="ic-calender">
                  <Icon icon="uiw:date" style={{ cursor: "pointer" }} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <Select
              className="custom-select-class"
              options={nationality && nationality}
              name="nationalityId"
              value={
                nationality &&
                nationality.filter(
                  (option) => option?.value === values?.nationalityId
                )
              }
              placeholder="Nationality *"
              onChange={(selectedOption) => {
                if (selectedOption) {
                  handleChange("nationalityId", selectedOption?.value);
                }
                else {
                  handleChange("nationalityId", null);
                }
              }}
              error={errors && errors?.nationalityId}
            />
          </div>
          <div className="col-md-12">
            <div className="custom-phonenumber">
              <PhoneInput
                id={"mobile"}
                inputProps={{
                  name: 'mobile',
                  required: true,
                }}
                placeholder={"xxxxx-xxxxx"}
                name={"mobile"}
                value={values?.mobile}
                onChange={(e, country) => handleChange("mobile", e, country)}
                error={errors && errors?.mobile}
              />
            </div>

          </div>
          <div className="col-md-12">
            <Select
              className="custom-select-class"
              options={docTypes && docTypes}
              name="documentTypeId"
              placeholder="Document Type *"
              value={
                docTypes &&
                docTypes.filter(
                  (option) => option?.value === values?.documentTypeId
                )
              }
              onChange={(selectedOption) => {
                if (selectedOption) {
                  handleChange("documentTypeId", selectedOption?.value);
                }
                else {
                  handleChange("documentTypeId", null);
                }
              }}
              error={errors && errors?.documentTypeId}
            />
          </div>
          {values?.documentTypeId && values?.documentTypeId === DocTypes.Passport &&
            <div className='col-md-12'>
              <TextInput
                type='text'
                value={values?.passportNumber}
                onChange={(e) => handleChange("passportNumber", e.target.value )}
                label='Passport No'
                error={errors && errors?.passportNumber}
                required={true}
                maxLength={15}
              />

            </div>}
          {values?.documentTypeId && values?.documentTypeId === DocTypes.EmiratesID &&
            <div className='col-md-12'>
              <TextInput
                type='text'
                value={values?.emiratesId && formatEmiratesId(values?.emiratesId)}
                onChange={(e) => handleChange("emiratesId", e.target.value)}
                label='Emirates Id'
                error={errors && errors?.emiratesId}
                required={true}
                maxLength={18}
              />
            </div>
          }

          <div className="col-md-12">
            <div className="form-group">
              <div className="date" data-date-format="mm/dd/yyyy">
                <DatePicker
                  className="form-control calender-wrap"
                  placeholderText={"Document Expiry Date *"}
                  dropdownMode="select"
                  name="dateOfbirth"
                  dateFormat="dd/mm/yyyy"
                  selected={values?.documentExpirydate ? values?.documentExpirydate : ""}
                  value={values?.documentExpirydate ? moment(values?.documentExpirydate).format("DD-MM-YYYY") : ""}
                  onChange={(date) => handleChange("documentExpirydate",date)}
                  error={errors && errors?.documentExpirydate}
                  futureDateOnly={true}
                />
                <div className="ic-calender">
                  <Icon icon="uiw:date" style={{ cursor: "pointer" }} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <Select
              className="custom-select-class"
              options={firsttimebuyer && firsttimebuyer}
              name="firstTimebuyer"
              placeholder="First Time Buyer *"
              value={
                firsttimebuyer && values?.firstTimebuyer &&
                firsttimebuyer.filter(
                  (option) => option?.value == values?.firstTimebuyer
                )
              }
              onChange={(selectedOption) => {
                if (selectedOption) {
                  handleChange("firstTimebuyer", selectedOption?.value);
                }
                else {
                  handleChange("firstTimebuyer", null);
                }
              }}
              error={errors && errors?.firstTimebuyer}
            />
          </div>
          <div className='col-md-12'>
            <Select
              className="custom-select-class"
              options={sources && sources}
              name="source"
              placeholder="Where Did You Hear About Us *"
              value={
                sources &&
                sources.filter(
                  (option) => option?.value == values?.source
                )
              }
              onChange={(selectedOption) => {
                if (selectedOption) {
                  handleChange("source", selectedOption?.value);
                }
                else {
                  handleChange("source", null);
                }
              }}
              error={errors && errors?.source}
              menuPlacement="top"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
}