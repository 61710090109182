export const Countries = {
    Others: 1,
    UAE: 2,
  };

  export const DocTypes = {
    Passport: 1,
    EmiratesID: 2,
  };

  export const RoleType = {
    Admin: 1,
    OfficeAdmin: 2,
    Customer: 3
  };