import moment from 'moment'
import React from 'react'
import { Link} from 'react-router-dom'

const Footer = () => {
    return (<div className="footer non-public">
        <div className="footer-top">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-5 col-lg-5 col-md-7 col-sm-9">
                        <div className="part-about">
                            <p>Buy Grand Raffle Tickets Online. <br />Anytime, Anyplace, Anywhere On A Phone, Tablet or Computer.</p>
                            <ul className="importants-links">
                                <li className="single-link">
                                    <Link to='/policyandterms'>Policy & Terms</Link>
                                </li>
                                <li className="single-link">
                                    <Link to='/disclaimer'>Disclaimer </Link>
                                </li>
                                <li className="single-link">
                                    <Link to='/fraudulentawareness'>Fraudulent awareness</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="footer-bottom">
            <div className="container">
                <div className="footer-bottom-content">
                    <p className="copyright-text">Copyright © {moment().format('YYYY')}. All Right Reserved By Grand Raffle</p>
                    {/*  */}
                    <div className="footer-menu">
                        <ul>
                            <li>
                                <Link className='single-menu' to='/contest'>Contest</Link>
                            </li>
                            <li>
                                <Link className='single-menu' to='/winnersclub'>Winners Club </Link>
                            </li>
                            <li>
                                <Link className='single-menu' to='/newsroom'>Newsroom</Link>
                            </li>
                            <li>
                                <Link className='single-menu' to='#'>Store Locator</Link>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}

export default Footer