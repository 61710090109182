import React from 'react';
import images from '../../assets/images';

const NewsRoom = () => {

  return (
    <>
      <div className='home-section newsroom-section'>
        <section className='hero-bg'>
          <section className='hero-section '>
            <div className="container">
              <div className="row ">
                <div className="col-md-4 mt-5">
                  <div className="new-img">
                    <img src={images.newscontent} className='img-fluid' />
                  </div>

                </div>
                <div className="col-md-8 mt-5">
                  <div className="content newsroom-club">
                    <h2>Grand Raffle Official Launch: Win Big Starting April 20!</h2>
                    <h5>Join Us for the Grand Opening of Grand Raffle – Your Ticket to Dream Big!</h5>
                    <p><strong>Fujairah –</strong> Excitement is brewing as Grand Raffle announces its official launch event happening on April 20th! We're inviting dreamers from all corners to celebrate the unveiling of the ultimate raffle experience. Whether you're in it for the thrill, the dreams, or the chance to win big, Grand    Raffle is your new go-to destination.
                    </p>

                    <p>
                      What's on Offer?
                      Grand Raffle isn't just another lottery platform; it's a gateway to your biggest dreams. From luxury cars and dream vacations to exclusive gadgets and hefty cash prizes, our raffles have something for everyone. Our platform guarantees transparency, fairness, and high-octane excitement with every draw.
                    </p>
                    <span>11 Apr 2024, Fujairah, UAE</span>


                  </div>
                </div>

              </div>

            </div>
          </section>
        </section>
      </div>
    </>
  );
}

export default NewsRoom;
