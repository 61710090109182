import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import images from '../../assets/images'
import { Icon } from '@iconify/react'
import { ResetPassword } from '../../store/auth/action';
import { connect } from 'react-redux';
import validate from "validate.js";
import { schema } from './schema';
import { isEmpty } from 'lodash';
import TextInput from '../../components/TextInput';
import ErrorModal from '../../components/ErrorModal';
import SuccessModal from '../../components/SuccessModal';
import Loader from '../../components/Loader';

const ChangePassword = ({ResetPassword}) => { 
  const navigate = useNavigate();
  const params = useParams() 
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState(); 
  const initialState = {
    values: {
      password: "",
      confirm: ""

    },
    errors: {
      password: null,
      confirm: null,

    },
  };
  const [values, setValues] = useState(initialState.values);
  const [errors, setErrors] = useState(initialState.errors);
  const [loading, setLoading] = useState(false);

  const handleChange = (field, value) => {
    setValues((prev) => ({ ...prev, [field]: value }));
  };

  const validateForm = () => {
    const errors = validate(values, schema);
    setErrors(errors);
    if (errors) {
      const message =
        !isEmpty(values.password) || !isEmpty(values.confirmPassword)
          ? errors[Object.keys(errors)[0]][0]
          : "Please complete all required fields with valid entries before proceeding.";
      setMessage(message);
      window.$("#errorModal").modal('show');
      return false;
    }
    return true;
  };

  const handleReset = async () => { 
    let valid = validateForm();
    if (valid) { 
      setLoading(true)
      const res = await ResetPassword(params?.token, values.password);
      if (res) {
        setLoading(false)
        setMessage("Your password has been successfully reset. You can now log in using your new password.");
        window.$("#successModal").modal('show');
      } else {
        setLoading(false)
      }
    }
  };

  const handleClick = (e) => {
    navigate('/')
  }

  const handleOnClose = () => {
    window.$("#errorModal").modal('hide'); 
  }

  const handleSuccess = () =>{
    window.$("#successModal").modal('hide');
    navigate('/login');
  }

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (<section className='login-section'>
    {loading && <Loader/>}
    <SuccessModal message={message} btn1Text="OK" btn1Click={handleSuccess} />
    <ErrorModal message={message} btn1Text="OK" btn1Click={handleOnClose} />
    <div className="container">
      <div className="row">
        <div className="col-md-3"></div>
        <div className="col-md-6">
          <div className="login-sectioncontent">
            <div className="log-header ">
              <div className="text-center">
                <img src={images.footerlogo} className='img-fluid wit-2 ' onClick={handleClick} alt="Footer Logo"/>
              </div>
              <h3>Change Your Password</h3>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group psd-tp">
                    <TextInput
                      type={showPassword ? 'text' : 'password'}
                      value={values.password}
                      onChange={(e) => handleChange("password", e.target.value)}
                      label='New Password'
                      name='password'
                      id='password'
                      className="pad-right"
                      error={errors?.password}
                      endAdornment={() => <span className='eye-icon cursor-pointer' onClick={handleTogglePassword}>
                        {showPassword ? <Icon icon="eva:eye-outline" /> : <Icon icon="tabler:eye-off" />}
                      </span>}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group psd-tp">
                    <TextInput
                      type={showPassword ? 'text' : 'password'}
                      value={values.confirm}
                      onChange={(e) => handleChange("confirm", e.target.value)}
                      label='Confirm password'
                      name='confirmPassword'
                      id='password'
                      className="pad-right"
                      error={errors?.confirm}
                      endAdornment={() => <span className='eye-icon cursor-pointer' onClick={handleTogglePassword}>
                        {showPassword ? <Icon icon="eva:eye-outline" /> : <Icon icon="tabler:eye-off" />}
                      </span>}
                    />
                  </div>
                </div>
                <div className="sub-btn col-12 text-center">
                  <button className="gradient-btn auto-width" onClick={handleReset} aria-label="change password">Change Password</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

const mapDispatchToProps = { ResetPassword };

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);