import client from "../../services/api-client";
import { errorHandler } from "../error/action";

export const GetAllTickets = () => async (dispatch) => {
    try{
        const res = await client.service('ticket-list').find();
        if(res){
            return res;
        }
    } catch (err) {
        errorHandler(err);
        return false
    }
}

export const SelectedTicket = (tickets) => async (dispatch) => {
    dispatch({
        type: "SELECTED_TICKETS",
        data: tickets
    });
    return true;

}

export const AddPersonTicket = (tickets) => async (dispatch) => {
    try{
        const res = await client.service('personticket').create(tickets);
        if(res){
            dispatch({
                type: "SELECTED_TICKETS",
                data: tickets
            }); 
            return res
        }
    } catch (err){
        errorHandler(err);
        return false
    }
}

export const GetPersonTicket = (personId) => async (dispatch) => {
    let data = {
        personId: personId
    }
    try {
        const res = await client.service('personticket').find({ query: data })
        if (res) { 
            dispatch({
                type: "SELECTED_TICKETS",
                data: res
            }); 
            return res
        }
    } catch (err) {
        errorHandler(err);
        return false
    }
}

export const DeletePersonTicket = (id) => async (dispatch) => {
    try{
        const res = await client.service('personticket').remove(id);
        if(res){
            return true;
        }
    } catch (err){
        errorHandler(err);
        return false
    }
}

export const ViewTickets = (ref) => async (dispatch) => {
    try{
        const res = await client.service('order').find({ query: { transactionReferenceId: ref } });
        if (res) {
            return res;
        }
    } catch (err){
        errorHandler(err);
        return false
    }
}

export const RemoveSessionTicket = (personId) => async (dispatch) => {
    try{
        const res = await client.service('ticket-list').remove(null, { query: { personId: personId } });
        if(res){ 
            return true
        }
    } catch (err){
        errorHandler(err);
        return false
    }
}