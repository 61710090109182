// TextInput.js
import React from 'react';

const TextInput = ({ type, placeholder, value, onChange, endAdornment, wrapperClass="", onBlur, label, error, className, disabled, onfocus, maxLength, required, id }) => {
  
  const wrapperClassName = `form-group ${wrapperClass} ${error ? 'redborder' : ''}`;
  const inputClassName = `form-control ${className ? className : ''} ${value ? 'has-value':''}`;
  
  return (
    <div className={wrapperClassName.trim()}>
      <input
        id={id}
        autoComplete="nope"
        type={type}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled} 
        placeholder={placeholder ? placeholder : ""}
        onFocus={onfocus}
        maxLength={maxLength}
        className={inputClassName.trim()}
        error={error}
      />
      <label className='placeholderlabel'>{label} {required && '*'}</label>
      {endAdornment && endAdornment()}
    </div>
  );
};

export default TextInput;
