import Header from './Header';
import Footer from './Footer';

const PublicLayout = ({ children, hideTestimonialAboutus=false }) => {
   return (<>
      <Header />
      {children} 
      <Footer hideTestimonialAboutus={hideTestimonialAboutus}/>
   </>
   )
}

export default PublicLayout