import client from "../../services/api-client";
import { errorHandler } from "../error/action";


export const RegisterUser = (data) => async (dispatch) => {
    try {
        const res = await client.service("users").create(data);
        if (res) { 
            return {
                res: true,
                profile: res,
            };
        }
    } catch (error) { 
        errorHandler(error);
        return false;
    }
};

export const Login = (credentails) => async (dispatch) => {
    try {
        const res = await client.authenticate({
            strategy: 'local',
            ...credentails,
        });
        
        if (res) {  
            if(res.user.isVerified == 0){
                const error = {
                    code:400,
                    message:`User is not verified.`
                }
                localStorage.removeItem("feathers-jwt");
                localStorage.removeItem("persist:root");
                await client.logout();
                errorHandler(error) 
                return false 
            } else {
                dispatch({
                    type: "LOGIN",
                    data: res.user
                })
                return res.user;
            }  
        }
    } catch (error) { 
        errorHandler(error)
        return false; 
    }
} 

export const UpdateProfile = (profile) => async (dispatch) => {
    dispatch({
        type: "LOGIN",
        data: profile
    });
}

export const Logout = () => async (dispatch) => {
    try {
        localStorage.removeItem("feathers-jwt");
        localStorage.removeItem("persist:root");
        await client.logout();
        dispatch({
            type: "LOGOUT",
        });
        return true;
    } catch (error) { 
        errorHandler(error);
        return false;
    }
};

export const GetPersonDetails = (id) => async (dispatch) => { 
    try {
        const res = await client.service("person").get(id);  
        if (res) { 
            return res;
        }
    } catch (err) { 
        errorHandler(err);
        return false;
    }
}

export const IsAlive = async () => {
    // Try to authenticate using an existing token
    try {
        const token = await localStorage.getItem("feathers-jwt");
        if (token) {
            const res = await client.reAuthenticate();
            if (res.accessToken) {
                return res.user;
            }
        }
        return false;
    } catch (error) {
        errorHandler(error);
        return false;
    }
};

export const CompleteProfile = (data) => async (dispatch) => { 
    try {
        if (data?.personId > 0) {
            const res = await client.service("person").patch(data?.personId, data);
            if (res) { 
                return true;
            }
            return false;
        }
    } catch (error) { 
        errorHandler(error);
        return false;
    }
};

export const ForgotPassword = (email) => async (dispatch) => {
    try {
        const res = await client.service("auth-management").create({
            action: "sendResetPwd",
            value: { email: email },
            notifierOptions: {}
        });

        if (res) {
            return true;
        }
        return false;
    } catch (error) {
        errorHandler(error);
        return false;
    }
}

export const ResetPassword = (token, password) => async (dispatch) => { 
    try {
        const res = await client.service('auth-management').create({
            action: 'resetPwdLong',
            value: {
              token, // compares to resetToken
              password, // new password
            },
            notifierOptions: {}, // optional - an object passed to notifier function
          })
        if (res) {
            return true;
        }
        return false;
    } catch (error) {
        errorHandler(error);
        return false;
    }
};

export const ChangePassword = (password, uid) => async (dispatch) => {
    try {
      const res = await client.service("users").patch(uid, { password });
      if (res) {
        return true;
      }
      return false;
    } catch (error) {
      errorHandler(error);
      return false;
    }
  };

export const ResendVerification = (email) => async (dispatch) => {
    try {
        const res = await client.service('auth-management').create({
            action: 'resendVerifySignup',
            value: {email:email}, // {email}, {token: verifyToken}
            notifierOptions: {}, // optional - an object passed to notifier function
          })

        if (res) {
            return true;
        }
        return false;
    } catch (error) {
        errorHandler(error);
        return false;
    }
}

export const VerifySignupLong = (verifyToken) => async (dispatch) => {
    try { 
        const res = await client.service('auth-management').create({
            action: 'verifySignupLong',
            value: verifyToken, // compares to .verifyToken
           })
        if (res) {
            return true;
        }
        return false;
    } catch (error) {
        errorHandler(error);
        return false;
    }
}

export const GetPerson = (personId) => async(dispatch) => {
    let data = {
        personId: personId
    }
    try{
        const res = await client.service('person').find({query: data});
        if(res){
            return res;
        }
    } catch (error){
        errorHandler(error);
        return false
    }
}