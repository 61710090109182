import client from "../../services/api-client";
import { errorHandler } from "../error/action";

export const CreatePayment = () => async (dispatch) => {
    try {
        const res = await client.service('payment').create({});
        if (res) {
            return res;
        }
    } catch (err) {
        errorHandler(err)
        return false
    }
}

export const PaymentHistory = ($limit=10, $skip=0) => async (dispatch) => {
    try {
        const res = await client.service(`/ordersummary`).find({ query: { $limit: $limit, $skip: $skip * $limit, } });
        if (res) {
            return res;
        }
    } catch (err) {
        errorHandler(err)
        return false
    }
}

export const PaymentSuccess = (ref) => async (dispatch) => {
    try {
        const res = await client.service(`/payment-accepted`).create({},{ query: { ref: ref } });
        if (res) {
            return res;
        }
    } catch (err) {
        errorHandler(err)
        return false
    }
}

export const PaymentFailure = (ref) => async (dispatch) => {
    try {
        const res = await client.service(`/payment-failure`).create({},{ query: { ref: ref } });
        if (res) {
            return res;
        }
    } catch (err) {
        errorHandler(err)
        return false
    }
}

export const PaymentStatus = (ref) => async (dispatch) => {
    try {
        const res = await client.service(`/payment-status`).find({ query: { ref: ref } });
        if (res) {
            return res;
        }
    } catch (err) {
        errorHandler(err)
        return false
    }
}