export const schema={
    email:{
         presence:{allowEmpty:false,message:"^Email is required"},
         email:{required:true,message:"^Enter a valid email address."},
       
    },
    password:{
        presence:{allowEmpty:false,message:"is required"},
       
    },
    recaptcha: {
        presence: { allowEmpty: false, message: "^Please complete the CAPTCHA verification to proceed."},
    }
};