import React, { useEffect } from "react";
import { removeError } from "../store/error/action";
import { connect } from "react-redux";
import { createBrowserHistory } from "history";

const RouteContainer = (props) => {
  const { error } = props; 
  const browserHistory = createBrowserHistory();

  useEffect(() => {
    const unlisten = browserHistory.listen((location, action) => {
      if (location && location.pathname !== "/login") {
        // sigin page message not cleared on route change in log_in to avoid
        // removing error msg when redirected, after authfailure, to sigin page from with in the app
        // removeError();
      }
    });
    return () => {
      unlisten();
    };
  }, []);

  useEffect(() => {
    if (
      error &&
      error.message &&
      error.message === "Your session is expired. Please re-login"
    ) {
      browserHistory.push("/");
    }
  }, [error]);

  return <div>{props.children}</div>;
};

const mapStateToProps = (state) => ({
  error: state.error,
});

const mapDispatchToProps = {
  removeError,
};
export default connect(mapStateToProps, mapDispatchToProps)(RouteContainer);