import React from 'react'

const FraudAwareness = () => {
    return (
        <section className='contentsection'>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="cm-content">
                            <h1>FRAUD AWARENESS</h1>
                            <p>Fraudulent activities have become increasingly sophisticated, targeting unsuspecting individuals through various channels. Understanding how fraudsters operate and staying vigilant can help protect you from falling victim to scams. Here's a comprehensive guide to recognizing and avoiding scams, brought to you by Fujairah Airport Duty-free Grand Raffle:</p>
                            <ul className='fd-li'>
                                <li>
                                    <h4>Learn how fraudsters operate:</h4>
                                    <ul>
                                        <li>Fraudsters use various communication methods to target victims, such as phone calls, SMS, WhatsApp, email, and social media.</li>
                                        <li>They claim you have won large cash prizes and request personal and bank account information to 'receive' the prize money.</li>
                                        <li>Scammers exploit this information to access victims' accounts and steal their funds.</li>
                                        <li>Perpetrators advise victims to maintain confidentiality about the prize, preventing them from seeking advice.</li>
                                    </ul>
                                </li>
                                <li>
                                    <h4>Prevention tips:</h4>
                                    <ul>
                                        <li>We only use official phone numbers and emails to contact winners. You can verify the details on our website: <a href="https://www.grandraffle.ae">https://www.grandraffle.ae</a>. If you're a winner, your name and ticket number will be on our official winners’ page and social media page. You can also call our customer care at (+971) 600 3334726 to verify or write to us on <a href="mailto:contact@grandraffle.ae">contact@grandraffle.ae</a>.</li>
                                        <li>Avoid downloading any application that claims to be associated with Grand Raffle.</li>
                                        <li>No fees are required to claim prizes; we will not ask winners to pay.</li>
                                        <li>Never share personal details with anyone.</li>
                                        <li>Confirm the credentials of the contact person.</li>
                                        <li>Avoid sending money to unverified sources.</li>
                                        <li>Do not click on unfamiliar links to prevent information theft.</li>
                                        <li>Be cautious of messages with errors, misspellings, or poor grammar.</li>
                                        <li>Beware of confidentiality demands.</li>
                                        <li>Avoid opening emails from unrecognized addresses.</li>
                                        <li>Carefully review URLs for spelling errors before clicking.</li>
                                        <li>Refrain from sharing personal info or sending money.</li>
                                        <li>Don't engage with suspected scammers to avoid further targeting.</li>
                                        <li>Report suspicious activities to authorities immediately.</li>
                                    </ul>
                                </li>
                            </ul>
                            <p>If you come across any fraudulent activity, please reach out to us at (+971) 600 3334726 or email us at <a href="mailto:contact@grandraffle.ae">contact@grandraffle.ae</a> for help. Your security and trust matter greatly to us. Please be careful and utmost vigilant at all times!</p>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FraudAwareness