import React from 'react'
import images from '../../../assets/images'
import moment from 'moment'
import { Link} from 'react-router-dom'

const Footer = ({hideTestimonialAboutus}) => {
    return (
    <section className={`footer-section ${hideTestimonialAboutus ? 'hidefooter' : ''}`}>
         {!hideTestimonialAboutus && (
                <div className="testimonal-section mt-5">
                    <div className="container">
                        <div className="row">
                            <div className="text-center">
                                <h4>Testimonials</h4>
                                <h1>CLIENT’S VALUABLE FEEDBACK</h1>
                                <div className="testimonial-content">
                                    <img className="image" src={images.testimonials} alt="Testimonials" />
                                    <div className="text-overlay">
                                        <p><span>‘</span>Hey, Are you interested to be the first one to share your valuable feedback through our website; write your experiences to us through <b>contact@grandraffle.ae</b><span>’</span></p>
                                    </div>
                                </div>
                                <div className="hexagon">
                                    <div className="content">
                                        <img src={images.questionmark} className='img-fluid' alt="questionmark" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        <div className={"footer home-screen-transparent"}>
            <img src={images.footerimg} className='doodlefooter' alt="Footer doodle"  style={{ display: hideTestimonialAboutus ? 'none' : 'block' }}/>
            {!hideTestimonialAboutus && (
            <div className="container">
                <div className="row">
                    <div className={"footer-area home-screen"}>
                        <div class="row section-title"><h3 class="sub-title">About us</h3>
                            <p>Fujariah international airport is situated in the picturesque emirate  of Fujariah. The airport has a strong commitment towards passenger safety , well being and felicitations just like any international airport have and is on an expansion process.</p>

                            <p>The airport runs ‘Fujariah Airport Duty Free Grand Raffle’ which is a gateway to win big and to make dreams come true through active participation of public.Our tag line ,’ Dream Big Win Big’ directly gives information as to what is in store for the public.</p>
                        </div>
                    </div>
                </div>
            </div>
             )}
            <div className="footer-top">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-5 col-lg-5 col-md-7 col-sm-9">
                            <div className="part-about">
                                <div className="footer-logo">
                                <Link to="/">
                                        <img src={images.footerlogo}  className="img-fluid"  alt="footer logo"/>
                                    </Link>
                                </div>
                                <p>Buy Grand Raffle Tickets Online. <br />Anytime, Anyplace, Anywhere On A Phone, Tablet or Computer</p>
                                <ul className="importants-links">
                                    <li className="single-link">
                                        <Link to='/policyandterms'>Policy & Terms</Link>
                                    </li>
                                    <li className="single-link">
                                    <Link to='/disclaimer'>Disclaimer </Link>
                                    </li>
                                    <li className="single-link">
                                    <Link to='/fraudulentawareness'>Fraudulent awareness</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <div className="footer-bottom-content">
                        <p className="copyright-text">Copyright © {moment().format('YYYY')}. All Right Reserved By Grand Raffle</p>
                        {/*  */}
                        <div className="footer-menu">
                            <ul>
                                <li>
                                    <Link className='single-menu' to='/contest'>Contest</Link>
                                </li>
                                <li>
                                    <Link className='single-menu' to='/winnersclub'>Winners Club </Link>
                                </li>
                                <li>
                                    <Link className='single-menu' to='/newsroom'>Newsroom</Link>
                                </li>
                                <li>
                                    <Link className='single-menu' to='#'>Store Locator</Link>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    


)
}

export default Footer