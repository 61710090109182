import React, { useState, useEffect } from 'react';
import images from '../../assets/images';
import { Icon } from '@iconify/react';
import { Link, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { GetNationality, GetStates } from '../../store/master/action';
import { GetPerson } from '../../store/auth/action';
import { getCountryAndStatesName, getDiscount, getSubTotal, grandTotal } from '../../util/helper';
import { DeletePersonTicket, GetPersonTicket } from '../../store/ticket/action';
import ConfirmationModal from '../../components/ConfirmationModal';
import { CreatePayment } from '../../store/payment/action'
import Loader from '../../components/Loader';


const OrderSummary = ({ CreatePayment, profile, GetPerson, GetNationality, GetStates, GetPersonTicket, DeletePersonTicket }) => {
    const navigate = useNavigate();
    const [items, setItems] = useState([]);
    const [personData, setPersonData] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [title, setTitle] = useState("");
    const [message, setMessage] = useState('');
    const [deleteItem, setDeleteItem] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (profile) {
            async function fetchData() {
                const res = await GetPersonTicket(profile?.person?.personId);
                if (res) {
                    setItems(res);
                    setLoading(false)
                }
            }
            fetchData();
        }
    }, [profile])

    useEffect(() => {
        if (profile) {
            async function fetchData() {
                const res = await GetPerson(profile?.person?.personId);
                if (res) {
                    setPersonData(res.data[0]);
                    const country = await GetNationality();
                    if (country) {
                        setCountryList(country);
                    }
                    const states = await GetStates(res?.data[0]?.countryId);
                    if (states) {
                        setStateList(states)
                    }
                }
            }
            fetchData();
        }
    }, [profile])


    const getUpdatedTickets = async () => {
        const res = await GetPersonTicket(profile?.person?.personId);
        if (res) {
            setItems(res);
        }
    }
    const deleteConfirmation = (item) => {
        setMessage(`Are you sure you want to delete this ticket #${item?.ticketNumber}? This action cannot be undone.`)
        setTitle("Warning")
        setDeleteItem(item);
        window.$("#confirm-modal").modal("show");
    }

    const removeItem = async () => {
        const res = await DeletePersonTicket(deleteItem?.personTicketId);
        if (res) {
            getUpdatedTickets();
            window.$("#confirm-modal").modal("hide");
        }
    }

    const handleCheckout = async () => {
        setLoading(true)
        const res = await CreatePayment()  
        window.location.href = res?._links?.payment?.href; 
        setLoading(false) 
    }



    return (
        <section className='order-section'>
            {loading && <Loader />}
            <ConfirmationModal title={title} message={message} btn1="Cancel" btn2="Delete" btn1Click={() => window.$("#confirm-modal").modal("hide")} btn2Click={removeItem} />
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="profile">
                            <h3>Order Summary</h3>
                            <p>For every 2 tickets, get 1 FREE! Double the deal with 4 tickets to receive 2 FREE! Maximize your chances without exceeding the AED 1,000 limit per order.</p>
                        </div>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-md-8">
                        <div className="summarycontent">
                            <table>
                                <tbody>
                                    <tr>
                                        <td className='wt-20'>
                                            <div className="or-img">
                                                <img src={images.ordersummary} className='img-fluid' alt="OrderSummary" />
                                            </div>
                                        </td>
                                        <td className='wt-35'>
                                            <div className="o-tickets">
                                                <h6>Grand Raffle Monthly Draw - Grand Prize AED 2.5 Million</h6>
                                                <div className='tic-list'>
                                                    {items && items?.map((item, index) => (
                                                        <button
                                                            key={item.ticketId}
                                                            style={{ backgroundColor: 'transparent' }}
                                                            className='tic-btn'
                                                        >
                                                            <img src={images.gr} alt="ticketnumber"/> <label>{item?.ticketNumber}</label>
                                                            <span onClick={() => deleteConfirmation(item)} className='o-close'><Icon icon="jam:close" />
                                                            </span>
                                                        </button>


                                                    ))}
                                                </div>
                                            </div>

                                        </td>
                                        <td className='wt-15'>
                                            <div className="o-right">
                                                <p>Each</p>
                                                <h6>AED 250.00</h6>
                                            </div>

                                        </td>
                                        <td className='wt-13'>
                                            <div className="text-center ft">
                                                <p>Qty</p>
                                                <h6>{items && items?.length}</h6>
                                            </div>
                                        </td>
                                        <td className='wt-24'>
                                            <div className="o-right">
                                                <p>Total</p>
                                                <h6>AED {getSubTotal(items)}.00</h6>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="checkout">
                            <div className="o-address">
                                <h6>Your Address <Link to="/update-profile">Change</Link></h6>
                                <p>(This address will printed on your e-Ticket)</p>
                                <div className="address-content">
                                    <p>{personData && personData?.firstName + " " + personData?.lastName}</p>
                                    <p>{personData && personData?.addressLine1}</p>
                                    <p>{personData?.addressLine2 !== null && personData?.addressLine2}</p>
                                    <p>{personData && countryList && stateList && getCountryAndStatesName(countryList, personData?.countryId, stateList, personData?.stateId)}</p>
                                    <p>+{personData && personData?.mobile}</p>
                                </div>
                            </div>
                            <div className="o-amount mt-4">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <p>Sub Total</p>
                                            </td>
                                            <td>
                                                <div className="t-right">
                                                    <p>AED {getSubTotal(items)}.00</p>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p>Discount</p>
                                            </td>
                                            <td>
                                                <div className="t-right">
                                                    <p>AED -{getDiscount(items)}.00</p>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h6>Grand Total</h6>
                                            </td>
                                            <td>
                                                <div className="t-right">
                                                    <h6>AED {grandTotal(items)}.00</h6>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="ch-btn mt-3">
                                    <button class="crt-btn" onClick={() => handleCheckout()}> <Icon icon="material-symbols:shield-locked-outline" />CheckOut</button>
                                </div>
                                <div className="ch-brbtn mt-3">
                                    <button class="crt-brbtn" onClick={() => navigate("/tickets")} aria-label="buymore">Buy More Tickets</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}

const mapDispatchToProps = { GetPerson, GetNationality, GetStates, GetPersonTicket, DeletePersonTicket, CreatePayment }
const mapStateToProps = (state) => ({
    persontickets: state?.ticket && state?.ticket?.selected,
    profile: state?.auth && state?.auth?.profile,

});

export default connect(mapStateToProps, mapDispatchToProps)(OrderSummary);
