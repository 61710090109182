import React, { useState } from 'react'
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import validate from "validate.js";
import { Icon } from '@iconify/react'
import { useNavigate } from 'react-router-dom'
import images from '../../assets/images'
import TextInput from '../TextInput'
import { ChangePassword } from '../../store/auth/action';
import { schema } from '../../containers/ChangePassword/schema';
import ErrorModal from '../ErrorModal';
import SuccessModal from '../SuccessModal';
import { Logout } from '../../store/auth/action'


const ChangePasswordModal = ({profile, ChangePassword, Logout}) => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState(); 
  const initialState = {
    values: {
      password: "",
      confirm: ""

    },
    errors: {
      password: null,
      confirm: null,

    },
  };
  const [values, setValues] = useState(initialState.values);
  const [errors, setErrors] = useState(initialState.errors);

  const handleChange = (field, value) => {
    setValues((prev) => ({ ...prev, [field]: value }));
  };

  const validateForm = () => {
    const errors = validate(values, schema);
    setErrors(errors);
    if (errors) {
      const message =
        !isEmpty(values.password) || !isEmpty(values.confirm)
          ? errors[Object.keys(errors)[0]][0]
          : "Please complete all required fields with valid entries before proceeding.";
      setMessage(message);
      window.$("#changepasswordmodal").modal('hide');
      window.$("#errorModal").modal('show');
      return false;
    }
    return true;
  };

  const handleReset = async () => { 
    let valid = validateForm();
    if (valid) { 
        const res = await ChangePassword(values?.confirm, profile?.userId);
        if (res) {
          setValues(initialState.values);
          setShowPassword(false);
          setMessage("Your password has been successfully saved");
          window.$("#changepasswordmodal").modal("hide");
          window.$("#successModal").modal('show');
        }
      } 
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleCancel = () => {
    window.$("#changepasswordmodal").modal("hide");
    setValues({password:"", confirm:""});
  }
  const handleClose = () => {
    window.$("#errorModal").modal("hide");
    window.$("#changepasswordmodal").modal("show")
  }

  const handleRedirect = () => {
    window.$("#successModal").modal('hide');
  }
  
  return (
    <>
    <ErrorModal message={message} btn1Text="OK" btn1Click={handleClose} />
    <SuccessModal message={message} btn1Text={"OK"} btn1Click={handleRedirect} />
    <div class="modal fade errorModal" data-bs-backdrop="static" id="changepasswordmodal" tabindex="-1" aria-labelledby="errorModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
        <div className="modalclose" onClick={handleCancel}>
            <Icon icon="ep:close-bold" aria-label="Close" />
          </div>
          <div class="modal-body">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="login-sectioncontent">
                    <div className="log-header ">
                      <div className="text-center">
                        <img src={images.footerlogo} className='img-fluid wit-2 ' />
                      </div>
                      <h6 className='text-center p-3'>Change Your Password</h6>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group psd-tp px-5 pb-3">
                            <TextInput
                              type={showPassword ? 'text' : 'password'}
                              value={values.password}
                              onChange={(e) => handleChange("password", e.target.value)}
                              label='New Password'
                              name='password'
                              id='password'
                              className="pad-right"
                              error={errors?.password}
                              endAdornment={() => <span className='eye-icon cursor-pointer' onClick={handleTogglePassword}
                            >
                              {showPassword ? <Icon icon="eva:eye-outline" /> : <Icon icon="tabler:eye-off" />}
                            </span>}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group psd-tp px-5 pb-3">
                            <TextInput
                              type={showPassword ? 'text' : 'password'}
                              value={values.confirm}
                              onChange={(e) => handleChange("confirm", e.target.value)}
                              label='Confirm password'
                              name='confirmPassword'
                              id='password'
                              className="pad-right"
                              error={errors?.confirm}
                              endAdornment={() => <span className='eye-icon cursor-pointer' >
                                {showPassword ? <Icon icon="eva:eye-outline" /> : <Icon icon="tabler:eye-off" />}
                              </span>}
                            />
                          </div>
                        </div>
                        <div className="sub-btn col-12 text-center pb-5 mt-2">
                          <button className="gradient-btn auto-width" onClick={handleReset} aria-label="changepassword">Change Password</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

const mapDispatchToProps = { ChangePassword, Logout};

const mapStateToProps = (state) => ({
  profile: state?.auth && state?.auth?.profile,
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordModal);