import React,{useState} from 'react'
import ReCAPTCHA from "react-google-recaptcha";
import { Link, useNavigate } from 'react-router-dom'
import { Icon } from '@iconify/react'
import images from '../../assets/images'
import { ForgotPassword } from '../../store/auth/action';
import { connect } from 'react-redux';
import validate from "validate.js";
import { schema } from './schema';
import {isEmpty} from 'lodash';
import TextInput from '../../components/TextInput';
import ErrorModal from '../../components/ErrorModal';
import Loader from '../../components/Loader';
import SuccessModal from '../../components/SuccessModal';


const Forgot = ({ForgotPassword}) => {  
  const navigate = useNavigate(); 
  const [message, setMessage] = useState();
  const initialState = {
    values: {
      email:"",
 
    },
    errors: {
      email:null,
 
    },
  };
  const [values, setValues] = useState(initialState.values);
  const [errors, setErrors] = useState(initialState.errors);
  const [loading, setLoading] = useState(false);

  const handleChange = (field, value) => {
    setValues((prev) => ({ ...prev, [field]: value }));
  };  
 
  const validateForm = () => {
    const errors = validate(values, schema);
    setErrors(errors);
    if (errors) {
      const message =   
        !isEmpty(values.email)
          ? errors[Object.keys(errors)[0]][0]
          : "Please enter the email associated with your account.";
      setMessage(message);
      window.$("#errorModal").modal('show');
      return false;
    }
    return true;
  };

  const handleReset = async () => { 
    let valid = validateForm();
    if (valid) {
        setLoading(true)
        const res = await ForgotPassword(values?.email);
        if(res) {
          setLoading(false)
          setMessage(`We've successfully sent a password reset link to your email. Please check your inbox (and spam folder, just in case!) and follow the instructions to reset your password.`)
          window.$('#successModal').modal('show');
        } else {
          setLoading(false)
        }
    }
  };

  const handleOk = () => {
    window.$('#successModal').modal('hide');
    navigate('/login');
  }

  const handleClick = () => {
    navigate('/')
  }

  const handleOnClose = () => { 
      window.$("#errorModal").modal('hide'); 
 }
  
  return ( <section className='login-section forget-section'>
    {loading && <Loader/>}
      <SuccessModal message={message} btn1Text="OK" btn1Click={handleOk} />
      <ErrorModal message={message} btn1Text={"OK"}  btn1Click={handleOnClose}/>
      <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-8 col-lg-6 col-xl-6 col-sm-12">
            <div className="login-sectioncontent">
              <div className="log-header ">
                <div className="text-center">
                <img src={images.footerlogo} className='img-fluid wit-2 ' onClick={handleClick} alt="Footer Logo"/>
                </div>
                <h3>Get Your Password</h3> 
                  <div className="row">
                    <div className="col-md-12">
                    <div className="form-group">
                    <TextInput
                          type='email'
                          value={values.email}
                          onChange={(e) => handleChange("email", e.target.value)} 
                          label='Email'
                          name="email"
                          id='email'
                          error={errors?.email}
                        />
                      </div>
                    </div>
                    <div className="sub-btn col-12 text-center">
                      <button className="gradient-btn auto-width" onClick={handleReset} aria-label="reset password">Reset Password</button>
                      </div>
                      <p className="joinnw">Remember your password? <Link to="/login" >Try Logging in</Link></p>
                  </div> 
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> 
  )
}

const mapDispatchToProps = { ForgotPassword };

const mapStateToProps = (state) => ({});
 
export default connect(mapStateToProps, mapDispatchToProps)(Forgot);