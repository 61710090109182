import React from 'react' 
import images from '../../assets/images'
import CountdownTimer from '../../components/CountdownTimer';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux';
import { RoleType } from '../../util/enum';


const Home = ({profile}) => {
  const navigate = useNavigate();

  const handleBuy = () => {
    if (profile && profile?.role.roleId == RoleType.OfficeAdmin) {
      navigate('/registration');
    }
    if (profile && profile?.role.roleId == RoleType.Customer) {
      if (profile && profile?.person?.profileStatus == 1) {
        navigate('/tickets');
      } else if (profile && profile?.person && profile?.person?.profileStatus == null) {
        navigate('/profile')
      }
    }
    if (!profile) {
      navigate('/register');
    }
  };

  return (
    <>
      <div className='home-section'>
        <section className='hero-bg'>
          <section className='hero-section'>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12 text-center mt-5">
                  <div className="content">
                    <img src={images.heroimg} className='img-fluid' alt='Grand Raffle Hero Section' loading="lazy"/>
                    <h4 className='hr-content fadeIn'>Claim Your Chance To Win AED 2.5 MILLION!</h4>
                    <button className='tick-btn' onClick={handleBuy} aria-label="buy tickets">BUY TICKETS</button>
                  </div>

                </div>
              </div>

            </div>
            <img src={images.herodoodleleft} className='herodoodleleft' alt='Grand Raffle Left Doodle'/>
            <img src={images.herodoodleright} className='herodoodleright'  alt='Grand Raffle Right Doodle'/>


          </section>
        </section>

        <section className="count-down mt-150">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <CountdownTimer />
              </div>
            </div>
          </div>
        </section>

        <section className='working-process'>
          <div className="container">
            <div className="row section-title">

              <h3 className="sub-title">How it works</h3>
              <div className="col-xl-4 col-lg-4 col-sm-4">
                <div className="icon-dt">
                  <img src={images.icon1} alt='Step 1'/>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-sm-4">
                <div className="icon-dt">
                  <img src={images.icon2} alt='Step 2'/>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-sm-4">
                <div className="icon-dt">
                  <img src={images.icon3} alt='Step 3'/>
                </div>

              </div>
            </div>
            <div className="row">
            <div className="col-xl-4 col-lg-4 col-sm-4">
                <div className="single-process">
                  <div className="part-text">
                    <span className="step-number">1.</span>
                    <span className="step-title">Complete your profile</span>
                    <p>Sign up and complete your profile with a few quick details.</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-sm-4">
                <div className="single-process">
                  <div className="part-text">
                    <span className="step-number">2.</span>
                    <span className="step-title">Pick your numbers</span>
                    <p>Since it’s all by luck, enjoy picking your numbers before checkout.</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-sm-4">
                <div className="single-process pp-2">
                  <div className="part-text">
                    <span className="step-number three">3.</span>
                    <span className="step-title">Check your numbers</span>
                    <p>If you are a winner, claim your prize before it expires in 2 months from the draw date.</p>
                  </div>
                </div>
              </div>

            </div>
          </div>


        </section>

        <section className='latest-winner'>
          <img src={images.winerdoodleleft} className='winnerdoodleleft' alt='Latest Winner Left Doodle'/>
          <img src={images.winerdoodleright} className='winnerdoodleright' alt='Latest Winner Right Doodle'/>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1 className='lst-drwhead mb-5'><img src={images.latestwinnertext} className='latest-img' alt='Latest Winner Text'/></h1>
              </div>
              <div className="col-md-5">
                <div className="winner-image text-right mt-5">
                  <img src={images.winneravatar} className='img-fluid' alt='Latest Winner Avatar' />
                </div>

              </div>
              <div className="col-md-7">
                <div className="winner-details text-center">
                  <p>Draw #333</p>
                  <h2>AED 2,500,000</h2>
                  <h4>Steve Harvey</h4>
                  <h4>Ticket No 734538</h4>
                </div>
              </div>
            </div>
          </div>
          <img src={images.winnerbottom} alt="Winner Bottom" className='winner-bottom' />
        </section>



      </div>
      <section className='livedraw'>
        <div className="container mb-5">
          <div className="row">
            <div className="col-md-6 mt-5 ">
              <div className='border-b'>
                <img src={images.livedraw} className='img-fluid' alt='Live Draw'/>

              </div>
            </div>
            <div className="col-md-6 mt-5">
              <div className="livecontent">
                <h2>Watch Our Grand Event</h2>
                <p>The draw will be on the 15th of every month and it will be physically taken inside the premises of the Fujariah International Airport. The draw will be picked by prominent personalities, anchors and other guest faces. The airport management including senior
                  officials will witness the draw. The results of all the winning numbers will be immediately announced after the draw which will be uploaded on all handles of ‘Fujairah Airport duty free Grand Raffle’</p>
                <div className="d-flex justify-content-center">
                  <div className="social-icon text-center">
                    <Icon icon="bi:twitter-x" className='twit-x' />
                    <Icon icon="entypo-social:youtube-with-circle" />
                    <Icon icon="entypo-social:instagram-with-circle" />
                    <Icon icon="entypo-social:facebook-with-circle" />
                  </div>
                </div>
                <div className="text-center">
                  <button className=' grn-gradient' aria-label="live draw">LIVE DRAWS <Icon icon="iconamoon:arrow-right-2-bold" /></button>

                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="container mt-12">
          <div className="row">
            <div className="col-md-12">
              <div className="ques-head text-center">
                <h3>You Have Questions </h3>
                <h1>WE HAVE ANSWERS</h1>
              </div>

            </div>
            <div className="col-md-8 col-lg-7 col-sm-12">
              <div className="faq-content mt-5">
                <h5 className='pd-20'>Do not hesitate to send us an email if you can't find what you're looking for.</h5>
                <div id="accordion">
                  <div className="card">
                    <div className="card-header" >
                      <h5 className="mb-0">
                        <button className="btn btn-link" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" aria-label="faqone">
                          How many Grand Raffle draws are available?
                        </button>
                      </h5>
                    </div>

                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-bs-parent="#accordion">
                      <div className="card-body">Every month draw will be available for all the sold out slots for the preceding months and each slot will have limited tickets.</div>
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-header" >
                      <h5 className="mb-0">
                        <button className="btn btn-link" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo" aria-label="faqtwo" >
                          Where can I subscribe to your newsletter?
                        </button>
                      </h5>
                    </div>

                    <div id="collapseTwo" className="collapse " aria-labelledby="headingTwo" data-bs-parent="#accordion">
                      <div className="card-body">We often send out our newsletter with news and great offers. </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" >
                      <h5 className="mb-0">
                        <button className="btn btn-link" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree" aria-label="faqthree">
                          Where can I edit my billing and shipping address?
                        </button>
                      </h5>
                    </div>

                    <div id="collapseThree" className="collapse " aria-labelledby="headingThree" data-bs-parent="#accordion">
                      <div className="card-body">If you created a new account after or while ordering you can edit both addresses (for billing and shipping) in your customer account.</div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" >
                      <h5 className="mb-0">
                        <button className="btn btn-link" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour" aria-label="faqfour">
                          Where can I find your disclaimer and privacy policy?
                        </button>
                      </h5>
                    </div>

                    <div id="collapseFour" className="collapse " aria-labelledby="headingFour" data-bs-parent="#accordion">
                      <div className="card-body">All data will be treated as strictly confidential and will not be disclosed to third parties. Take a look on our disclaimer and Privacy Policy.</div>
                    </div>
                  </div>
                  <div className="card ">
                    <div className="card-header" >
                      <h5 className="mb-0">
                        <button className="btn btn-link" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive" aria-label="faqfive">
                          Are you on Twitter, Facebook and other social media platforms?
                        </button>
                      </h5>
                    </div>

                    <div id="collapseFive" className="collapse " aria-labelledby="headingFive" data-bs-parent="#accordion">
                      <div className="card-body">Yes, you can find us on the following social media platforms.</div>
                    </div>
                  </div>
                </div>
                {/* <button className=' faq-btn mt-5' > FREQUENTLY ASKED QUESTIONS ?  </button> */}
              </div>
            </div>
            <div className="col-md-4 col-lg-5 col-sm-12">
              <div className="faq-img">
                <img src={images.faq} className='img-fluid' alt='Frequently Asked Questions'/>
              </div>
            </div>
          </div>
        </div>
      </section> 
    </>
  )
}

const mapStateToProps = (state) => ({
  profile: state?.auth && state?.auth?.profile,
});
const mapDispatchToProps = { };
export default connect(mapStateToProps, mapDispatchToProps)(Home);