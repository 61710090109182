import { Countries, DocTypes } from "../../util/enum";
const msg = "^Please complete all fields marked with an asterisk (*) before submitting.";

export default {
  email: {
    presence: { allowEmpty: false, message: msg },
    email: { required: true, message: "^Enter a valid email address." },
  },
  salutationId: {
    presence: { allowEmpty: false, message: msg },
  },
  firstName: {
    presence: { allowEmpty: false, message: msg },
  },
  lastName: {
    presence: { allowEmpty: false, message: msg },
  },
  dateOfbirth: {
    presence: { allowEmpty: false, message: msg },
  },
  nationalityId: {
    presence: { allowEmpty: false, message: msg },
  },
  documentTypeId: {
    presence: { allowEmpty: false, message: msg },
  },
  documentExpirydate: {
    presence: { allowEmpty: false, message: msg },
  },
  firstTimebuyer: {
    presence: { allowEmpty: false, message: msg },
  },
  addressLine1: {
    presence: { allowEmpty: false, message: msg },
  },
  countryId: {
    presence: { allowEmpty: false, message: msg },
  },
  stateId: {
    presence: { allowEmpty: false, message: msg },
  },
  city: (value, attributes) => {
    const countryValue = attributes.countryId;
    if (countryValue == Countries.UAE) {
      return null;
    } else {
      return {
        presence: {
          allowEmpty: false,
          message: msg,
        },
      };
    }
  },
  source: {
    presence: { allowEmpty: false, message: msg },
  },
  passportNumber: (value, attributes) => {
    const documentTypeValue = attributes.documentTypeId;
    if (documentTypeValue === DocTypes.Passport) {
      return {
        presence: { allowEmpty: false, message: msg }
      }
    } else {
      return { presence: false }
    }
  },
  emiratesId: (value, attributes) => {
    const documentTypeValue = attributes.documentTypeId;
    if (documentTypeValue === DocTypes.EmiratesID) {
      return {
        format: { pattern: /^784-\d{7}-\d{4}-\d{1}$/, message: '^The Emirates ID you entered is invalid. Please enter a valid 15-digit Emirates ID number.' },
      }
    } else {
      return { presence: false }
    }
  },
  // homePhone: {
  //   presence: { allowEmpty: false, message: msg },
  //   length: {
  //     minimum: 11,
  //     message: "^Please enter a valid phone number",
  //   },
  // },
  mobile: {
    presence: { allowEmpty: false, message: msg },
    length: {
      minimum: 11,
      message: "^Please enter a valid phone number",
    },
  },
  isChecked: {
    presence: { allowEmpty: false, message: "^Please accept the terms & conditions." },
  }
};
