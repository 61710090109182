export const TicketReducer = (state = {}, action) => {
    switch (action.type) { 
        case "SELECTED_TICKETS":
            return {
                ...state,
                selected: action.data,
            };
        default:
            return state;
    }
};