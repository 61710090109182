import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { removeError } from "../../store/error/action";
import { Icon } from '@iconify/react';
import { ResendVerification } from "../../store/auth/action";
import SuccessModal from '../SuccessModal'
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../Loader";

const ErrorBar = ({ removeError, error, ResendVerification }) => {
  const navigate = useNavigate();
  const location = useLocation()
  const [message, setMessage] = useState();
  const [title, setTitle] = useState("Error");
  const [btn, setBtn] = useState(null);
  const [successMessage, setSuccessMessage] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (error && error.message) {
      setMessage(error.message);
      setBtn(null)
      setTitle("Error")
      if (error?.code !== 400 && error?.code !== 401) {
        setTimeout(() => {
          setMessage();
          removeError();
        }, 5000);
      } else if (error.message) {
        if (error.message === "email must be unique") {
          setMessage(`The email address you entered is already in use. Please try a different email address.`)
        }
        if (error.message === "Email ID or password is incorrect" || error.message === "Invalid login") {
          setMessage(`The email or password seems incorrect. Please try again or reset your password if you've forgotten it.`)
        }
        if (error.message === "User not found." && !location.pathname.includes('/activate')) {
          setMessage('Please enter registered email address.')
        }
        if (error.message === "User not found." && location.pathname.includes('/activate')) {
          setMessage(`This account is already activated. Please log in or reset your password if you've forgotten it.`)
        }
        if (error.message === "User is not verified.") {
          setTitle("User is not verified")
          setMessage(`Please verify your account by clicking on the verification link sent to your registered email address. If you haven't received the verification email, please check your spam folder. If you still can't find it, you can request a new verification token by clicking on the "Resend Verification Email" button below.`)
          setBtn('Resend Verification Email')
        }
        if (error.message === "Password reset token has expired.") {
          setMessage(`Your password reset link has expired.`)
        }
        window.$("#serverErrorModal").modal('show');
      }
    }
  }, [error]);

  const handleCancel = () => {
    window.$("#serverErrorModal").modal('hide');
    if ((error.message === "User not found." && location.pathname.includes('/activate')) || error.message=="Your session has expired. Please log in again.") {
      navigate('/login')
    }
    setTimeout(() => {
      setMessage("")
      removeError();
    }, 200);
  }

  const handleAction = async () => {
    const email = window.$("#email").val()
    setLoading(true)
    const res = await ResendVerification(email);
    if (res) { 
      window.$("#serverErrorModal").modal('hide');
      window.$("#successModal").modal('show');
      setSuccessMessage(`A verification email has been sent to '${email}'. Please check your inbox (including spam) to activate your account.`)
      setLoading(false)
    } else{
      setLoading(false)
    }
  }

  const handleClose = async () => {
    window.$("#successModal").modal('hide');
    navigate('/login')
  }

  return (<>
    <div class="modal fade errorModal" id="serverErrorModal" data-bs-backdrop="static" tabindex="-1" aria-labelledby="errorModalLabel" aria-hidden="true">
    {loading && <Loader/>}
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          {btn && <div className="modalclose" onClick={handleCancel}>
            <Icon icon="ep:close-bold" aria-label="Close" />
          </div>}
          <div class="modal-body">
            <div className="error-content">
              <Icon icon="mingcute:alert-line" />
              <h5>{title}</h5>
              {message?.length > 50 ? <p style={{ textAlign: 'justify' }}>{message}</p> : <p>{message}</p>}
            </div>
            <div className="btn-modal text-center">
              {!btn && <button type="button" class="btn yell-gradient-modal" onClick={handleCancel} aria-label="ok btn">OK</button>}
              {btn && <button type="button" class="btn yell-gradient-modal" onClick={handleAction} aria-label="continue">{btn}</button>}
            </div>
          </div>
        </div>
      </div>
    </div>
    <SuccessModal message={successMessage} btn1Text={"OK"} btn1Click={handleClose} />
  </>
  );
};
const mapStateToProps = (state) => ({ error: state.error });

const mapDispatchToProps = { removeError, ResendVerification };

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBar);
