import React, { useState, useEffect } from 'react'
import { Icon } from '@iconify/react'
import images from '../../../assets/images'
import { Link, useNavigate } from 'react-router-dom'
import { connect } from 'react-redux';
import { RoleType } from '../../../util/enum';


const Header = ({ profile }) => {
    const [isSticky, setIsSticky] = useState(false);
    const navigate = useNavigate();


    const handleScroll = () => {
        const scrollY = window.scrollY || document.documentElement.scrollTop;
        const threshold = 80; // Adjust this threshold as needed
        if (scrollY > threshold) {
            setIsSticky(true);
        } else {
            setIsSticky(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const headerClass = isSticky
        ? 'main-menu-wrapper sticky is-sticky'
        : 'main-menu-wrapper sticky header-transparent';

    const headermainClass = isSticky
        ? 'header-area home-screen is-sticky'
        : 'header-area home-screen';

    const mobileheadersticky = isSticky
        ? 'mobile-header d-lg-none d-md-block sticky is-sticky'
        : 'mobile-header d-lg-none d-md-block sticky';
    
    const handleBuy = () => { 
        if (profile && profile?.role?.roleId == RoleType.OfficeAdmin) {
            navigate('/registration');
        } 
        if (profile && profile?.role?.roleId == RoleType.Customer) {
            if (profile && profile?.person?.profileStatus == 1) {
                navigate('/tickets');
            } else if (profile && profile?.person && profile?.person?.profileStatus == null) {
                navigate('/profile');
            }
        }
        if (!profile) {
            navigate('/register');
        }
    };

    return (<header className={headermainClass}>
        <div className="main-header d-none d-lg-block">
            <div className="header-top theme-bg">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-sm-4">
                            <div className="header-top-left text-center text-sm-start">
                                <Icon icon="bi:phone" />(+971) 600 3334726
                            </div>
                        </div>
                        <div className="col-sm-8">
                            <div className="header-settings-bar d-flex justify-content-end">
                                {!profile?.person &&
                                    <>
                                        <div className="login-register text-center">
                                            <Link to="/login" ><Icon icon="mdi:register-outline" />Login</Link>
                                        </div>
                                        <div className="login-register text-center">
                                            <Link to="/register"><Icon icon="material-symbols-light:login-outline" />Register</Link>
                                        </div>
                                    </>
                                }

                                <div className="login-register text-center">
                                    <Link to={"/"} class="disabled"><Icon icon="mdi-light:cart" />Cart</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={headerClass}>
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-4">
                            <div className="brand-logo">
                                <Link to="/">
                                    <img src={images.logo} alt="logo" />
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="main-menu-inner">
                                <nav className="main-menu">
                                    <ul>
                                        <li className="active"><Link to='/contest'>Contest</Link></li>
                                        <li><Link to='/winnersclub'>Winners Club</Link></li>
                                        <li><Link to='/newsroom'>Newsroom</Link></li>
                                        <li>Store Locator</li>
                                        <li><button onClick={handleBuy} className='buy-tickets'><Icon icon="arcticons:mtickets" />Buy Tickets</button></li>
                                    </ul>
                                </nav>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div className={mobileheadersticky}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-12">
                        <div className="mobile-main-header">
                            <div className="mobile-logo">
                                <img src={images.logo} alt="logo" />
                            </div>
                            <div className="mobile-menu-toggler">
                                <button className="mobile-menu-btn" data-bs-toggle="offcanvas" data-bs-target="#mobile-menu" aria-label="mobile-menu">
                                    <Icon icon="mynaui:menu" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="offcanvas offcanvas-start" id="mobile-menu">
            <div className="offcanvas-header">
                <h1 className="offcanvas-title"></h1>
                <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="btn-close"><Icon icon="flowbite:close-solid" /></button>
            </div>
            <div className="offcanvas-body">
                <div className="mobile-menu">
                    <nav className="main-menu">
                        <ul>
                            <li className="active"><Icon icon="ion:home" />Home</li>
                            <li className="active"><Link to='/contest'>Contest</Link></li>
                            <li><Link to='/winnersclub'>Winners Club</Link></li>
                            <li><Link to='/newsroom'>Newsroom</Link></li>
                            <li>Store Locator</li>
                            <li><button onClick={handleBuy} className='buy-tickets' aria-label="buy-tickets"><Icon icon="arcticons:mtickets" />Buy Tickets</button></li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </header>
    )
}

const mapStateToProps = (state) => ({
    profile: state?.auth && state?.auth?.profile,
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Header);