import React, { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import images from '../../assets/images';
import { connect } from 'react-redux';
import { AddPersonTicket, GetAllTickets, GetPersonTicket, RemoveSessionTicket, SelectedTicket } from '../../store/ticket/action';
import Loader from '../../components/Loader';
import RefreshModal from '../../components/RefreshConfirmationModal';
import ErrorModal from '../../components/ErrorModal'
import { useNavigate } from 'react-router-dom';
import { GetDrawschedule } from '../../store/master/action';
import moment from 'moment';

const TicketListing = ({ GetAllTickets, SelectedTicket, AddPersonTicket, profile, GetPersonTicket, cartTickets, RemoveSessionTicket, GetDrawschedule }) => {

    const navigate = useNavigate();
    const [checkboxes, setCheckboxes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [personTickets, setPersonTickets] = useState([]);
    const [message, setMessage] = useState('');
    const [drawSchedule, setDrawSchedule] = useState([]);


    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (unsavedChanges) {
                event.preventDefault()
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [unsavedChanges]);

    useEffect(() => {
        if (profile) {
            async function fetchData() {
                if (profile?.person?.profileStatus != 1) {
                    navigate('/profile')
                    return
                }
                const res = await GetPersonTicket(profile?.person?.personId);
                if (res) {
                    setPersonTickets(res);
                }
                const result = await GetDrawschedule();
                if (result) {
                    setDrawSchedule(result);
                  }
            }
            fetchData();
        }
    }, [profile])

    useEffect(() => {
        const fetchData = async () => {
            const res = await GetAllTickets();
            if (res) {
                const checkboxData = res.map((i) => ({
                    id: i.ticketId,
                    label: i.ticketNumber,
                    checked: false
                }));
                setCheckboxes(checkboxData);
                setLoading(false)
            }
        };
        fetchData();
    }, [])

    const handleCheckboxChange = async (id) => {
        const totalSelection = [...personTickets, ...checkboxes?.filter((i) => i.checked == true)];

        if (totalSelection.length == 6 && (personTickets.filter(p => p.ticketId == id).length == 0 && checkboxes.filter(p => p.id == id && p.checked == true).length == 0)) {
            setMessage(`You have exceeded the AED 1,000 limit per order.`)
            window.$("#ticketError").modal("show");
            return false
        }

        const copyData = [...checkboxes]
        const checkedData = copyData.map(checkbox =>
            checkbox.id === id ? { ...checkbox, checked: !checkbox.checked } : checkbox
        )
        setCheckboxes(checkedData);
        let selected = checkedData.filter(c => c.checked == true);
        let filterCartSelection = cartTickets.filter(c=>!selected.includes(c) && c.id != id) 
        await SelectedTicket([...selected, ...filterCartSelection])
        setUnsavedChanges(true);
    };

    const handleCart = async () => {
        const totalSelection = [...personTickets, ...checkboxes?.filter((i) => i.checked == true)];
        if (totalSelection.length > 0) {
            let data = checkboxes && checkboxes?.filter((i) => i.checked == true);
            let payload = data?.map((i) => ({
                ticketId: i.id,
                ticketNumber: i.label
            }))
            const res = await AddPersonTicket(payload);
            await RemoveSessionTicket(profile?.person?.personId)
            if (res) {
                navigate('/ordersummary');
            }
        } else {
            setMessage(`Please select your ticket to proceed.`)
            window.$("#ticketError").modal("show");
        }
    }

    const GetSelectedTickets = (isTicketLength = false) => {
        const totalSelection = [...personTickets.map(t => t.ticketNumber), ...checkboxes?.filter((i) => i.checked == true).map(t => t.ticketNumber = t.label)];
        if(isTicketLength){ return totalSelection?.length}
        return totalSelection && totalSelection?.length > 0 ?
            <h6>{totalSelection && totalSelection
                ?.map((ticket) => ticket)
                ?.join(", ")
            }</h6> : <h6>- - -</h6>
    }

    return (<section className="ticketlisting-section">
        <RefreshModal btn1Text="Refresh" />
        <ErrorModal modalId="ticketError" message={message} btn1Text="OK" btn1Click={() => window.$("#ticketError").modal("hide")} />
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="profile">
                        <h3>Choose Your Lucky Numbers & Unlock Exclusive Offers!</h3>
                        <p>For every 2 tickets, get 1 FREE! Double the deal with 4 tickets to receive 2 FREE! Maximize your chances without
                            exceeding the AED 1,000 limit per order.</p>
                    </div>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-xl-8 col-lg-8 col-md-7 col-sm-12">
                    <div className='tic-list'>
                        {loading && <Loader className={'tickerListingWrapper tic-load'} />}
                        {checkboxes && checkboxes?.map(checkbox => (
                            <button
                                key={checkbox.id}
                                style={{ backgroundColor: checkbox.checked ? '#FFEBCD' : 'transparent' }}
                                onClick={() => handleCheckboxChange(checkbox.id)}
                                className='tic-btn'
                            >
                                <img src={images.gr} alt="GR Logo"/> <label>{checkbox.label}</label>
                            </button>
                        ))}
                    </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-5 col-sm-12">
                    <div className="tc-sticky mb-5">
                        <div className='tic-summary'>
                            <h4>Draw summary</h4>
                            <div className="tic-content">
                                <table className='mt-3'>
                                    <tr className='ln-dt'>
                                        <td>
                                            <div className="tc-icon">
                                                <Icon icon="ph:ticket-light" />
                                            </div>
                                        </td>
                                        <td><p>Ticket Price</p></td>
                                        <td><h6 className='ad-am'>AED {drawSchedule && drawSchedule?.ticketPrice ? drawSchedule?.ticketPrice : '-' }</h6><span>(Inclusive of VAT)</span></td>
                                    </tr>
                                    <tr className='ln-dt'>
                                        <td>
                                            <div className="tc-icon">
                                                <Icon icon="octicon:goal-24" />
                                            </div>
                                        </td>
                                        <td><p>Draw #</p></td>
                                        <td><h6>{drawSchedule && drawSchedule?.drawNo ? drawSchedule?.drawNo : '-' }</h6></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="tc-icon">
                                                <Icon icon="formkit:date" />
                                            </div>
                                        </td>
                                        <td><p>Draw Date</p></td>
                                        <td><h6>{drawSchedule && drawSchedule?.schedule ? moment(drawSchedule?.schedule)?.format('DD-MM-YYYY'): '-'}</h6></td>
                                    </tr>
                                </table>
                            </div>

                        </div>
                        <div className='tic-details'>
                            {/* <div className="tic-details"> */}
                            <div className="tic-content tc-dt">
                                <table className='mt-3'>
                                    <tr>
                                        <td className='wit-50'><p>Tickets selected</p></td>
                                        <td><h6>{GetSelectedTickets(true)}</h6></td>
                                    </tr>
                                    <tr>
                                        <td className='wit-50'><p>Ticket numbers</p></td>
                                        <td>{GetSelectedTickets(false)}</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="add-cart text-center mt-2">
                                <button className='crt-btn' onClick={handleCart}>  <Icon icon="mdi:cart-outline" />ADD TO CART</button>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
    )
}
const mapDispatchToProps = { GetAllTickets, SelectedTicket, AddPersonTicket, GetPersonTicket, RemoveSessionTicket, GetDrawschedule }
const mapStateToProps = (state) => ({
    profile: state?.auth && state?.auth?.profile,
    cartTickets: state?.ticket && state?.ticket.selected
});

export default connect(mapStateToProps, mapDispatchToProps)(TicketListing);
