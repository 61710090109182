import React, { useEffect, useState } from 'react' 
import { connect } from 'react-redux';  
import { useNavigate, useParams } from 'react-router-dom';
import { VerifySignupLong } from '../../store/auth/action'; 
import Loader from '../../components/Loader';

const Activate = ({VerifySignupLong}) => {
  const navigate = useNavigate();
  const params = useParams();

    useEffect(() => {
        async function fetch(token) {
            const res = await VerifySignupLong(token);
            if (res) { 
                navigate('/login');
            }
        }
        if (params.token) { 
            fetch(params.token)
        }
    }, [params.token]);

 
  
    return (<section className='login-section' style={{minHeight:window.innerHeight-190}}>
        <div className="container">
            <div className="row">
           <Loader/>
           </div>
        </div>
    </section> 
  )
}

const mapDispatchToProps = { VerifySignupLong  };

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Activate);