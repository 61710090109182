import { Icon } from "@iconify/react";
import Select from "../../../../components/Select";
import TextInput from "../../../../components/TextInput";
import DatePicker from '../../../../components/DatePicker'
import moment from "moment";
import PhoneInput from "../../../../components/PhoneInput"; 

export const ProfileInfo = ({ errors, salutations, values, handleChange, nationality }) => {
 
  const calculateMaxDate = () => {
    const currentDate = new Date();
    const maxDate = new Date(currentDate.getFullYear() - 18, currentDate.getMonth(), currentDate.getDate());
    return maxDate;
  };
  
  return <div className='col-md-6'>
    <div className='login-sectioncontent'>
      <div className='log-header'>
        <div className='row mt-3 profile-form mb-5'>
          <div className='col-md-12 rd-btn'>
            <div className="button-wrap">
              Title :
              {salutations && salutations.length ? salutations?.map((i, index) => {
                return (<span key={index}>
                  <input value={i.salutationId} className="hidden radio-label gender-options" type="radio"
                    name="accept-offers" id={index} onChange={(e) => handleChange("salutationId", Number(e.target.value))}
                    checked={values?.salutationId == i.salutationId && "checked"} />
                  <label className={`button-label ${errors && errors?.salutationId && 'error-box'}`} htmlFor={index}>
                    <h1>{i.Title}</h1>
                  </label>
                </span>)
              }):''}
            </div>
          </div>
          <div className="col-md-12">
            <TextInput
              type='text'
              value={values?.firstName}
              onChange={(e) => handleChange("firstName", e.target.value)}
              label='First Name'
              error={errors && errors?.firstName}
            />
          </div>
          <div className="col-md-12">
            <TextInput
              type='text'
              value={values?.lastName}
              onChange={(e) => handleChange("lastName", e.target.value)}
              label='Last Name'
              error={errors && errors?.lastName}
            />
          </div>
          <div className='col-md-12'>
            <TextInput
              type='text'
              value={values?.email}
              onChange={(e) => handleChange("email", e.target.value)}
              label='Email'
              disabled={values && values?.personId > 0}
              error={errors && errors?.email}
            />
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <div className="date" data-date-format="mm/dd/yyyy">
                <DatePicker
                  className="form-control calender-wrap"
                  placeholderText={"Date of Birth *"}
                  dropdownMode="select"
                  name="dateOfbirth"
                  dateFormat="dd/mm/yyyy"
                  selected={values?.dateOfbirth ? values?.dateOfbirth : ""}
                  value={values?.dateOfbirth ? moment(values?.dateOfbirth).format("DD-MM-YYYY") : ""}
                  onChange={(date) => handleChange("dateOfbirth", date)}
                  error={errors && errors?.dateOfbirth}
                  futureDateOnly={false}
                  maxDate={new Date(calculateMaxDate())}
                />
                <div className="ic-calender">
                  <Icon icon="uiw:date" style={{ cursor: "pointer" }} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <Select
              className="custom-select-class"
              options={nationality && nationality}
              name="nationalityId"
              value={
                nationality &&
                nationality.filter(
                  (option) => option?.value === values?.nationalityId
                )
              }
              placeholder="Nationality *"
              onChange={(selectedOption) => {
                if (selectedOption) {
                  handleChange("nationalityId", selectedOption?.value);
                }
                else {
                  handleChange("nationalityId", null);
                }
              }}
              error={errors && errors?.nationalityId}
            />
          </div>
          <div className="col-md-12">
            <div className="custom-phonenumber">
              <PhoneInput
                id={"mobile"}
                inputProps={{
                  name: 'mobile',
                  required: true,
                }}
                placeholder={"xxxxx-xxxxx"}
                name={"mobile"}
                value={values?.mobile}
                onChange={(e, country) => handleChange("mobile", e, country)}
                error={errors && errors?.mobile}
              />
            </div> 
          </div>  
        </div>
      </div>
    </div>
  </div>
}