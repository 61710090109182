import React from "react";
import { connect } from "react-redux";
import Select, { components } from 'react-select';

const NoOptionsMessage = props => {
  return (
    <components.NoOptionsMessage {...props}>
      <span className="custom-css-class">No records to display</span>
    </components.NoOptionsMessage>
  );
};

const SelectDropdown = (props) => {
  const {className, menuPlacement, options, value, onChange, grouped, error, placeholder, isMulti, label, menuIsOpen, onInputChange, noOptionsMessage, isDisabled,isLoading } = props;
  
  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between', 
  };
  const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#5921ff',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
  };
  
  const formatGroupLabel = (data) => (
    <div style={groupStyles}>
      <span style={{color:'#5921ff', fontWeight:600, fontSize:14}}>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );
   
  if (grouped) {
    return (
      <Select
        styles={{
          control: (base, state) => ({
            ...base,
            boxShadow: 'none',
            fontSize: "14px",
            'border': `${error
              ? `1px solid #ee3f46 !important`
              : ``
              }`,
          }),
        }}
        isClearable={true}
        formatGroupLabel={formatGroupLabel}
        isMulti={isMulti}
        classNamePrefix="input-select"
        options={options}
        value={value}
        onChange={onChange}
        error={error}
        placeholder={placeholder}
        maxMenuHeight={200}
        components={{ NoOptionsMessage }}
        label={label}
        aria-labelledby={label}
        aria-label={label}
        isSearchable={true}
        menuIsOpen={menuIsOpen}
        onInputChange={onInputChange}
        noOptionsMessage={noOptionsMessage}
        isDisabled={isDisabled}
        isLoading={isLoading}
      />
    )
  }
  return (
    <Select
      className={`${error && "error-box"} ${className}`}
      isClearable={true}
      isMulti={isMulti}
      classNamePrefix="input-select"
      options={options}
      value={value}
      onChange={onChange}
      error={error}
      placeholder={placeholder}
      maxMenuHeight={200}
      components={{ NoOptionsMessage }}
      label={label}
      aria-labelledby={label}
      aria-label={label}
      isSearchable={true}
      menuIsOpen={menuIsOpen}
      onInputChange={onInputChange}
      noOptionsMessage={noOptionsMessage}
      isDisabled={isDisabled}
      isLoading={isLoading}
      menuPlacement={menuPlacement}
    />
  )
};

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SelectDropdown);




