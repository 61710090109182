import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<GoogleReCaptchaProvider
    reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITEKEY_V3}
    language={'en'}
    useRecaptchaNet={false}
    useEnterprise={true}
    scriptProps={{
        async: false, // optional, default to false,
        defer: false, // optional, default to false
        appendTo: 'body', // optional, default to "head", can be "head" or "body",
        nonce: undefined // optional, default undefined
    }}
    container={{ // optional to render inside custom element
        // element: "[required_id_or_htmlelement]",
        parameters: {
            badge: 'bottomleft', // optional, default undefined
            theme: 'dark', // optional, default undefined
        }
    }}
>
    <App />
</GoogleReCaptchaProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
