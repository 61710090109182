import './App.css'; 
import React, { useState, useEffect} from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom"
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap';
import "../src/scss/app.scss";
// import 'react-tooltip/dist/react-tooltip.css'
import 'react-datepicker/dist/react-datepicker.css';
import { PersistGate } from "redux-persist/lib/integration/react";
import { IsAlive } from "./store/auth/action";
import Router from "./routes/index";
import store  from "./store";
import ScrollToTop from "./ScrollToTop";
import { createBrowserHistory } from 'history';
import { persistStore } from 'redux-persist';
import { RoleType } from './util/enum';
// import LottieLoader from './components/Lottie/index'
const browserHistory = createBrowserHistory();

// Bind persist store with redux store
const persistor = persistStore(store);

function App() {
  const [loading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoading(false);
    setTimeout(() => setLoaded(true), 500);
  }, []);

  const onBeforeLift = async () => {
    const res = await IsAlive();
    const path = browserHistory.location.pathname;
    if (res) {

      if (path === "/" || path === "/login") {
        if (res && res?.role?.roleId == RoleType.OfficeAdmin){
          browserHistory.push("/registration");
          return true;
        }
        if (res && res?.role?.roleId == RoleType.Customer) {
          if (res && res?.person && res?.person?.profileStatus == 1) {
            browserHistory.push("/tickets");
            return true;
          }
          browserHistory.push("/profile");
          return true;
        } 
      }
      return true
    } else {
      await store.dispatch({
        type: "LOGOUT",
      });
    }
    return true
  };

  const loader = () => {
    const loadStyle = {
      position: "absolute",
      top: "40%",
      left: "50%",
      transform: "translate(-50%,-50%)",
      width: "75px",
      height: "75px",
    };

    return <span style={loadStyle}></span>;
  };

  return (
    <Provider store={store}>
    <PersistGate
      persistor={persistor}
      onBeforeLift={() => onBeforeLift()}
      loading={loader}
    >
      <BrowserRouter basename="/">
      <ScrollToTop />
             {/* {!loaded && <LottieLoader loading={loading} />} */}
            <Router />   
      </BrowserRouter>
    </PersistGate>
  </Provider>
  );
}

export default App;
