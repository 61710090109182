import React from 'react'
const TicketPDFModal = ({ btn1Click, btn1, btn2Click, btn2, title, message, pdf }) => {
 


  return (<div class="modal fade successModal" data-bs-backdrop="static" id="ticketModal" tabindex="-1" aria-labelledby="successModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered pdfModal">
      <div class="modal-content"> 
        <div class="modal-body ">
          <div className="success-content pdfHeight">
            
           {pdf ? <iframe id="pdfFrame"  width={'100%'} height={'100%'} src={`${pdf}`}></iframe> : ''}
          </div>
          <div className="btn-modal text-center">
            <button type="button" class="btn grey-gradient-modal" onClick={()=>{window.$("#ticketModal").modal("hide")}} aria-label="confirmation">CLOSE</button>
            {/* <button type="button" class="btn yell-gradient-modal" onClick={handlePrint} aria-label="confirmation">PRINT</button> */}
           </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default TicketPDFModal