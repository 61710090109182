import React, { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';

const Customer = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [customer, setCustomer] = useState([]);

   

    useEffect(() => {
        const fetchData = async () => {
            await new Promise(resolve => setTimeout(resolve, 2000));
            const sampleCustomer = [
                { name: 'Steve Harvay', mobile: '8870567162', email: 'steveharvay@xxxx.com', nationality: 'Indian', createdat: '24-Dec-2023 13.35', Lastlogin: '24-Dec-2023 15.15' },
               
            ];
            setCustomer(sampleCustomer);
            setLoading(false);
        };
        fetchData();
    }, []);

    return (
        <>
            <section className="bg_panel custom-page">
                <div className="content_body report_page">
                    <div className="main_tab">

                        <div className="tab-titles">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-4">
                                        <nav>
                                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                                <button className="nav-link active hideproftab" id="nav-custom-tab" data-bs-toggle="tab"
                                                    data-bs-target="#nav-custom" type="button" role="tab" aria-controls="nav-custom"
                                                    aria-selected="true" aria-label="btn-customer">Customer</button>
                                            </div>
                                        </nav>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="searchbar">
                                            <input type="search" placeholder="Search...." className="inputsearch" />
                                            <span className="iconify" data-icon="akar-icons:search"></span>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="sort_month monthfilter ">
                                            {/* <button className='btn-action' onClick={handleClick}>Create custom</button> */}
                                            <div className="input-group h-100 float-end w-20 cursor-pointer" >
                                                <span className="input-group-text" id="inputGroup-sizing-sm"><Icon icon="fontisto:date" className='yel-icon' /></span>
                                                <input type="text" className="form-control border-none placeholderCalendar cursor-pointer" placeholder={"DD/MM/YYYY - DD/MM/YYYY"}
                                                    value=""
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="tab-content custom-tab mt-4" id="nav-tabContent">
                            <div className="tab-pane fade show active" id="nav-custom" role="tabpanel"
                                aria-labelledby="nav-custom-tab">
                                <div className="tabwrapper_data">
                                    <div className="listing_items lead-tab-data">

                                        <ul>
                                            <li className="headings mb-3 mt-2">
                                                {/* <div className="user_details"> */}
                                                    <div className="name_sec">
                                                        <h6 className="list_tophead">Name <Icon icon="iconamoon:arrow-down-2-light" className='green-icon' /></h6>
                                                    </div>
                                                    <div className="contact_sec">
                                                        <h6 className="list_tophead">Mobile <Icon icon="iconamoon:arrow-down-2-light" className='green-icon' /></h6>
                                                    </div>
                                                {/* </div> */}
                                                <div className="date_sec status-data">
                                                    <h6 className="list_tophead">Email <Icon icon="iconamoon:arrow-down-2-light" className='green-icon' /></h6></div>
                                                <div className="date_sec">
                                                    <h6 className="list_tophead">Nationality</h6></div>
                                                <div className="date_sec">
                                                    <h6 className="list_tophead">Created at<Icon icon="iconamoon:arrow-down-2-light" className='green-icon' /></h6></div>
                                                    <div className="date_sec">
                                                    <h6 className="list_tophead">Last logged at<Icon icon="iconamoon:arrow-down-2-light" className='green-icon' /></h6></div>
                                               
                                            </li>
                                            
                                            {customer.map((custom, index) => (
                                                <li className="checkbx_respo" key={index}>
                                                    {/* <div className="user_details"> */}
                                                        <div className="name_sec">
                                                            <h6 className="listhead">{custom.name}</h6>
                                                        </div>
                                                        <div className="contact_sec">
                                                        <h6 className="listhead">{custom.mobile}</h6>

                                                       </div>
                                                    {/* </div> */}
                                                    <div className="date_sec status-data">
                                                    <h6 className="listhead">{custom.email}</h6>

                                                    </div>
                                                    <div className="date_sec">
                                                        <h6 className="listhead">{custom.nationality}</h6>
                                                    </div>
                                                    <div className="date_sec">
                                                        <h6 className="listhead">{custom.createdat}</h6>
                                                    </div>
                                                    <div className="date_sec">
                                                        <h6 className="listhead">{custom.Lastlogin}</h6>
                                                    </div>
                                                   
                                                </li>
                                            ))}
                                               
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default Customer;
