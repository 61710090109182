import React from 'react'
import { Icon } from '@iconify/react'

const RegisterSuccessModal = ({ btn1Text, btn1Click }) => {
  return (
    <div className="modal fade successModal" data-bs-backdrop="static" id="registerSuccessModal" tabIndex="-1"  aria-labelledby="successModalLabel" aria-hidden="false">
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-body">
          <div className="success-content">
            <Icon icon="mdi:success-circle-outline" />
            <h5>Thank you for joining at Grand Raffle!</h5>
             <p className='text-justify'> We can't wait for you to experience the thrill of winning. An email containing a verification link has been sent to your inbox. Please be sure to check your spam folder just in case it lands there.</p> 
            <p className='text-justify'>To activate your account and begin participating in exciting raffles, simply click on the link provided in the email.</p>
            <p className='text-justify'>If you have any questions or need assistance, don't hesitate to contact our support team at <a  href="mailto:contact@grandraffle.ae">contact@grandraffle.ae</a></p>
          </div>
          <div className="btn-modal text-center">
            {btn1Text && <button type="button" className="btn yell-gradient-modal" onClick={btn1Click}  aria-label="success">{btn1Text}</button>}
           </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default RegisterSuccessModal