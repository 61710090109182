import Footer from './Footer';
import Header from './Header';

const DashboardLayout = ({ children }) => {
   return (<>
      <Header />
      {children}
      <Footer />
   </>
   )
}

export default DashboardLayout