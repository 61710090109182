import { combineReducers } from "redux";
import { AuthReducer } from "./auth/reducer";
import { MasterReducer } from './master/reducer';
import { TicketReducer } from './ticket/reducer';
import { ErrorReducer } from './error/reducer';

const rootReducer = combineReducers({
   auth: AuthReducer,
   master: MasterReducer,
   ticket:TicketReducer,
   error: ErrorReducer,
});

export default rootReducer;