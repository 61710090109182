import React, { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import { Link, useNavigate } from 'react-router-dom';
import { GetAllOrders } from '../../../store/officeadmin/action';
import { connect } from 'react-redux';
import Loader from '../../../components/Loader';
import moment from 'moment';
import TicketPDFModal from '../../../components/TicktePDFModal';

const TicketReport = ({ GetAllOrders }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [ticketReport, setTicketReport] = useState([]);
    const [pdf, setPdf] = useState(null)

    useEffect(() => {
        const fetchData = async () => {
            const orders = await GetAllOrders();  
            setTicketReport(orders);
            setLoading(false);
        };
        fetchData();
    }, []);

    const handleOpen = (image) => { 
        setPdf(image)
        window.$("#ticketModal").modal("show")
    }

    return ( 
            <section className="bg_panel ticket-page">
               <TicketPDFModal pdf={pdf}/> 
                {loading && <Loader/>}
                <div className="content_body report_page">
                    <div className="main_tab"> 
                        <div className="tab-titles">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-4">
                                        <nav>
                                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                                <button className="nav-link active hideproftab" id="nav-ticket-tab" data-bs-toggle="tab"
                                                    data-bs-target="#nav-ticket" type="button" role="tab" aria-controls="nav-ticket"
                                                    aria-selected="true" aria-label="btn-ticket">Ticket</button>
                                            </div>
                                        </nav>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="searchbar">
                                            <input type="search" placeholder="Search...." className="inputsearch" />
                                            <span className="iconify" data-icon="akar-icons:search"></span>
                                        </div>
                                    </div>
                                    <div className="col-md-4 justify-content-center">
                                        <div className="sort_month monthfilter">
                                            <div className="input-group h-100 float-end w-20 cursor-pointer" >
                                                <span className="input-group-text" id="inputGroup-sizing-sm"><Icon icon="fontisto:date" className='yel-icon' /></span>
                                                <input type="text" className="form-control border-none placeholderCalendar cursor-pointer" placeholder={"DD/MM/YYYY - DD/MM/YYYY"}
                                                    value=""
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="tab-content ticket-tab mt-4" id="nav-tabContent">
                            <div className="tab-pane fade show active" id="nav-ticket" role="tabpanel"
                                aria-labelledby="nav-ticket-tab">
                                <div className="tabwrapper_data">
                                    <div className="listing_items lead-tab-data">

                                        <ul>
                                            <li className="headings mb-3 mt-2">
                                                {/* <div className="user_details"> */}
                                                    <div className="name_sec">
                                                        <h6 className="list_tophead">Date <Icon icon="iconamoon:arrow-down-2-light" className='green-icon' /></h6>
                                                    </div>
                                                    <div className="contact_sec">
                                                        <h6 className="list_tophead"># of Tickets  <Icon icon="iconamoon:arrow-down-2-light" className='green-icon' /></h6>
                                                    </div>
                                                {/* </div> */}
                                                <div className="date_sec status-data">
                                                    <h6 className="list_tophead">Draw # <Icon icon="iconamoon:arrow-down-2-light" className='green-icon' /></h6></div>
                                                <div className="date_sec">
                                                    <h6 className="list_tophead">Starting Order ID</h6></div>
                                                <div className="date_sec">
                                                    <h6 className="list_tophead">Ending Order ID<Icon icon="iconamoon:arrow-down-2-light" className='green-icon' /></h6></div>
                                                    <div className="date_sec">
                                                    <h6 className="list_tophead">Tickets <Icon icon="iconamoon:arrow-down-2-light" className='green-icon' /></h6></div>
                                                <div className="income content-center">
                                                    <h6 className="list_tophead color_change content-center">Status
                                                    </h6>
                                                </div>
                                            </li>
                                            
                                            {ticketReport?.data?.map((ticket, index) => (
                                                <li className="checkbx_respo" key={index}> 
                                                        <div className="name_sec">
                                                            <h6 className="listhead">{moment().format("MMMM Do YYYY, h:mm A")}</h6>
                                                        </div>
                                                        <div className="contact_sec">
                                                        <h6 className="listhead">{ticket.printLot}</h6>

                                                       </div> 
                                                    <div className="date_sec status-data">
                                                    <h6 className="listhead">{ticket.drawSchedule.drawNo}</h6>

                                                    </div>
                                                    <div className="date_sec">
                                                        <h6 className="listhead">{ticket.startingOrderNumber}</h6>
                                                    </div>
                                                    <div className="date_sec">
                                                        <h6 className="listhead">{ticket.endingOrderNumber}</h6>
                                                    </div>
                                                    <div className="date_sec">
                                                        <Link onClick={() => { handleOpen(ticket.ticketImage) }}>View</Link>
                                                    </div>
                                                    <div className="income respo_display mr-top d-flex ml-3">
                                                    <h6 className="listhead">{ticket.status || '--'}</h6>

                                                    </div>
                                                </li>
                                            ))}
                                               
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
 )
}

const mapStateToProps = (state) => ({
    profile: state?.auth && state?.auth?.profile,
  });
  const mapDispatchToProps = { GetAllOrders };
  export default connect(mapStateToProps, mapDispatchToProps)(TicketReport);
