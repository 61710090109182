import React from 'react';
import images from '../../assets/images';

const Contest = () => {

  return (
    <>
      <div className='home-section contest-section'>
        <section className='hero-bg'>
          <section className='hero-section '>
            <div className="container">
              <div className="row">
              <div className="col-md-5">
                  <div className='winner-club mt-5'>
                    <img src={images.contleft}  className='contest-img' />
                  </div>
                </div>
                <div className="col-md-7 mt-5">
                  <div className="content contest-club">
                    <h2>Win upto</h2>
                    <img src={images.aed45} className='img-fluid win-img' alt='Grand Raffle Winner Club' loading="lazy"/>
                    <h2>Every Month!</h2>
                    <h3>Grand prize: AED 2.5 Million in cash for 1 Lucky Winner!</h3>
                    <h3>20 Additional Winners will each take home AED 100,000!</h3>
                   <h1>A Total of 21 Winners Every Month!</h1>
                  </div>
                </div>
                
              </div>
             
            </div>
          </section>
        </section>
      </div>
    </>
  );
}

export default Contest;
