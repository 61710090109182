export default {
    logo:require("./logo.webp"),
    heroimg:require('./grand-rf 1.webp'),
    herobg:require('./herobackground.webp'),
    icon1:require('./user1.webp'),
    icon2:require('./icon2.webp'),
    icon3:require('./icon3.webp'),
    howbg:require('./howitworks-bg.webp'),
    herodoodleleft:require('./hero-bt-left.webp'),
    herodoodleright:require('./hero-bt-right.webp'),
    winneravatar:require('./winner-avatarimg.webp'),
    winnerbg:require('./winner-bg.webp'),
    livedraw:require('./livedraw.webp'),
    footerimg:require('./22.webp'),
    winerdoodleleft:require('./winner-doodleleft.webp'),
    winerdoodleright:require('./winner-doodleright.webp'),
    countdownicon:require('./countdownicon.webp'),
    winnerbottom:require('./winner-bottom.webp'),
    faq:require('./faq.webp'),
    testimonials:require('./testimonialbg.webp'),
    questionmark:require('./questionmark.webp'),
    footerlogo:require('./footer-logo.webp'),
    nexttxt:require('./next-txt.webp'),
    latestwinnertext:require('./latestwinner-text.webp'),
    avatar:require('./avatar.webp'),
    gr:require('./gr.webp'),
    ordersummary:require('./grandraffle.webp'),
    successimage:require('./success.webp'),
    failureimage:require('./failure.webp'),
    ticket:require('./ticket.webp'),
    grandticket:require('./grand-right.webp'),
    arrowup:require('./arrow-up.png'),
    winnerclub:require('./winner-club.webp'),
    winnerclubname:require('./winnerclubname.webp'),
    aed:require('./aed.webp'),
    winside:require('./asset7.webp'),
    aed10:require('./aed10.png'),
    aed45:require('./aed45.webp'),
    contleft:require('./contt-left.webp'),
    newscontent:require('./news-announcement.webp'),
}