import React, { useState, useEffect } from 'react';  
import { GetDocumentType, GetNationality, GetStates, GetSalutations } from '../../store/master/action';
import { connect } from 'react-redux';
import { isEmpty  } from 'lodash'
import { CompleteProfile, GetPersonDetails, UpdateProfile } from '../../store/auth/action';
import { GetPersonData, formatDocumentTypes, formatNationality, formatStates } from '../../util/helper';
import { validate } from 'validate.js';
import schema from './schema';
import ErrorModal from '../../components/ErrorModal';
import SuccessModal from '../../components/SuccessModal';
import { ProfileInfo } from './ProfileInfo';
import { AddressInfo } from './AddressInfo';
import { useNavigate, useLocation } from 'react-router-dom';
import Loader from '../../components/Loader';


const Profile = (props) => {
  const { GetNationality, GetStates, GetDocumentType, GetSalutations, profile, GetPersonDetails, CompleteProfile, UpdateProfile } = props;
  const [message, setMessage] = useState();
  const navigate = useNavigate(); 
  const location = useLocation();
  let pathname = location.pathname;

  const initialState = {
    values: {
      profileStatus: 1,
      firstName: "",
      lastName: "",
      email: "",
      dateOfbirth: "",
      nationalityId: null,
      mobile:null,
      documentTypeId: null,
      emiratesId: null,
      documentExpirydate: "",
      firstTimebuyer: "",
      addressLine1: "",
      addressLine2: "",
      countryId: "",
      stateId: "",
      city: "",
      source: "",
      passportNumber: null,
      homePhone: null,
      password: "",
      isChecked:false

    },
    errors: {
      firstName: null,
      lastName: null,
      email: null,
      dateOfbirth: null,
      nationalityId: null,
      mobile:null,
      documentTypeId: null,
      emiratesId: null,
      documentExpirydate: null,
      firstTimebuyer: null,
      addressLine1: null,
      addressLine2: null,
      stateId: null,
      countryId: null,
      city: null,
      source: null,
      passportNumber: null,
      homePhone: null,
      password: null,
      isChecked:null

    },
  };
  const [values, setValues] = useState(initialState.values);
  const [errors, setErrors] = useState(initialState.errors);
  const [nationality, setNationality] = useState([]);
  const [states, setStates] = useState([]);
  const [docTypes, setDocTypes] = useState([]);
  const [salutations, setSalutations] = useState([])
  const [loading, setLoading] = useState(false);


  const handleChange = async (field, value, country) => { 
    if(field=='mobile') { 
      const numberWithoutCode = value.replace(country.dialCode, ''); 
      setValues((prev) => ({ ...prev, [field]: `${country.dialCode} ${numberWithoutCode}` })) 
      return
    }
      setValues((prev) => ({ ...prev, [field]: value })) 
  };

  useEffect(() => {
    async function fetchData() {
      if (values && values.countryId > 0) {
        const states = await GetStates(values.countryId);
        const res = await formatStates(states)
        setStates(res);
      }
    }
    fetchData()
  }, [values?.countryId])

  useEffect(() => {
    async function fetchData() { 
      const nationality = await GetNationality();
      if (nationality) {
        const res = await formatNationality(nationality);
        setNationality(res)
      }

      const documentType = await GetDocumentType();
      if (documentType) {
        const res = await formatDocumentTypes(documentType)
        setDocTypes(res);
      }

      const salutations = await GetSalutations();
      if (salutations) {
        setSalutations(salutations);
      }
    }
    fetchData()
  }, []);

  useEffect(() => {
    if (profile) {
      async function fetchUserData() {
        const res = await GetPersonDetails(profile?.person?.personId); 
        const values = await GetPersonData(res)
        setValues(values);
        if (res) {
          const states = await GetStates(res?.nationalityId)
          if (states) { 
            const res = await formatStates(states)
            setStates(res);
          }
        }
      }
      fetchUserData();
    }
  }, [profile]);

  const validateForm = () => { 
    let data = values;
    if(pathname == "/update-profile"){
       data.isChecked = true
    }
    const error = validate(data, schema);
    setErrors(error);
    let valid = !isEmpty(error) ? false : true;
    if (!valid) {
      let errorMessage =
        (error &&
          !isEmpty(error) &&
          error[Object.keys(error)[0]] &&
          error[Object.keys(error)[0]][0]) || '';
      setMessage(errorMessage);
      window.$("#profileError").modal('show');
    }
    else {
      setErrors({});
    }
    return valid;
  }

  const handleSubmit = async () => { 
    let valid = await validateForm(); 
    if (valid) { 
      setLoading(true)
      values.profileStatus = true;  
      const {isChecked, ...originalValues} = values;
      const res = await CompleteProfile(originalValues);
      if (res) {
        setLoading(false)
        let updatedProfile = { ...profile };
        updatedProfile.person.profileStatus = 1;
        await UpdateProfile(updatedProfile);
        if(location.pathname  == '/update-profile'){
          navigate("/ordersummary");
        }else{
          navigate("/tickets");
        }
      } else {
        setLoading(false)
      }
    }
  }

  const handleOk = () => {
    window.$("#profileError").modal('hide');
    window.$("#successModal").modal('hide');
  } 
   
  const handleSuccess = () => { 
    window.$("#successModal").modal('hide');
  } 
 
  return (<section className='Profiling-section'>
    {loading && <Loader />}
    <ErrorModal modalId="profileError" message={message} btn1Text={"OK"}  btn1Click={handleOk} />
    <SuccessModal message={message} btn1Text={"OK"} btn1Click={handleSuccess} />
    <div className='container'> 
      <div className='row'>
        <div className="profile">
          <h3>Complete Your Profile, Unlock Your Fortune!</h3>
          <p>Enhance your Grand Raffle experience: Confirm your nationality, provide valid documents, and address info to secure your chances and rewards!</p>
        </div>
        <ProfileInfo errors={errors} values={values} handleChange={handleChange} 
        states={states} nationality={nationality} docTypes={docTypes} salutations={salutations}/>
        <AddressInfo errors={errors} values={values} handleChange={handleChange} 
        states={states} nationality={nationality} handleSubmit={handleSubmit}/>
      </div>
    </div>
  </section>
  );
};

const mapDispatchToProps = { GetNationality, GetStates, GetDocumentType, GetSalutations, GetPersonDetails, CompleteProfile, UpdateProfile }
const mapStateToProps = (state) => ({
  profile: state?.auth && state?.auth?.profile,
});
export default connect(mapStateToProps, mapDispatchToProps)(Profile)