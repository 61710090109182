let passwordError = (
  <div> 
    <p>Please ensure your password meets these criteria for security:</p>
    <ul style={{ listStyleType: 'disc', textAlign: 'left', }} className="mb-3"> 
      <li>8 characters minimum</li>
      <li>At least one uppercase letter (A-Z)</li>
      <li>At least one lowercase letter (a-z)</li>
      <li>At least one number (0-9)</li>
      <li>At least one special character (!@#$)</li>  
    </ul>
    </div>
);

let msg = `^Please ensure all the fields are filled out to register your account.`

export default {
  salutationId: {
    presence: { allowEmpty: false, message: msg },
  },
  firstName: {
    presence: { allowEmpty: false, message: msg },
  },
  lastName: {
    presence: { allowEmpty: false, message: msg},
  },
  mobile: {
    presence: { allowEmpty: false, message: msg },
    length: {
      minimum: 11,
      message: "^Please enter a valid phone number",
    },
  },
  email: {
    presence: { allowEmpty: false, message: msg },
    email: { required: true, message: "^Enter a valid email address." },
  },
  password: {
    presence: { allowEmpty: false,  message: "^Password cannot be blank." },
    length: { minimum: 8, maximum: 16, message: passwordError, },
    format: {
      pattern: /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W]).{6,20})/,
      message: passwordError,
    },
  },
  recaptcha: {
    presence: { allowEmpty: false, message: "^Please complete the CAPTCHA verification to proceed."},
  }
};
