import { has } from 'lodash';
import Footer from './Footer';
import Header from './Header'

const DefalutLayout = ({ children, hasHeader=false }) =>{
   return (<>
   {hasHeader && <Header/>}
      {children}
      <Footer />
   </>)
}

export default DefalutLayout