export const MasterReducer = (state = {}, action) => {
    switch (action.type) {
        case "NATIONALITY":
            return {
                ...state,
                nationality: { ...action.data },
            };
        case "STATES":
            return {
                ...state,
                states: { ...action.data },
            };
        case "SALUTATION":
            return {
                ...state,
                salutation: { ...action.data },
            };
        case "DOCUMENTTYPE":
            return {
                ...state,
                documenttype: { ...action.data },
            };
        case "DISCOUNTTYPE":
            return {
                ...state,
                discounttype: { ...action.data },
            }; 
        default:
            return state;
    }
};