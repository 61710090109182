import React from 'react'
import { Icon } from '@iconify/react'

const RefreshModal = ({ btn1Click }) => {
  
  return (<div class="modal fade successModal" data-bs-backdrop="static" id="refreshModal" tabindex="-1" aria-labelledby="successModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div className="modalclose" onClick={() => window.$("#refreshModal").modal("hide")}>
          <Icon icon="ep:close-bold" aria-label="Close" />
        </div>
        <div class="modal-body">
          <div className="success-content">
            <Icon icon="emojione-v1:warning" />
            <h5>Unsaved Data Alert</h5>
            <p>You have unsaved changes that may be lost if you leave this page.</p>
          </div>
          <div className="btn-modal text-center">
            <button type="button" class="btn grey-gradient-modal" onClick={btn1Click} aria-label="exit btn">Exit</button>
            <button type="button" class="btn yell-gradient-modal" onClick={() => { window.$("#refreshModal").modal("hide") }} aria-label="stay btn">Stay</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default RefreshModal