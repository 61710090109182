import { Route, Routes } from "react-router-dom";
import routes from "./routes";
import ProtectedRoute from "../layouts/ProtectedLayout";
import ErrorBar from '../components/ServerError';
import RouteContainer from './RouteContainer';

const Router = () => {
    return (<RouteContainer>
        <main>
            <Routes>
                {routes && routes.map(({ path, View, isProtected, Layout, hasHeader, userPrevillege, hideTestimonialAboutus }) =>
                    isProtected ? <Route path={path} key={path} element={
                        <Layout children={<ProtectedRoute userPrevillege={userPrevillege}>
                            <View/>
                        </ProtectedRoute>} hasHeader={hasHeader}/> 
                    } /> :
                        <Route path={path} key={path} element={<Layout children={<View />} hasHeader={hasHeader} hideTestimonialAboutus={hideTestimonialAboutus}/>} />
                )}
            </Routes>
            <ErrorBar />
        </main>
    </RouteContainer>
    );
};

export default Router;
