import DashboardLayout from "../layouts/DashboardLayout";
import LoginLayout from "../layouts/LoginLayout";
import PublicLayout from "../layouts/PublicLayout";

import Home from "../containers/Home";
import Login from "../containers/Login";
import Profile from "../containers/Profile";
import Register from "../containers/Register";
import ChangePassword from "../containers/ChangePassword";
import ForgotPassword from "../containers/ForgotPassword";
import Activate from "../containers/Activate";
import TicketListing from "../containers/TicketListing";
import OrderSummary from "../containers/OrderSummary";
import OrderSuccess from "../containers/OrderSuccess";
import OrderHistory from "../containers/OrderHistory"; 
import PolicyTerms from "../containers/PolicyTerms";
import FraudulentAwareness from "../containers/FraudulentAwareness";
import Disclaimer from "../containers/Disclaimer";
import NotFound from "../containers/NotFound";

import PersonRegistration from '../containers/OfficeAdmin/PersonRegistration';
import PersonTicketListing from "../containers/OfficeAdmin/TicketListing";
import PersonOrderSummary from "../containers/OfficeAdmin/OrderSummary";
import PersonOrderSuccess from "../containers/OfficeAdmin/OrderSuccess";
import PersonOrderHistory from "../containers/OfficeAdmin/OrderHistory"; 

import Customer from "../containers/Admin/Customer";
import Ticket from "../containers/Admin/Ticket";
import { RoleType } from "../util/enum";
import WinnersClub from "../containers/WinnersClub";
import Contest from "../containers/Contest";
import NewsRoom from "../containers/NewsRoom";
//routes

export default [
    {
        path: "/",
        View: Home,
        isProtected: false, 
        Layout: PublicLayout,
        userPrevillege: 'all',
    },
    {
        path: "/login",
        View: Login,
        isProtected: false, 
        Layout: LoginLayout,
        userPrevillege: 'all',
    },
    {
        path: "/register",
        View: Register,
        isProtected: false, 
        Layout: LoginLayout,
        userPrevillege: 'all',
    },
    {
        path: "/forgotpassword",
        View: ForgotPassword,
        isProtected: false, 
        Layout: LoginLayout,
        userPrevillege: 'all',
    },
    {
        path: "/changepassword/:token",
        View: ChangePassword,
        isProtected: false, 
        Layout: LoginLayout,
        userPrevillege: 'all',
    },
    {
        path: "/activate/:token",
        View: Activate,
        isProtected: false, 
        Layout: LoginLayout,
        userPrevillege: 'all',
    },
    {
        path: "/profile",
        View: Profile,
        isProtected: true, 
        Layout: DashboardLayout,
        userPrevillege: [RoleType.Customer],
    },
    {
        path: "/update-profile",
        View: Profile,
        isProtected: true, 
        Layout: DashboardLayout,
        userPrevillege: [RoleType.Customer],
    },
    {
        path: "/tickets",
        View: TicketListing,
        isProtected: true, 
        Layout: DashboardLayout,
        userPrevillege: [RoleType.Customer],
    },
    {
        path: "/ordersummary",
        View: OrderSummary,
        isProtected: true, 
        Layout: DashboardLayout,
        userPrevillege: [RoleType.Customer],
    },
    {
        path: "/ordersuccess",
        View: OrderSuccess,
        isProtected: true, 
        Layout: DashboardLayout,
        userPrevillege: [RoleType.Customer],
    },
    {
        path: "/orderhistory",
        View: OrderHistory,
        isProtected: true, 
        Layout: DashboardLayout,
        userPrevillege: [RoleType.Customer],
    },  
    {
        path: "/registration",
        View: PersonRegistration,
        isProtected: true, 
        Layout: DashboardLayout,
        userPrevillege: [RoleType.OfficeAdmin],
    }, 
    {
        path: "/registration/:personId",
        View: PersonRegistration,
        isProtected: true, 
        Layout: DashboardLayout,
        userPrevillege: [RoleType.OfficeAdmin],
    },
    {
        path: "/tickets/:personId",
        View: PersonTicketListing,
        isProtected: true, 
        Layout: DashboardLayout,
        userPrevillege: [RoleType.OfficeAdmin],
    },
    {
        path: "/ordersummary/:personId",
        View: PersonOrderSummary,
        isProtected: true, 
        Layout: DashboardLayout,
        userPrevillege: [RoleType.OfficeAdmin],
    },
    {
        path: "/ordersuccess/:personId",
        View: PersonOrderSuccess,
        isProtected: true, 
        Layout: DashboardLayout,
        userPrevillege: [RoleType.OfficeAdmin],
    },
    {
        path: "/history",
        View: PersonOrderHistory,
        isProtected: true, 
        Layout: DashboardLayout,
        userPrevillege: [RoleType.OfficeAdmin],
    }, 
    {
        path: "/policyandterms",
        View: PolicyTerms,
        isProtected: false, 
        Layout: LoginLayout,
        hasHeader: true,
        userPrevillege: "all",
    },
    {
        path: "/fraudulentawareness",
        View: FraudulentAwareness,
        isProtected: false, 
        Layout: LoginLayout,
        hasHeader: true,
        userPrevillege: "all",
    },
    {
        path: "/disclaimer",
        View: Disclaimer,
        isProtected: false, 
        Layout: LoginLayout,
        hasHeader: true,
        userPrevillege: "all",
    },
    {
        path: "/*",
        View: NotFound,
        Layout: DashboardLayout,
        isProtected: false, 
        userPrevillege: "all",
    },



    {
        path: "/customer",
        View: Customer,
        isProtected: true,
        isPublic: false,
        Layout: DashboardLayout, 
        userPrevillege: [RoleType.Admin],
    },
    {
        path: "/ticket",
        View: Ticket,
        isProtected: true,
        isPublic: false,
        Layout: DashboardLayout, 
        userPrevillege: [RoleType.Admin],
    },
    {
        path: "/winnersclub",
        View: WinnersClub,
        isProtected: false,
        isPublic: false,
        Layout: PublicLayout,
        hasHeader: true,
        hideTestimonialAboutus : true,
    },
    {
        path: "/contest",
        View: Contest,
        isProtected: false,
        isPublic: false,
        Layout: PublicLayout,
        hasHeader: true,
        hideTestimonialAboutus : true,
    },
    {
        path: "/newsroom",
        View: NewsRoom,
        isProtected: false,
        isPublic: false,
        Layout: PublicLayout,
        hasHeader: true,
        hideTestimonialAboutus : true,
    },
]