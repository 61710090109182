import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { PaymentHistory } from '../../../store/officeadmin/action'
import Loader from '../../../components/Loader';
import image from '../../../assets/images';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { arrayBufferToBase64 } from '../../../util/helper';
import Pagination from '../../../components/Pagination';
import moment from 'moment';

const OrderHistory = ({ PaymentHistory, profile }) => {
    const [loading, setLoading] = useState(true);
    const [orders, setOrders] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [images, setImages] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);

    useEffect(() => {
        async function fetchData() {
            const res = await PaymentHistory(10, 0 ,profile.userId);
            debugger
            if (res) {
                setOrders(res);
                setLoading(false)
            }
        }
        fetchData();
    }, [])


    const onChangePage = async (pageOfItems, pageno) => {
        if (!loading) {
            const res = await PaymentHistory(pageOfItems, pageno - 1, profile.userId)
            setOrders(res);
            setCurrentPage(pageno - 1);
        }
    };

    const handleDownload = (e) => {
        e.preventDefault()
        const active = images[activeIndex];
        const url = window.URL.createObjectURL(new Blob([active.ticketImage]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', active.orderNo + ".png");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link)
    }

    const handleClick = (orders) => {
        setImages(orders);
        window.$('#viewticket').modal('show')
        setActiveIndex(0)
    }

    return (
        <section className='order-section order-height'>
            {loading && <Loader />}
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="profile">
                            <h3>Order History</h3>
                            <p>Explore Your Order History: A Journey Through Your Past Orders.</p>
                        </div>
                    </div>
                </div>
                <div className="row mt-5">
                    {orders && orders.total > 0 && orders.data.map(item => {
                        return <div className="summarycontent row p-3 order-his"> 
                                        <div className='col-2 col-md-2'>
                                            <div className="or-img d-flex flex-row-reverse">
                                                <img src={image.ordersummary} className='img-fluid w-75 rounded' alt="OrderSummary" />
                                            </div>
                                        </div>
                                        <div className='col-7 col-md-7'>
                                            <div className="o-tickets">
                                                <div className='d-flex'><h6 className='me-2'>TRANSACTION REF #: </h6> <h6>{item.transactionReferenceId}</h6></div>
                                                <div className='d-flex'><h6 className='me-2'>TRANSACTION DATE: </h6> <h6>{moment(item.transactionDate).format('MMMM Do YYYY, h:mm  a')}</h6></div>
                                                <div className='d-flex'><h6 className='me-2'>NO: OF TICKETS PURCHASED: </h6> <h6> {item.ticketOrders.length}</h6></div>
                                                {item.ticketOrders && item.ticketOrders.length > 0 && item.ticketOrders[0].isSuccess ? <h6 style={{ color: 'green' }}>ORDER STATUS: <Icon icon="ep:success-filled" /> SUCCESS</h6> : ''}
                                                {item.ticketOrders && item.ticketOrders.length > 0 && !item.ticketOrders[0].isSuccess ? <h6 style={{ color: 'red' }}>ORDER STATUS: <Icon icon="material-symbols:error-outline" /> FAILURE</h6> : ''}
                                            </div>
                                        </div>
                                        <div className='col-3 col-md-3'>
                                            {item.ticketOrders && item.ticketOrders.length > 0 && item.ticketOrders[0].isSuccess ?
                                                <div className='tic-list'>
                                                    <div className="ch-btn mt-3">
                                                        <button className="crt-btn " onClick={() => handleClick(item.ticketOrders)} aria-label="viewticket">VIEW TICKETS</button>
                                                    </div>
                                                </div>
                                            : ''}
                                        </div> 
                        </div>
                    })}
                      {orders.total == 0 && <div className="summarycontent">
                <table>
                    <tbody>
                        <tr style={{verticalAlign:'middle'}}>
                            <td >
                                <div className="or-img">
                                    <img src={image.ordersummary} className='img-fluid' alt="OrderSummary" />
                                </div> 
                            </td>
                            <td >
                            <div className="o-tickets">
                                    <h6>Your order history appears to be empty. Get ready to immerse yourself in unforgettable experiences and stand a chance to win exciting prizes.</h6>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>}
                </div>
            </div> 
            {orders.total > 0 && (
                <Pagination
                    pageSize={10}
                    currentPage={currentPage}
                    items={orders.total}
                    onChangePage={onChangePage}
                />
            )}
            <div className={`modal fade`} id="viewticket" tabIndex="-1" aria-labelledby="viewticketLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="tic-icon">
                            <Link className="md-close" data-bs-dismiss="modal" aria-label="Close">
                                <Icon icon="jam:close" />
                            </Link>
                            <Link className="download-icon" onClick={handleDownload}>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="download-tooltip">Download</Tooltip>}
                                >
                                    <Icon icon="flowbite:download-outline" />
                                </OverlayTrigger>
                            </Link>
                        </div>
                        <div className="modal-body">
                            <Carousel onChange={index => setActiveIndex(index)} selectedItem={activeIndex}
                                showThumbs={false} showStatus={false} showIndicators={false}
                                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                                    hasPrev && (
                                        <button className="custom-prev-button" onClick={onClickHandler} title={label}>
                                            <Icon icon="solar:alt-arrow-left-bold" />
                                        </button>
                                    )
                                }
                                renderArrowNext={(onClickHandler, hasNext, label) =>
                                    hasNext && (
                                        <button className="custom-next-button" onClick={onClickHandler} title={label}>
                                            <Icon icon="solar:alt-arrow-right-bold" />
                                        </button>
                                    )
                                }>
                                {
                                    images && images.length && images.map(o => {
                                        return <div className="ticket-slider" >
                                            <img src={arrayBufferToBase64(o.ticketImage, "image/webp")} alt="" />
                                        </div>
                                    })
                                }
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

const mapDispatchToProps = { PaymentHistory }
const mapStateToProps = (state) => ({
    profile: state?.auth && state?.auth?.profile,
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderHistory);
