import client from "../../services/api-client";
import { errorHandler } from "../error/action";

export const GetNationality = () => async (dispatch) => {
    try {
        const res = await client.service('nationality').find();
        if (res) {
            return res;
        }
    } catch (err) {
        errorHandler(err)
        return false
    }
}

export const GetStates = (nationalityId) => async (dispatch) => {
    let data = {
        nationalityId: nationalityId
    }
    try {
        const res = await client.service('states').find({ query: data });
        if (res) {
            return res;
        }
    } catch (err) {
        errorHandler(err)
        return false
    }
}

export const GetSalutations = () => async (dispatch) => {
    try {
        const res = await client.service('salutation').find();
        if (res) {
            return res;
        }
    } catch (err) {
        errorHandler(err)
        return false
    }
}

export const GetDocumentType = () => async (dispatch) => {
    try {
        const res = await client.service('documenttype').find();
        if (res) {
            return res;
        }
    } catch (err) {
        errorHandler(err)
        return false
    }
}

export const GetDiscountType = () => async (dispatch) => {
    let data = {
        $limit: -1,
    }
    try {
        const res = await client.service('discounttype').find({ query: data });
        if (res) {
            return res;
        }
    } catch (err) {
        errorHandler(err)
        return false
    }
} 

export const GetDrawschedule = () => async (dispatch) => {
    try {
        const res = await client.service('drawschedule').find();
        if (res) {
            return res;
        }
    } catch (err) {
        errorHandler(err)
        return false
    }
}